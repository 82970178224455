import axios from "axios";
import { BASE_URL } from "../constants";
import React, { useEffect, useState } from "react";
import { setError } from "../store/error/errorSlice";
import { useDispatch, useSelector } from "react-redux";

import {
  addFilesToState,
  addImagePropsTitle,
  removeImagesUsingTitle,
  deleteImageFromDb,
  fillEduEmpForm,
  editCellInSummayDetailForm,
  deleteCellInSummayDetailForm,
  addCellInSubFormsType2,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import uniqueString from "unique-string";
import AddCellModel from "./AddCellModel";
import CustomDropDown from "./CustomDropDown";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import RecursiveModalNew from "./RecursiveModalNew";
import NotificationAlert from "./NotificationAlert";
import EditCellTitleModel from "./EditCellTitleModel";
import ConfirmationDialog from "./ConfirmationDialog";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";

const EducationNew = ({ annexure }) => {
  const modelId = "educationCellTileEdit";

  const dispatch = useDispatch();

  const attachmentList = useSelector((state) => state.pdfdatanew.attachments);
  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const eduForms = useSelector(
    (state) => state.pdfdatanew.forms["Education Verification"]
  );
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Education Verification"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Name of Applicant"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const [loading, setloading] = useState(false);
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [universityNameList, setUniversityNameList] = useState([]);
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Education Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );

    if (component === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType2({
        type: typecellref,
        fieldType,

        position,
        mainTable,
        subTable,
      })
    );
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Education Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Education Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (table, form, component, name, value) => {
    dispatch(
      fillEduEmpForm([
        "Education Verification",
        table,
        form,
        component,
        name,
        value,
      ])
    );
  };
  const addUGCDocument = async (e, subTable, annexure, row) => {
    document.getElementById(`tailspin${subTable}`).style.visibility = "visible";
    let inputVal = e.target.value.trim().toLowerCase();
    let formVal = eduForms[subTable]["form1"][row].infoProvided
      .trim()
      .toLowerCase();

    if (formVal === "") {
      await axios({
        url: `${BASE_URL}client/university/${encodeURIComponent(
          e.target.value
        )}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          return axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .then((img) => {
              console.log(img);
              const file = new File([img.data], `UGC Snapshot.png`, {
                lastModified: new Date(),
                type: "image/png",
              });
              dispatch(
                addImagePropsTitle([
                  "",
                  "Education Verification",
                  subTable,
                  currentKey,
                  "UGC Snapshot",
                  annexure,
                ])
              );
              dispatch(addFilesToState([currentKey, { ...[file] }]));
              addVal(e, subTable, "form1", row);
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (inputVal !== formVal) {
      const keyObject = Object.entries(imgStruc[subTable]).filter((item) => {
        console.log(item);
        return item[1].title === "UGC Snapshot";
      });
      if (
        keyObject !== undefined &&
        keyObject.length !== 0 &&
        keyObject.length[0] !== 0
      ) {
        const key = keyObject[0][0];
        const mainTable = "Education Verification";
        dispatch(
          removeImagesUsingTitle({
            mainTable,
            subTable,
            key,
          })
        );
        if (
          attachmentList[key] !== undefined &&
          attachmentList[key].length > 0
        ) {
          const path = attachmentList[key][0];

          dispatch(deleteImageFromDb(path));
        }
      }

      await axios({
        url: `${BASE_URL}client/university/${encodeURIComponent(
          e.target.value
        )}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          return axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .then((img) => {
              console.log(img);
              const file = new File([img.data], `UGC Snapshot.png`, {
                lastModified: new Date(),
                type: "image/png",
              });
              dispatch(
                addImagePropsTitle([
                  "",
                  "Education Verification",
                  subTable,
                  currentKey,
                  "UGC Snapshot",
                  annexure,
                ])
              );
              dispatch(addFilesToState([currentKey, { ...[file] }]));
              addVal(e, subTable, "form1", row);
              setloading(false);
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    document.getElementById(`tailspin${subTable}`).style.visibility = "hidden";
  };

  useEffect(() => {
    async function findUniversityNames() {
      axios
        .get(`${BASE_URL}client/universities`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setUniversityNameList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Company Name List!", e, false])
          );
        });
    }
    findUniversityNames();
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}client/universities`, {
        withCredentials: true,
      })
      .then((res) => {});
  }, []);

  useEffect(() => {
    f();
  }, [applicantName, eduForms]);

  useEffect(() => {
    Object.keys(eduForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Education Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(eduForms).map((table, index) => {
      dispatch(
        fillEduEmpForm([
          "Education Verification",
          table,
          "form2",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  function f() {
    return Object.keys(eduForms).map((table, index) => (
      <table key={table}>
        <tr>
          <th className="heading" colSpan={3}>
            <h3>
              {table}
              {
                <div
                  className="loadingAnimation"
                  id={`tailspin${table}`}
                  style={{
                    marginTop: "-25px",
                    visibility: "hidden",
                  }}
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Please Wait!!!</span>
                </div>
              }
            </h3>
          </th>
        </tr>
        <tr>
          <th className="centered-text" colSpan={2}>
            Details as per Subject’s Application Form
          </th>
          <th className="centered-text">Verification Remarks</th>
        </tr>
        {Object.keys(eduForms[table]["form1"]).map((row, index) => (
          <>
            <tr>
              {row === "Complete name of Qualification/ Degree Attained" ||
              row === "Year of passing" ||
              row === "University name" ||
              row === "School / College / Institution attended (full name)" ||
              row === "Status" ? (
                <>
                  <td className="col-sm-6">{row}</td>
                  {row.includes("University") ||
                  row.includes("College") ||
                  row.includes("Institute") ||
                  row.includes("Board") ||
                  row.includes("Location") ? (
                    <td>
                      <input
                        type="text"
                        list="universities"
                        style={{ width: 315, height: 30 }}
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          addVal(e, table, "form1", row);
                          if (e.target.value !== "")
                            addUGCDocument(e, table, index + 1, row);
                        }}
                      />
                      <datalist id="universities">
                        <option></option>
                        {universityNameList.map((item, index) => {
                          return item ===
                            eduForms[table]["form1"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </td>
                  ) : (
                    <td>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={
                          (e) => {
                            addVal(e, table, "form1", row);
                          }
                          // onBlur(e, item.component)
                        }
                      />
                    </td>
                  )}
                </>
              ) : (
                <>
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form1");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form1");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>

                  {row.includes("University") ||
                  row.includes("College") ||
                  row.includes("Institute") ||
                  row.includes("Board") ||
                  row.includes("Location") ? (
                    <td>
                      <input
                        type="text"
                        list="universities"
                        style={{ width: 315, height: 30 }}
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          addVal(e, table, "form1", row);
                          if (e.target.value !== "")
                            addUGCDocument(e, table, index + 1, row);
                        }}
                      />
                      <datalist id="universities">
                        <option></option>
                        {universityNameList.map((item, index) => {
                          return item ===
                            eduForms[table]["form1"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </td>
                  ) : (
                    <td>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={
                          (e) => {
                            addVal(e, table, "form1", row);
                          }
                          // onBlur(e, item.component)
                        }
                      />
                    </td>
                  )}
                </>
              )}
              <td>
                <InfoVerifiedDropDown
                  fun={(e) => addVal(e, table, "form1", row)}
                  currentValue={eduForms[table]["form1"][row].infoVerified}
                />
              </td>
            </tr>
          </>
        ))}
        {Object.keys(eduForms[table]["form2"]).map((row, index) => (
          <tr>
            {row === "Status" ? (
              <td>{row}</td>
            ) : (
              <td className="cellTitle">
                <div className="row row-cols-2">
                  <div className="col-sm-10">{row}</div>
                  <div className="col-sm-2 hoverEdit">
                    {row !== "UGC/ AICTE Approval Status" &&
                      row !==
                        "Suspicious Education Database check (Any Findings)" && (
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form2");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                      )}
                    <div className="col-6">
                      <button
                        className="editIcon fa fa-trash"
                        type="button"
                        title="Delete Cell Title"
                        onClick={(e) => {
                          setConfirmation({
                            isOpen: true,
                            color: "error",
                            title: "Are you sure to delete this cell?",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              onDeleteCell(index, table, "form2");
                            },
                          });
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              </td>
            )}
            {row === "Status" ? (
              <ColorCode
                namefield={row}
                cls={eduForms[table]["form2"][row]}
                fun={(e) => addVal(e, table, "form2", row)}
                spanNum={2}
              />
            ) : row.includes("date") ? (
              <td colSpan={2}>
                <DatePickerReact
                  inputTagName={row}
                  val={eduForms[table]["form2"][row]}
                  isSubtable={true}
                  fun={(name, value) => {
                    addDateVal(table, "form2", row, name, value);
                  }}
                />
              </td>
            ) : row === "Suspicious Education Database check (Any Findings)" ? (
              <td colSpan={2}>
                <CustomDropDown
                  fun={(e) => addVal(e, table, "form2", row)}
                  currentValue={eduForms[table]["form2"][row]}
                  arr={["Found", "Not Found"]}
                />
              </td>
            ) : row === "UGC/ AICTE Approval Status" ? (
              <td colSpan={2}>
                <CustomDropDown
                  fun={(e) => addVal(e, table, "form2", row)}
                  currentValue={eduForms[table]["form2"][row]}
                  arr={["Approved", "Not Approved"]}
                />
              </td>
            ) : (
              <td colSpan={2}>
                <textarea
                  style={{ width: "100%", height: 30 }}
                  type="text"
                  defaultValue={eduForms[table]["form2"][row]}
                  disabled={row === "Annexure Details" ? true : false}
                  name={row}
                  onBlur={(e) => {
                    addVal(e, table, "form2", row);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
        <tr>
          <td>Upload Document</td>
          <td colSpan={2}>
            <RecursiveModalNew
              mainTable="Education Verification"
              subTable={table}
              annexure={annexure + (index + 1)}
            />

            <span className="alert">
              Files of format .jpg, .jpeg, .png, .eml are accepted!
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            Please Refer{" "}
            <b>
              Annexure-{annexure}
              {index + 1}
            </b>
          </td>
        </tr>
        <tr>
          <td>
            <button
              type="button"
              className="btn btn-sm btn-outline-dark col-sm-8"
              style={{
                paddingRight: "4px",
                paddingLeft: "4px",
              }}
              data-bs-toggle="modal"
              title="Add One More Cell"
              data-bs-target={`#addCellModelEducationVerification${index}`}
              data-bs-whatever="@mdo"
            >
              <b
                style={{
                  color: "#32cd32",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Add Cell
              </b>
            </button>
          </td>
          <td colSpan={2}></td>
        </tr>
        <EditCellTitleModel
          modelId={modelId}
          onEditCell={onEditCell}
          currentTitle={selectedCellTitle}
          setCurrentTitle={setSelectedCellTitle}
        />
        <AddCellModel
          inputFields={eduForms[table]["form1"]}
          onaddCell={(typecellref, position, fieldType) =>
            addCell(
              typecellref,
              position,
              fieldType,
              "Education Verification",
              table
            )
          }
          uniqueName={`EducationVerification${index}`}
        />
      </table>
    ));
  }

  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <h3 className="text-center mt-5">
        <u>Education Verification</u>
      </h3>
      {f()}
    </>
  );
};
export default EducationNew;
