import React, { useState } from "react";
import Education from "./Education";
import Employment from "./Employment";
import Gap from "./Gap";
import CriminalVerification from "./CriminalVerification";
import DatabaseVerification from "./DatabaseVerification";
import Identity from "./Identity";
import PoliceVerification from "./PoliceVerification";
import WebMediaSearch from "./WebMediaSearchVerification";
import "../styles/pageStyles.css";
import { useSelector, useDispatch } from "react-redux";
import DrugVerification from "./DrugVerification";
import AddressVerification from "./AddressVerification";
import UAN from "./UANVerification";
import Form26 from "./Form26AS";
import Form16 from "./Form16";
import ProfessionalReferenceCheck from "./ProfessionalReference";
import CreditCheck from "./CreditCheck";
import CourtCheck from "./CourtCheck";
import DirectorshipVerification from "./DirectorshipVerification";
import CompanyCheckVerification from "./CompanyCheckVerification";
import VendorVerification from "./VendorVerification";
import OfficialNameChangeVerification from "./OfficialNameChangeVerification";
import FranchiseVerification from "./FranchiseVerification";
import BankStatementVerification from "./BankStatementVerification";
import {
  changeOverallColorCode,
  createSubTable,
  deleteSubTable,
  editCellInExecutiveSummary,
} from "../store/pdfdata/pdfdataSlice";
import SummaryFormPieChart from "./SummaryFormPieChart";
import RightToWorkVerification from "./RightToWorkVerification";
import EditCellTitleModel from "./EditCellTitleModel";
import ColorCheckFun from "./ColorCheckFun";
import NationalPoliceAndHundredCheck from "./NationalPoliceAndHundredCheck";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";

const ExecutiveSummary = () => {
  const forms = useSelector((state) => state.pdfdata.forms);
  const executiveSummaryTypes = useSelector(
    (state) => state.pdfdata.executiveSummary
  );
  const dispatch = useDispatch();
  const addSubtablesToState = (
    mainTable,
    subTable,
    selectedPositionAddSubTable
  ) => {
    dispatch(
      createSubTable([mainTable, subTable, selectedPositionAddSubTable])
    );
  };
  const deleteSubForm = (mainTable, subTable) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    dispatch(deleteSubTable([mainTable, subTable]));
    ColorCheckFun(dispatch, executiveSummaryTypes, subTable, "");
    setNotify({
      isOpen: true,
      message: "Sub check has been deleted!",
      type: "error",
    });
  };
  const [selectedPositionAddSubTable, setselectedPositionAddSubTable] =
    useState(0);
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedPreviousCellTitle, setSelectedPreviousCellTitle] =
    useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState("");
  const [selectedSubtable, setSelectedSubtable] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  if (Object.keys(executiveSummaryTypes).length === 0) return null;
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />

      <SummaryFormPieChart data={executiveSummaryTypes} />
      <table>
        <colgroup>
          <col span="1" style={{ width: "33%" }} />
          <col span="1" style={{ width: "50%" }} />
          <col span="1" style={{ width: "17%" }} />
        </colgroup>
        <thead>
          <tr>
            <th className="heading centered-text" colSpan={3}>
              <h3 className="ES-heading">Executive Summary</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text" scope="col">
              Type of Check
            </th>
            <th className="centered-text" scope="col">
              Remarks
            </th>
            <th className="centered-text" scope="col">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(executiveSummaryTypes).map((table, index) => (
            <>
              <tr>
                {
                  <th colSpan={3} className="table-heading" id="headerName">
                    {table}{" "}
                    <div className="float-end">
                      <button
                        type="button"
                        className="btn btn-circle btn-sm btn-outline-dark"
                        data-bs-toggle="modal"
                        title="Add New Sub Check"
                        data-bs-target={"#exampleModal" + index}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedSubtable(table);
                        }}
                      >
                        <b
                          style={{
                            color: "#32cd32",
                            fontWeight: "900",
                            fontSize: "18px",
                          }}
                        >
                          +
                        </b>
                      </button>
                    </div>
                    <div
                      className="modal fade"
                      id={"exampleModal" + index}
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                    >
                      <div
                        className="modal-dialog dialog-of-modal"
                        role="document"
                      >
                        <div className="modal-content content-modal">
                          <div className="modal-header">
                            <h5
                              className="modal-title title-modal"
                              id="exampleModalLabel"
                            >
                              Add new Subcheck for {table}
                            </h5>
                          </div>
                          <hr />
                          <div className="modal-body body-modal">
                            <div className="mb-2">
                              <label
                                htmlFor="sel1"
                                className="form-label float-start"
                              >
                                * New Cell Will Be Added Just After The Selected
                                Cell (for adding at the top or for new check
                                select "Top").
                              </label>

                              <select
                                className="form-control border border-secondary"
                                name="subtable"
                                id="sel1"
                                value={selectedPositionAddSubTable}
                                onChange={(e) =>
                                  setselectedPositionAddSubTable(e.target.value)
                                }
                              >
                                <option value="0">Top</option>
                                {selectedSubtable !== "" ? (
                                  Object.keys(forms[selectedSubtable]).map(
                                    (key, index) => {
                                      return (
                                        <>
                                          <option value={index + 1}>
                                            {key}
                                          </option>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </select>
                              <label className="form-label float-start">
                                Subcheck Name:
                              </label>
                              <input
                                id={"subtableInput" + index}
                                type="text"
                                className="form-control-sm border border-secondary"
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="modal-footer justify-content-around">
                            <button
                              type="button"
                              title="Save New Subchek"
                              onClick={(e) => {
                                e.preventDefault();
                                const subtable = document.getElementById(
                                  "subtableInput" + index
                                ).value;
                                if (subtable !== "") {
                                  if (
                                    executiveSummaryTypes[table][subtable] ===
                                    undefined
                                  ) {
                                    addSubtablesToState(
                                      table,
                                      subtable,
                                      selectedPositionAddSubTable
                                    );
                                  } else
                                    setNotify({
                                      isOpen: true,
                                      message: `Subcheck named ${subtable} already exists!`,
                                      type: "warning",
                                    });
                                } else {
                                  setNotify({
                                    isOpen: true,
                                    message: "Subcheck is empty!",
                                    type: "warning",
                                  });
                                }
                                setselectedPositionAddSubTable(0);
                                setSelectedSubtable("");
                                document.getElementById(
                                  "subtableInput" + index
                                ).value = "";
                              }}
                              className="btn-modal button-close button-close-hover"
                              data-bs-dismiss="modal"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              title="Close This Modal"
                              className="btn-modal button-close button-close-hover"
                              data-bs-dismiss="modal"
                              onClick={(e) => {
                                e.preventDefault();
                                setselectedPositionAddSubTable(0);
                                setSelectedSubtable("");
                                document.getElementById(
                                  "subtableInput" + index
                                ).value = "";
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                }
              </tr>

              {Object.keys(executiveSummaryTypes[table]).map((row, index) => (
                <tr>
                  <td
                    className="cellTitle"
                    style={{ width: "25%", wordBreak: "break-all" }}
                  >
                    <div className="row">
                      <div className="col-10">{row}</div>
                      <div className="col-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#Edit${index}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedPreviousCellTitle(row);
                            setSelectedCellTitle(row);
                            setSelectedSubtable(table);
                          }}
                        ></button>
                      </div>
                      <div className="col-1">
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                deleteSubForm(table, row);
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                  {table === "Form16 Verification" || table === "Form26-AS Verification" ? (
                    <td colSpan={2}>
                      {executiveSummaryTypes[table][row].remarks}
                    </td>
                  ) : (
                    <>
                      <td>{executiveSummaryTypes[table][row].remarks}</td>
                      <td className={executiveSummaryTypes[table][row].status}>
                        {executiveSummaryTypes[table][row].status}
                      </td>
                    </>
                  )}
                  <EditCellTitleModel
                    modelId={`Edit${index}`}
                    onEditCell={() => {
                      if (
                        executiveSummaryTypes[selectedSubtable][
                          selectedCellTitle
                        ] === undefined
                      ) {
                        dispatch(
                          editCellInExecutiveSummary({
                            selectedPreviousCellTitle,
                            selectedSubtable,
                            selectedCellTitle,
                            selectedCellPosition,
                          })
                        );
                      } else
                        setNotify({
                          isOpen: true,
                          message: `Subcheck named ${selectedCellTitle} already exists!`,
                          type: "warning",
                        });
                    }}
                    currentTitle={selectedCellTitle}
                    setCurrentTitle={setSelectedCellTitle}
                  />
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>

      {Object.keys(forms).map((key, index) =>
        Object.keys(forms[key]).length >= 1 ? (
          key === "Education Verification" ? (
            <Education annexure={String.fromCharCode(index + 65)} />
          ) : key === "Employment Verification" ? (
            <Employment annexure={String.fromCharCode(index + 65)} />
          ) : key === "Gap Verification" ? (
            <Gap annexure={String.fromCharCode(index + 65)} />
          ) : key === "Identity Verification" ? (
            <Identity annexure={String.fromCharCode(index + 65)} />
          ) : key === "Criminal Verification" ? (
            <CriminalVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Database Verification" ? (
            <DatabaseVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Police Verification" ? (
            <PoliceVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Web and Media Search" ? (
            <WebMediaSearch annexure={String.fromCharCode(index + 65)} />
          ) : key === "Drug Verification" ? (
            <DrugVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Address Verification" ? (
            <AddressVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "UAN Verification" ? (
            <UAN annexure={String.fromCharCode(index + 65)} />
          ) : key === "Form26-AS Verification" ? (
            <Form26 annexure={String.fromCharCode(index + 65)} />
          ) : key === "Form16 Verification" ? (
            <Form16 annexure={String.fromCharCode(index + 65)} />
          ) : key === "Professional Reference Check" ? (
            <ProfessionalReferenceCheck
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "National Police Check & 100 Points Check" ? (
            <NationalPoliceAndHundredCheck
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "Credit Check" ? (
            <CreditCheck annexure={String.fromCharCode(index + 65)} />
          ) : key === "Company Check Verification" ? (
            <CompanyCheckVerification
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "Vendor Verification" ? (
            <VendorVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Bank Statement Verification" ? (
            <BankStatementVerification
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "Official Name Change Verification" ? (
            <OfficialNameChangeVerification
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "Franchise Verification" ? (
            <FranchiseVerification annexure={String.fromCharCode(index + 65)} />
          ) : key === "Directorship Verification" ? (
            <DirectorshipVerification
              annexure={String.fromCharCode(index + 65)}
            />
          ) : key === "Court Verification" ? (
            <CourtCheck annexure={String.fromCharCode(index + 65)} />
          ) : key === "Right To Work Verification" ? (
            <RightToWorkVerification
              annexure={String.fromCharCode(index + 65)}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      )}

      {/*remarks={summaryState.education.remarks} status={summaryState.education.status} updateRemarks={updateRemarks} updateStatus={updateStatus}*/}
      {/*remarks={summaryState.employment.remarks} status={summaryState.employment.status} updateRemarks={updateRemarks} updateStatus={updateStatus}*/}
      {/* <Employment updateRemarks={updateRemarks}
        /> */}
      <br />
      <br />
    </div>
  );
};
export default ExecutiveSummary;
