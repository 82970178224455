//Dependencies Import
import React, { useRef, useState, useEffect, useContext } from "react";
import useThrottle from "./useThrottle";
import "./ClientSpecificForm.css";
import AuthContext from "../contexts/AuthProvider";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

import axios from "axios";
import { matchSorter } from "match-sorter";
import NavigationBar from "./NavigationBar";
import { BASE_URL } from "../constants";
import { useDispatch } from "react-redux";
import { setError } from "../store/error/errorSlice";
import Header from "./Header";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";
/*
//Main Clientspecificform function component
*/
const Clientspecificform = () => {
  const { loggedInUserEmail } = useContext(AuthContext);
  const initialValue = {
    ClientName: "",
    ClientCode: "",
    EducationVerification: [""],
    EmploymentVerification: [""],
    UANVerification: [""],
    GapVerification: [""],
    AddressVerification: [""],
    ReferenceCheck: [""],
    IdentityVerification: [""],
    RightToWorkVerification: [""],
    DrugVerification: [""],
    PoliceVerification: [""],
    CriminalVerification: [""],
    CreditVerification: [""],
    AustralianFederalPoliceVerification: [""],
    CourtVerification: [""],
    CompanyCheckVerification: [""],
    BankStatementVerification: [""],
    Form16Verification: [""],
    "Form26-AS Verification": [""],
    "GST Verification": [""],
    "Domain Verification": [""],
    "Professional License Verification": [""],
    "Channel Partner Verification": [""],
    FranchiseVerification: [""],
    DirectorshipVerification: [""],
    VendorVerification: [""],
    OfficialNameChangeVerification: [""],
    WebAndMediaSearch: [""],
    DatabaseVerification: [""],
    CreatedBy: "",
    CreatedDate: "",
    LastUpdatedBy: "",
    LastUpdatedDate: "",
  };
  const initialExcludeList = [
    "ClientName",
    "ClientCode",
    "DatabaseVerification",
    "CreatedBy",
    "CreatedDate",
    "LastUpdatedBy",
    "LastUpdatedDate",
  ]; //Checks to exclude while Loading a client info

  const verificationList = [
    "EducationVerification",
    "EmploymentVerification",
    "UANVerification",
    "GapVerification",
    "AddressVerification",
    "ReferenceCheck",
    "IdentityVerification",
    "RightToWorkVerification",
    "DrugVerification",
    "PoliceVerification",
    "CriminalVerification",
    "CreditVerification",
    "AustralianFederalPoliceVerification",
    "CourtVerification",
    "CompanyCheckVerification",
    "BankStatementVerification",
    "Form16Verification",
    "Form26-AS Verification",
    "GST Verification",
    "Domain Verification",
    "Professional License Verification",
    "Channel Partner Verification",
    "FranchiseVerification",
    "DirectorshipVerification",
    "VendorVerification",
    "OfficialNameChangeVerification",
    "WebAndMediaSearch",
    "DatabaseVerification",
  ];

  /*
  //React states
  */
  const [form, setform] = useState(initialValue); //state for form data
  //   const [searchText, setsearchText] = useState(""); //state for search text field
  //const [customFields, setcustomFields] = useState([]); //state for storing keys of custom fields of fetched client table
  const [newFields, setnewFields] = useState([]); //state for new custom fields
  const [clientListOrignal, setclientListOriginal] = useState([]); //state for all the client specific list
  const [loading, setloading] = useState(false);
  const [excludeList, setexcludeList] = useState(initialExcludeList);
  const [newCheckField, setnewCheckField] = useState(""); //State for new Check
  const [currentKey, setcurrentKey] = useState(""); // State for Current Check key Index
  const dispatch = useDispatch();
  /*
  //React ref
  */
  //   const clientList = useRef([]); //ref for sorted client list
  let saveFlag = useRef(false); //ref for isForm saved
  let clientFlag = useRef(false); //ref for isClientName empty
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  //Handler for getting Client Specifics list
  const getList = async () => {
    axios
      .get(`${BASE_URL}client/specifics`, {
        withCredentials: true,
      })
      .then((res) => {
        const mylist = res.data;
        setclientListOriginal([...mylist.data]);
      })
      .catch((e) => {
        dispatch(setError(["Error in fetching Client Specifics!", e, false]));
      })
      .finally(() => {
        setloading(false);
      });
  };
  /*
  //useEffect hook #1
  //Runs at the reload, fetches list of all the client specifics
  */
  useEffect(() => {
    getList();
  }, []);

  /*
  //useEffect hook #2
  //Runs on Search Box text change. It sorts the list of dropdown elements
  */

  //   const handleSortingOptionList = () => {
  //     clientList.current = matchSorter(clientListOrignal, searchText, {
  //       threshold: matchSorter.rankings.NO_MATCH,
  //     });
  //   };

  /*
    //Handle Functions
    */
  //Handler for client-specific/client name field
  const handleNameChange = (e) => {
    setform({ ...form, ClientName: e.target.value });
  };
  const handleCodeChange = (e) => {
    setform({ ...form, ClientCode: e.target.value });
  };

  //Adding new fields, appends old form with new customs fields./ *Save Button
  const addingNewFields = (e) => {
    if (form.ClientName === "") {
      clientFlag.current = false;
    } else {
      clientFlag.current = true;
    }
    if (clientFlag.current === false) {
      setNotify({
        isOpen: true,
        message: "Client Specific Field is empty",
        type: "warning",
      });
    } else {
      let newRows = {};
      newFields.forEach((newFieldObject) => {
        if (newFieldObject.field !== "") {
          setexcludeList([...initialExcludeList, newFieldObject.field]);
          // newRows = {
          //   ...newRows,
          //   [newFieldObject.field]: [newFieldObject.value],
          // };
        }
      });
      if (form.CreatedBy === "") {
        form.CreatedBy = loggedInUserEmail;
        form.LastUpdatedBy = loggedInUserEmail;
      } else {
        form.LastUpdatedBy = loggedInUserEmail;
      }

      // handleAddingDatabaseAtLast(newRows);
      //setform({ ...form, ...newRows });
      saveFlag.current = true;
      // alert("form is saved");
      console.log(JSON.stringify(form));
    }
  };

  // const handleAddingDatabaseAtLast = (newRows) => {
  //   const lastToDatabase = Object.keys(form)
  //     .slice(-5)
  //     .reduce((result, key) => {
  //       result[key] = form[key];
  //       return result;
  //     }, {});
  //   const firstToDatabase = Object.keys(form)
  //     .slice(0, -5)
  //     .reduce((result, key) => {
  //       result[key] = form[key];
  //       return result;
  //     }, {});
  //   setform({ ...firstToDatabase, ...newRows, ...lastToDatabase });
  // };

  //On Submit form Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    if (saveFlag.current === true && clientFlag.current === true) {
      axios
        .post(`${BASE_URL}client/specific/addOrUpdate`, form, {
          withCredentials: true,
        })
        .then((response) => {
          response.status === 201
            ? setNotify({
                isOpen: true,
                message: "Form is submitted successfully!",
                type: "success",
              })
            : setNotify({
                isOpen: true,
                message: "Form is not submitted!",
                type: "warning",
              });

          getList();
          saveFlag.current = false;
          //Console.log(response);
        })
        .catch((e) => {
          dispatch(setError(["Error in updating Client Specific!", e, false]));
        });
    } else {
      setNotify({
        isOpen: true,
        message: "form is not saved or Client Specific field is empty",
        type: "error",
      });
    }
  };

  //Reset Button Handler
  const handleReset = async (e) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    e.preventDefault();
    setform([]);
    setform(initialValue);

    setexcludeList(initialExcludeList);
    setnewFields([]);
    // setsearchText("");

    setTerm("");

    await getList();
  };

  //Handler to fetch a particular client Specific data
  const getClient = async (searchText) => {
    setloading(true);

    // try {
    // const response = await
    return axios
      .get(`${BASE_URL}client/specific/${searchText}`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        dispatch(
          setError([
            "Error in fetching data for the selected Client Specific!",
            e,
            false,
          ])
        );
      });

    //Console.log(response);

    //   if (response.status === 200) {
    //     const data1 = response.data;
    //     ////Console.log(data1.data);
    //     return data1.data;
    //   }
    // } catch (err) {
    //   throw new Error(err);
    // }
  };

  //Particular Client search and field repopulate Handler
  const handleSearch = async (e) => {
    e.preventDefault();
    const searchText = document.getElementById(
      "ClientSpecific_input_box"
    ).value;

    if (searchText === "") {
      setNotify({
        isOpen: true,
        message: "Search Box is empty",
        type: "warning",
      });
    } else if (!clientListOrignal.includes(searchText)) {
      dispatch(setError(["Client Specific doesn't exist", "Error", false]));
    } else {
      setnewFields([]);
      // try {
      // const record = await
      getClient(searchText)
        .then((res) => {
          var aKeys = Object.keys(res).sort();
          var bKeys = Object.keys(initialValue).sort();
          if (!(JSON.stringify(aKeys) === JSON.stringify(bKeys))) {
            //setcustomFields(customFieldObject(record));
          }
          //Console.log(customFields);
          setform(res);
          //setsearchText("");
          setloading(false);
        })
        .catch((e) => {
          dispatch(setError(["Client Specific doesn't exist!", e, false]));
        })
        .finally(() => {
          setloading(false);
        });
      //Console.log(record);
      //   if (Object.keys(record).length === 0) {
      //     alert("Record not found");
      //   } else {
      //     //Console.log(record)
      //     var aKeys = Object.keys(record).sort();
      //     var bKeys = Object.keys(initialValue).sort();
      //     if (!(JSON.stringify(aKeys) === JSON.stringify(bKeys))) {
      //       //setcustomFields(customFieldObject(record));
      //     }
      //     //Console.log(customFields);
      //     setform(record);
      //     //setsearchText("");
      //     setloading(false);
      //   }
      // } catch (e) {
      //   setloading(false);
      //   window.alert("Client Specific doesn't exist");
      // }
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to reload? All provided data will be lost and you will be logged out.";
    e.returnValue = message;
    return message;
  };

  //Handle Delete client specific
  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Are you Sure?")) {
      axios({
        method: "delete",
        url: `${BASE_URL}client/specific/${form.ClientName}`,
        withCredentials: true,
      })
        .then((res) => {
          setNotify({
            isOpen: true,
            message: "Client Specific Deleted Successfully!",
            type: "success",
          });
          handleReset();
          getList();
          //
        })
        .catch((e) => {
          dispatch(setError(["Error in deleting Client Specific!", e, false]));
        });
    }
  };

  //New Component
  const handleAddNewCheck = (val) => {
    if (newCheckField !== "") {
      const position = Object.keys(form).indexOf(currentKey);
      const newData = Object.entries(form);
      newData.splice(position + val, 0, [newCheckField, [""]]);
      setform({ ...Object.fromEntries(newData) });
      setnewCheckField("");
    }
  };

  //combobox

  const [term, setTerm] = React.useState("");
  const results = useCityMatch(term);
  const handleChange = (event) => setTerm(event.target.value);

  function useCityMatch(term) {
    const throttledTerm = useThrottle(term, 100);
    return React.useMemo(
      () =>
        term.trim() === ""
          ? null
          : matchSorter(clientListOrignal, term, {
              keys: [(item) => `${item}`],
            }),

      // matchSorter(clientListOrignal, searchText, {
      //     threshold: matchSorter.rankings.NO_MATCH,
      //   });
      [throttledTerm]
    );
  }

  /*
  //React Render Component
  */
  return (
    <>
      <NavigationBar />
      <div className="main">
        <div className="container-fluid">
          <Header heading={"Client Specific Form"} />
          <NotificationAlert notify={notify} setNotify={setNotify} />
          <ConfirmationDialog
            confirmation={confirmation}
            setConfirmation={setConfirmation}
          />

          <div className="formClient">
            <ul className="zf-tempHeadBdr" id="clientUl">
              <li className="zf-tempHeadContBdr">
                <p className="zf-frmDesc">Client Specific Form</p>
                <div className="zf-clearBoth"></div>
              </li>
            </ul>
            <div className="zf-subContWrap zf-leftAlign">
              <ul id="clientUl">
                <li className="zf-tempFrmWrapper zf-small">
                  <label
                    className="zf-labelName clientLabel"
                    id="searchtext"
                    type="search"
                  >
                    Search
                  </label>
                  <div className="comboxbox">
                    <div className="comboBox">
                      <Combobox
                        id="searchBox"
                        aria-label="ClientSpecifics"
                        onSelect={(item) => {
                          setTerm(item);
                          document.getElementsByClassName(
                            "shadow-popup"
                          )[0].style.display = "none";
                        }}
                      >
                        <ComboboxInput
                          value={term}
                          type="text"
                          id="ClientSpecific_input_box"
                          placeholder="Search Client Specifics"
                          className="search_input clientInput"
                          onChange={(e) => {
                            handleChange(e);
                            document.getElementsByClassName(
                              "shadow-popup"
                            )[0].style.display = "block";
                          }}
                        />

                        {results && (
                          <ComboboxPopover
                            id="CS_data_list"
                            className="shadow-popup"
                            style={{
                              outline: "1px solid blue",
                              display: "block",
                            }}
                          >
                            {results.length > 0 ? (
                              <ComboboxList>
                                <ComboboxOption value="" />
                                {results.slice(0, 10).map((result, index) => (
                                  <ComboboxOption
                                    key={index}
                                    value={`${result}`}
                                  />
                                ))}
                              </ComboboxList>
                            ) : (
                              <p
                                style={{
                                  margin: 0,
                                  color: "#454545",
                                  padding: "0.25rem 1rem 0.75rem 1rem",
                                  fontStyle: "italic",
                                }}
                              >
                                No results :(
                              </p>
                            )}
                          </ComboboxPopover>
                        )}
                      </Combobox>
                    </div>

                    <button
                      className="button-close button-close-hover"
                      title="Search"
                      id="searchBtnCf"
                      onClick={handleSearch}
                    >
                      🔍 Search
                    </button>

                    <div className="zf-clearBoth"></div>
                  </div>
                </li>
                {loading ? (
                  <div className="loadingAnimation">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <>
                      <li className="zf-tempFrmWrapper zf-medium">
                        <label className="zf-labelName clientLabel">
                          Client Specific<em className="zf-important">*</em>
                        </label>
                        <div className="zf-tempContDiv">
                          <span>
                            <input
                              title={form.ClientName}
                              className="clientInput"
                              type="text"
                              name="ClientName"
                              checktype="c1"
                              value={form.ClientName}
                              onChange={handleNameChange}
                              placeholder=" Enter Client Specific"
                              required
                            />
                          </span>
                        </div>
                        <div className="zf-clearBoth"></div>
                      </li>

                      <li className="zf-tempFrmWrapper zf-medium">
                        <label className="zf-labelName clientLabel">
                          Client Code<em className="zf-important">*</em>
                        </label>
                        <div className="zf-tempContDiv">
                          <span>
                            <input
                              title={form.ClientCode}
                              className="clientInput"
                              type="text"
                              name="ClientCode"
                              checktype="c1"
                              value={form.ClientCode}
                              onChange={handleCodeChange}
                              placeholder=" Enter Client Code"
                              required
                            />
                          </span>
                        </div>
                        <div className="zf-clearBoth"></div>
                      </li>

                      {Object.entries(form).map(([key, value]) => {
                        console.log(form[key][0] === "");
                        //console.log(!Object.entries(newFields).map(([newFieldkey,val])=>val.field.includes(key)));
                        if (!excludeList.includes(key)) {
                          return (
                            <div className="checkFieldContainer" key={key}>
                              <li className="zf-tempFrmWrapper zf-medium">
                                <label className="zf-labelName clientLabel">
                                  {key}
                                </label>

                                <label className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    name={key}
                                    checked={form[key][0] === "" ? false : true}
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? setform({
                                            ...form,
                                            [e.target.name]: ["true"],
                                          })
                                        : setform({
                                            ...form,
                                            [e.target.name]: [""],
                                          })
                                    }
                                  />
                                  <span className="mark"></span>
                                </label>

                                <div className="zf-clearBoth"></div>
                                <div className="checkEditButtons">
                                  <button
                                    type="button"
                                    title="Add New Verificaiton Form"
                                    className="button-plus button-plus-hover"
                                    data-bs-toggle="modal"
                                    data-bs-target="#newCheckModal"
                                    id="addCheck"
                                    onClick={() => setcurrentKey(key)}
                                  >
                                    +
                                  </button>
                                  <button
                                    type="button"
                                    title="Remove Verificatin Form"
                                    className="button-cross button-cross-hover"
                                    id="deleteCheck"
                                    onClick={() => {
                                      const formData = form;
                                      delete formData[key];
                                      setform({ ...formData });
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              </li>
                            </div>
                          );
                        }
                      })}

                      <>
                        <li className="zf-tempFrmWrapper zf-medium">
                          <label className="zf-labelName clientLabel">
                            DatabaseVerification
                          </label>
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              name="DatabaseVerification"
                              checked={
                                form["DatabaseVerification"][0] === ""
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                e.target.checked === true
                                  ? setform({
                                      ...form,
                                      [e.target.name]: ["true"],
                                    })
                                  : setform({ ...form, [e.target.name]: [""] })
                              }
                            />
                            <span className="mark"></span>
                          </label>

                          <div className="zf-clearBoth"></div>
                        </li>
                      </>
                    </>
                  </>
                )}

                <div
                  className="modal fade"
                  id="newCheckModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog dialog-of-modal" role="document">
                    <div className="modal-content content-modal">
                      <div className="modal-header">
                        <h5
                          className="modal-title title-modal"
                          id="exampleModalLabel"
                        >
                          Add New Check
                        </h5>
                      </div>
                      <hr />
                      <div className="modal-body body-modal">
                        <select
                          className="form-control form-select border border-secondary"
                          checktype="c1"
                          value={newCheckField}
                          onChange={(e) => setnewCheckField(e.target.value)}
                          style={{ width: "100%" }}
                        >
                          <option></option>
                          {verificationList.map((item, index) => {
                            return (
                              <option value={item} className="vlistDropdown">
                                {item}
                              </option>
                            );
                          })}
                        </select>
                        {/* <input
                        className="clientInput"
                        type="text"
                        checktype="c1"
                        value={newCheckField}
                        onChange={(e) => setnewCheckField(e.target.value)}
                        placeholder=" Enter New Check"
                        //required
                      /> */}
                      </div>
                      <hr />
                      <div className="modal-footer justify-content-around">
                        <button
                          type="button"
                          title="Add Check Above"
                          className="btn btn-sm button-submit button-submit-hover"
                          data-bs-dismiss="modal"
                          onClick={() => handleAddNewCheck(0)}
                        >
                          ↑ Add Check Above
                        </button>
                        <button
                          type="button"
                          title="Add Check Below"
                          className="btn btn-sm button-submit button-submit-hover"
                          data-bs-dismiss="modal"
                          onClick={() => handleAddNewCheck(1)}
                        >
                          ↓ Add Check Below
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm button-close button-close-hover"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <ul id="clientUl">
                  <li className="zf-fmFooter">
                    <div className="bottom-buttons">
                      {/* <button
                      className="buttonCf generateBtnCf"
                      onClick={addingNewFields}
                    >
                      Save
                    </button> */}
                      <button
                        className="button-close button-close-hover"
                        title="Delete"
                        onClick={(e) => {
                          handleDelete(e);
                        }}
                        Delete
                      >
                        Delete
                      </button>
                      <button
                        className="button-close button-close-hover"
                        title="Submit Form Data"
                        onClick={(e) => {
                          addingNewFields(e);
                          handleSubmit(e);
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="button-close button-close-hover"
                        title="Reset Form Data"
                        onClick={(e) => {
                          setConfirmation({
                            isOpen: true,
                            color: "warning",
                            title:
                              "Do you want to reset the form? Any unsubmitted changes would be lost! Press yes to continue and no to cancel.",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              handleReset(e);
                            },
                          });
                        }}
                        // onClick={(e) => handleReset(e)}
                      >
                        Reset
                      </button>
                    </div>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clientspecificform;
