import React, { useCallback, useState } from "react";
import { Pie, PieChart, Sector } from "recharts";

const SummaryFormPieChart = ({ data }) => {
  var outerCount = 0;
  var completedOuter = 0;
  const innerData = Object.keys(data).map((e) => {
    return { name: e, value: 100, fill: "#3A3A3A" };
  });
  const outerData = [];
  Object.keys(data).forEach((e, index) => {
    const value = data[e];
    if (e !== "Gap Verification") {
      if (Object.keys(value).length === 0) {
        outerData.push({
          name: e,
          value: 100,
          fill: "#838383",
          remarks: "No Remarks",
        });
      } else {
        Object.keys(value).forEach((key) => {
          let color = "";
          outerCount += 1;
          switch (value[key].status) {
            case "Green":
              color = "#32CD32";
              completedOuter += 1;
              break;
            case "Red":
              color = "#FF0000";
              completedOuter += 1;
              break;
            case "Amber":
              color = "#FFA500";
              completedOuter += 1;
              break;
            case "IRWIP":
              color = "#FFFF00";
              completedOuter += 1;
              break;
            case "IRCEP":
              color = "#FFFF00";
              completedOuter += 1;
              break;
            case "Stop Check":
              color = "#FFFF00";
              completedOuter += 1;
              break;
            default:
              color = "#C9C9C9";
          }

          outerData.push({
            name: e + " : " + key,
            value: (innerData.length * 10) / Object.keys(value).length,
            fill: color,
            remarks: value[key].remarks ? value[key].remarks : "No Remarks",
          });
        });
      }
    }
  });
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      remarks,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const percentage = outerCount
      ? ((completedOuter / outerCount) * 100).toFixed(0)
      : 0;

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#00B053"}>
          {percentage + "%"}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 5}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${name}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {"Summary Remarks : " + remarks}
        </text>
      </g>
    );
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  return (
    <div>
      <PieChart width={1053} height={400}>
        <Pie
          data={innerData}
          dataKey="value"
          cx={525}
          cy={200}
          innerRadius={20}
          outerRadius={70}
          fill="#00B053"
        />
        <Pie
          data={outerData}
          dataKey="value"
          cx={525}
          cy={200}
          innerRadius={80}
          outerRadius={110}
          fill="#00B053"
          activeShape={renderActiveShape}
          activeIndex={activeIndex}
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </div>
  );
};

export default SummaryFormPieChart;
