import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment.js";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { changeExecutiveSummary } from "../store/pdfdata/pdfdataSlice";

const DatePickerReact = ({ inputTagName, val, isSubtable, fun, disabled }) => {
  const dispatch = useDispatch();
  const dateChangeHandler = (date) => {
    dispatch(changeExecutiveSummary([inputTagName, date]));
  };

  const [startDate, setStartDate] = useState(
    val ? new Date(Date.parse(val)) : ""
  );
  useEffect(() => {
    if (val) {
      setStartDate(val === "" ? "" : new Date(Date.parse(val)));
    }
  }, [val]);

  return (
    <DatePicker
      dateFormat="dd-MMM-yyy"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={startDate}
      disabled={disabled}
      onChange={(date) => {
        if (isSubtable === true) {
          if (date === null) {
            setStartDate("");
            fun(inputTagName, "");
          } else {
            setStartDate(date);
            fun(inputTagName, moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY"));
          }
        } else {
          if (date === null) {
            setStartDate("");
            dateChangeHandler("");
          } else {
            setStartDate(date);
            dateChangeHandler(moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY"));
          }
        }
      }}
    />
  );
};

export default DatePickerReact;
