import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillDrugVerificationForm,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import CustomDropDown from "./CustomDropDown";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFunNew";
import RecursiveModal from "./RecursiveModalNew";

const DrugVerificationNew = ({ annexure }) => {
  const dispatch = useDispatch();

  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Drug Verification"]
  );
  const drugVerificationForms = useSelector(
    (state) => state.pdfdatanew.forms["Drug Verification"]
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillDrugVerificationForm([
        "Drug Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  useEffect(() => {
    Object.keys(drugVerificationForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Drug Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(drugVerificationForms).map((table, index) => {
      dispatch(
        fillDrugVerificationForm([
          "Drug Verification",
          table,
          "form2",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <>
      <h3 className="text-center mt-4">
        <u>Drug Verification</u>
      </h3>
      {Object.keys(drugVerificationForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Substance</th>
            <th className="centered-text">Result</th>
          </tr>
          {drugVerificationForms?.[table] &&
            Object.keys(drugVerificationForms?.[table]?.["form2"]).map(
              (row) => (
                <tr>
                  <td>{row}</td>

                  {row === "Status" ? (
                    <ColorCode
                      namefield="status"
                      cls={drugVerificationForms?.[table]?.["form2"]?.[row]}
                      fun={(e) => addVal(e, table, "form2", row)}
                      spanNum={1}
                    />
                  ) : row === "Annexure Details" ? (
                    <td style={{ backgroundColor: "white" }}>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        name={row}
                        disabled={row === "Annexure Details" ? true : false}
                        defaultValue={
                          drugVerificationForms?.[table]?.["form2"]?.[row]
                        }
                        onBlur={(e) => {
                          addVal(e, table);
                        }}
                      />
                    </td>
                  ) : row === "Additional Comments" ? (
                    <td colSpan={2}>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        defaultValue={
                          drugVerificationForms[table]["form2"][row]
                        }
                        name={row}
                        onBlur={(e) => {
                          addVal(e, table, "form2", row);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={2}>
                      <CustomDropDown
                        fun={(e) => addVal(e, table, "form2", row)}
                        currentValue={
                          drugVerificationForms[table]["form2"][row]
                        }
                        arr={["Positive", "Negative"]}
                      />
                    </td>
                  )}
                </tr>
              )
            )}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Drug Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </>
  );
};
export default DrugVerificationNew;
