import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bAddToAnnexureTile: {},
};

export const flagIsAnnexureTileSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setBAddToAnnexureTile: (state, action) => {
      const { annexure, currentAttachmentCheck } = action.payload;
      if (annexure) {
        state.bAddToAnnexureTile[annexure] = currentAttachmentCheck;
      }
    },
  },
});

export const { setBAddToAnnexureTile } = flagIsAnnexureTileSlice.actions;

export default flagIsAnnexureTileSlice.reducer;
