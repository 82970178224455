import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillReferenceCheckForm,
  editCellInSummayDetailForm,
  deleteCellInSummayDetailForm,
  fillEduEmpForm,
  addCellInSubFormsType1,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import AddCellModel from "./AddCellModel";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import RecursiveModal from "./RecursiveModalNew";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";
import EditCellTitleModel from "./EditCellTitleModel";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";

const ProfessionalReferenceCheckNew = ({ annexure }) => {
  const modelId = "professionalRefCheckCellTileEdit";

  const dispatch = useDispatch();

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Professional Reference Check"]
  );
  const profRefForms = useSelector(
    (state) => state.pdfdatanew.forms["Professional Reference Check"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addVal = (e, table, form, component, type) => {
    e.preventDefault();
    dispatch(
      fillReferenceCheckForm([
        "Professional Reference Check",
        table,
        form,
        component,
        e.target.value,
        e.target.name,
      ])
    );
    if (e.target.name === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    subTable = [subTable]["form2"];
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType1({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Professional Reference Check";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Professional Reference Check";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (table, form, component, name, value) => {
    dispatch(
      fillReferenceCheckForm([
        "Professional Reference Check",
        table,
        form,
        component,
        value,
        name,
      ])
    );
  };

  useEffect(() => {
    Object.keys(profRefForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Professional Reference Check",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(profRefForms).map((table, index) => {
      dispatch(
        fillReferenceCheckForm([
          "Professional Reference Check",
          table,
          "form2",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Professional Reference Check</u>
      </h3>
      {Object.keys(profRefForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text" colSpan={2}>
              Details as per Subject’s Application Form
            </th>
            <th className="centered-text dark-bg">Verification Remarks</th>
          </tr>

          {Object.keys(profRefForms[table]["form1"]).map((row, index) => (
            <>
              <tr>
                {row === "Name of the reference" ? (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-12">{row}</div>
                    </div>
                  </td>
                ) : (
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form1");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form1");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={
                      profRefForms[table]["form1"][row].infoProvided
                    }
                    name="infoProvided"
                    onBlur={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>
                <td>
                  <InfoVerifiedDropDown
                    fun={(e) => addVal(e, table, "form1", row)}
                    currentValue={
                      profRefForms[table]["form1"][row].infoVerified
                    }
                  />
                  {/* <input
                    type="text"
                    defaultValue={empForms[table]["form1"][row].infoVerified}
                    name="infoVerified"
                    onBlur={(e) => {
                      addVal(e, table, "form1", row);
                    }}
                  /> */}
                </td>
              </tr>
            </>
          ))}
          {Object.keys(profRefForms[table]["form2"]).map((row, index) => (
            <>
              <tr key={row + table + index}>
                {row === "Status" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form2");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form2");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                    {row === "Upload Document" ? (
                      <>
                        {" "}
                        For
                        <b>
                          {" "}
                          Annexure {annexure}-{index + 1}
                        </b>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                )}

                {row === "Status" ? (
                  <ColorCode
                    namefield={row}
                    cls={profRefForms[table]["form2"][row]}
                    fun={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                    spanNum={2}
                  />
                ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                  <td key={row + table + index} colSpan={2}>
                    <DatePickerReact
                      inputTagName={row}
                      val={profRefForms[table]["form2"][row]}
                      isSubtable={true}
                      fun={(name, value) => {
                        addDateVal(table, "form2", row, name, value);
                      }}
                    />
                  </td>
                ) : (
                  <td key={row + table + index} colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      id={table + row + "ROCUpdate"}
                      defaultValue={profRefForms[table]["form2"][row]}
                      disabled={row === "Annexure Details" ? true : false}
                      name={row}
                      onBlur={(e) => {
                        addVal(e, table, "form2", row);
                      }}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Professional Reference Check"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              {" "}
              <button
                type="button"
                className="btn btn-sm btn-outline-dark col-sm-8"
                style={{
                  paddingRight: "4px",
                  paddingLeft: "4px",
                }}
                data-bs-toggle="modal"
                title="Add One More Cell"
                data-bs-target={`#addCellModelReferenceVerification${index}`}
                data-bs-whatever="@mdo"
              >
                <b
                  style={{
                    color: "#32cd32",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Add Cell
                </b>
              </button>
            </td>
          </tr>
          {console.log("Table: ", table)}
          <AddCellModel
            inputFields={profRefForms[table]["form2"]}
            onaddCell={(typecellref, fieldType, position) =>
              addCell(
                typecellref,
                fieldType,
                position,
                "Professional Reference Check",
                table
              )
            }
            uniqueName={`ReferenceVerification${index}`}
          />

          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
    </>
  );
};
export default ProfessionalReferenceCheckNew;
