import React, { useEffect, useState } from "react";
import "../styles/pageStyles.css";
import { useDispatch } from "react-redux";
import {
  addCellInCaseDetail,
  changeCaseDetail,
  editCellInCaseDetail,
  fetchDataByClientSpecific,
  fetchDataByClientSpecificnew,
} from "../store/pdfdata/pdfdataSliceNew";
import { useSelector } from "react-redux";
import DatePickerReact from "./DatePickerReact";
import AddCellModel from "./AddCellModel";
import NotificationAlert from "./NotificationAlert";
import EditCellTitleModel from "./EditCellTitleModel";
import axios from "axios";
import { setError } from "../store/error/errorSlice";
import { BASE_URL } from "../constants";
import ExecutiveSummaryNew from "./ExecutiveSummaryNew";
import ConfirmationDialog from "./ConfirmationDialog";
import ColorCode from "./ColorCode";
import RecursiveModalNew from "./RecursiveModalNew";
const CaseDetails = ({ informate360Refrence }) => {
  const dispatch = useDispatch();
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [clientSpecificList, setClientSpecificList] = useState([]);
  const caseDetailsData = useSelector((state) => state.pdfdatanew);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const modelId = "caseDetailEditCell";
  const changeHnadler = (e) => {
    e.preventDefault();
    if (e.target.name === "Client /Scope") {
      const clientSpecific = e.target.value;
      if (clientSpecific !== "") {
        dispatch(fetchDataByClientSpecificnew([dispatch, clientSpecific]));
      }
    }
    dispatch(changeCaseDetail([e.target.name, e.target.value]));
  };
  const addDateVal = (name, val) => {
    dispatch(changeCaseDetail([name, val]));
  };

  const addCell = (typecellref, fieldType, position) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(addCellInCaseDetail({ type: typecellref, fieldType, position }));
  };

  const EditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    dispatch(
      editCellInCaseDetail({ type: cellTitileRef, selectedCellPosition })
    );
  };

  const onSetClientSpecific = (nam, value) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
  };

  useEffect(() => {
    async function findColor() {
      axios
        .get(`${BASE_URL}client/specifics`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setClientSpecificList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Client Specific List!", e, false])
          );
        });
    }
    findColor();
  }, []);

  useEffect(() => {
    let arr = [];
    Object.keys(caseDetailsData.forms).forEach((table) => {
      Object.keys(caseDetailsData.forms[table]).forEach((data) => {
        if (caseDetailsData.forms[table]?.[data]?.["form1"]?.Status) {
          arr.push(caseDetailsData.forms[table]?.[data]?.["form1"]?.Status);
        }
        if (caseDetailsData.forms[table]?.[data]?.["form2"]?.Status) {
          arr.push(caseDetailsData.forms[table]?.[data]?.["form2"]?.Status);
        }
        if (caseDetailsData.forms[table]?.[data]?.["form3"]?.Status) {
          arr.push(caseDetailsData.forms[table]?.[data]?.["form3"]?.Status);
        }
        if (caseDetailsData.forms[table]?.[data]?.["form4"]?.Status) {
          arr.push(caseDetailsData.forms[table]?.[data]?.["form4"]?.Status);
        }
      });
    });
    //     if(arr.includes("Stop Check"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "Stop Check"]));
    //     else if(arr.includes("Red"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "Red"]));
    //     else if(arr.includes("Amber"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "Amber"]));
    //    else if(arr.includes("IRWIP"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "IRWIP"]));
    //     else if(arr.includes("IRCEP"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "IRCEP"]));
    //     else if(arr.includes("NA"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "NA"]));
    //     else if(arr.includes("Green"))
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "Green"]));
    //     else
    //     dispatch(changeCaseDetail(["Color Code (Red/Amber/Green/IRCEP/Stop Case)", "NA"]));
  }, [caseDetailsData]);

  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <table>
        <thead>
          <tr>
            <th colSpan={4} className="heading" style={{ textAlign: "center" }}>
              <h3>Case Details</h3>
            </th>
          </tr>
        </thead>
        <div class="container-fluid">
          <div class="row row-cols-2">
            {Object.keys(caseDetailsData.caseDetails).map((item, index) => {
              return (
                <div class="col">
                  <div class="row row-cols-2 ">
                    <div class="col">
                      <div class="cellTitle row row-cols-2">
                        <td class="col-9">
                          <input
                            class="largeCelltitle"
                            type="text"
                            readonly=""
                            title={item}
                            placeholder={item}
                            value={item}
                          />
                        </td>
                        <td class="cellTitlewithIcon col-3">
                          {item !== "Case Reference Number/URN No." ? (
                            item !== "Client /Scope" && (
                              <div class="row">
                                <div class="col-6">
                                  <button
                                    class="editIcon fa fa-pencil"
                                    type="button"
                                    data-bs-toggle="modal"
                                    title="Edit Cell Title"
                                    data-bs-target={`#${
                                      modelId + selectedCellPosition
                                    }`}
                                    onClick={() => {
                                      setSelectedCellPosition(index);
                                      setSelectedCellTitle(item);
                                    }}
                                    data-bs-whatever="@mdo"
                                  ></button>
                                </div>
                                <div class="col-6">
                                  <button
                                    class="editIcon fa fa-trash"
                                    type="button"
                                    title="Delete Cell Title"
                                  ></button>
                                </div>
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                        </td>
                      </div>
                    </div>
                    <td
                      id={["row-", item].join("")}
                      style={{ overflow: "hidden" }}
                    >
                      {caseDetailsData?.caseDetails[item]?.type === "date" ? (
                        <DatePickerReact
                          inputTagName={item}
                          val={caseDetailsData?.caseDetails[item]?.value}
                          isSubtable={true}
                          fun={(name, value) => {
                            addDateVal(name, value);
                          }}
                        />
                      ) : item === "Client /Scope" ? (
                        <select
                          id="clientSpecificSelect"
                          name={item}
                          onBlur={(event) => {
                            if (event.target.value === "") {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title:
                                  "Do you want to set client specific as null? Doing so may lead to data loss. Click Ok to continue and Cancel to cancel",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onSetClientSpecific(
                                    event.target.name,
                                    event.target.value
                                  );
                                },
                                onDisprove: () => {
                                  document.getElementById(
                                    "clientSpecificSelect"
                                  ).value =
                                    caseDetailsData.caseDetails[item].value;
                                },
                              });
                            } else changeHnadler(event);
                          }}
                          style={{ width: "100%" }}
                          disabled={!informate360Refrence}
                        >
                          <option></option>
                          {clientSpecificList.map((items, index) => {
                            return items ===
                              caseDetailsData.caseDetails[item]?.value ? (
                              <option value={items} selected>
                                {items}
                              </option>
                            ) : (
                              <option value={items}>{items}</option>
                            );
                          })}
                        </select>
                      ) : item.includes("Color Code") ? (
                        caseDetailsData.caseDetails[item]?.value ===
                        "IR-Red" ? (
                          <div
                            className={"Red"}
                            style={{
                              width: "112%",
                              height: "100%",
                              marginLeft: "-6%",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "7%",
                                paddingTop: "1%",
                              }}
                            >
                              {caseDetailsData.caseDetails[item]?.value}
                            </div>
                          </div>
                        ) : caseDetailsData.caseDetails[item]?.value.indexOf(
                            "-"
                          ) === -1 ? (
                          <div
                            className={caseDetailsData.caseDetails[item]?.value}
                            style={{
                              width: "112%",
                              height: "100%",
                              marginLeft: "-6%",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "7%",
                                paddingTop: "1%",
                              }}
                            >
                              {caseDetailsData.caseDetails[item]?.value}
                            </div>
                          </div>
                        ) : (
                          <div className="row-Color Code">
                            {caseDetailsData.caseDetails[item]?.value
                              .split("-")
                              .map((item, index) => (
                                <>
                                  {index !== 0 ? <span>-</span> : <></>}
                                  <span className={item}>{item}</span>
                                </>
                              ))}
                          </div>
                        )
                      ) : item === "LOA" ? (
                        <RecursiveModalNew
                          mainTable="Case Details"
                          subTable={"case"}
                          annexure={"Y1"}
                        />
                      ) : (
                        <input
                          name={item}
                          type="text"
                          value={
                            item === "Case Reference Number/URN No."
                              ? informate360Refrence
                              : caseDetailsData?.caseDetails[item]?.value
                          }
                          onChange={changeHnadler}
                          disabled={item === "Case Reference Number/URN No."}
                        />
                      )}
                    </td>
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              className="btn btn-sm btn-outline-dark col-sm-2"
              style={{
                paddingRight: "4px",
                paddingLeft: "4px",
              }}
              data-bs-toggle="modal"
              title="Add One More Cell"
              data-bs-target="#addCellModelCaseDetail"
              data-bs-whatever="@mdo"
            >
              <b
                style={{
                  color: "#32cd32",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Add Cell
              </b>
            </button>
          </div>
        </div>
        <AddCellModel
          inputFields={caseDetailsData?.caseDetails}
          onaddCell={addCell}
          uniqueName="CaseDetail"
        />
        <EditCellTitleModel
          modelId={modelId + selectedCellPosition}
          onEditCell={EditCell}
          currentTitle={selectedCellTitle}
          setCurrentTitle={setSelectedCellTitle}
        />
      </table>
      <ExecutiveSummaryNew />
    </>
  );
};
export default CaseDetails;
