import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  IsInfmRefNumNotFilled: true,
};

export const flagSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setInfmRefNumNotFilled: (state, action) => {
      console.log(action.payload);
      state.IsInfmRefNumNotFilled = action.payload;
    },
  },
});

export const { setInfmRefNumNotFilled } = flagSlice.actions;

export default flagSlice.reducer;
