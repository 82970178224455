import axios from "axios";
import "../styles/pageStyles.css";
import { BASE_URL } from "../constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addFilesToState,
  fillCourtCheckDbForm,
  editCellInCourtCheckDetailForm,
  deleteCellInSummayDetailForm,
  addCellInSubFormsType2,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import AddCellModel from "./AddCellModel";
import CustomDropDown from "./CustomDropDown";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFunNew";
import { TailSpin } from "react-loader-spinner";
import RecursiveModal from "./RecursiveModalNew";
import NotificationAlert from "./NotificationAlert";
import EditCellTitleModel from "./EditCellTitleModel";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";

const CourtCheckNew = ({ annexure }) => {
  const modelId = "CourtCheckCellTileEdit";

  const dispatch = useDispatch();

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Court Verification"]
  );
  const CourtCheckForms = useSelector(
    (state) => state.pdfdatanew.forms["Court Verification"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addFiles = (e) => {
    const ip = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < ip.length; i++) {
      formData.append(e.target.name, ip[i]);
    }
    axios({
      method: "post",
      url: `${BASE_URL}form/file/validate`,
      data: formData,
    })
      .then((res) => {
        dispatch(addFilesToState([e.target.name, e.target.files]));
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "One or more files are invalid",
          type: "error",
        });
      });
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType2({
        type: typecellref,
        fieldType,

        position,
        mainTable,
        subTable,
      })
    );
  };

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillCourtCheckDbForm([
        "Court Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (component === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Employment Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (selectedSubForm === "" || selectedCellPosition === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    const mainTable = "Court Verification";
    dispatch(
      editCellInCourtCheckDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
  };

  useEffect(() => {
    Object.keys(CourtCheckForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Court Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(CourtCheckForms).map((table, index) => {
      dispatch(
        fillCourtCheckDbForm([
          "Court Verification",
          table,
          "form2",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Court Record Verification</u>
      </h3>

      <>
        {Object.keys(CourtCheckForms).map((table, index) => (
          <table key={table + index}>
            <tr>
              <th className="heading" colSpan={3}>
                <h3>{table}</h3>
                {
                  <div
                    className="loadingAnimation"
                    id={`tailspin${table}`}
                    style={{
                      marginTop: "-25px",
                      visibility: "hidden",
                    }}
                  >
                    <TailSpin
                      style={{}}
                      height="30"
                      width="40"
                      color="red"
                      ariaLabel="loading"
                    />
                    <span>&nbsp;Please Wait!!!</span>
                  </div>
                }
              </th>
            </tr>
            <tr>
              {table === "Court Proceedings" ? (
                <th className="centered-text" colSpan={3}>
                  Civil Proceedings (Civil and High Court)
                </th>
              ) : (
                <th className="centered-text" colSpan={3}>
                  Criminal Proceedings (Magistrate, Sessions and High Court)
                </th>
              )}
            </tr>
            <tr>
              {table === "Court Proceedings" ? (
                <th className="centered-text" colSpan={3}>
                  Includes Original Suit, Miscellaneous Suit, Execution and
                  Arbitration Case
                </th>
              ) : (
                <th className="centered-text" colSpan={3}>
                  Includes Criminal Petitions, Criminal Appeal, Sessions Case,
                  Special Sessions Case, Criminal Miscellaneous Petition and
                  Criminal Revision Appeal.
                </th>
              )}
            </tr>
            {Object.keys(CourtCheckForms[table]["form1"]).map((row, index) => (
              <>
                <tr>
                  {row === "Employer name" ||
                  row === "Joining Date" ||
                  row === "Leaving Date" ||
                  row === "Period of Employment - As per document" ? (
                    <td className="cellTitle col-sm-6">
                      <div className="row row-cols-2">
                        <div className="col-sm-12">{row}</div>
                      </div>
                    </td>
                  ) : (
                    <td className="cellTitle col-sm-6">
                      <div className="row row-cols-2">
                        <div className="col-sm-10">{row}</div>
                        <div className="col-sm-2 hoverEdit">
                          <div className="col-6">
                            <button
                              className="editIcon fa fa-pencil"
                              type="button"
                              data-bs-toggle="modal"
                              title="Edit Cell Title"
                              data-bs-target={`#${modelId}`}
                              data-bs-whatever="@mdo"
                              onClick={() => {
                                setSelectedCellPosition(index);
                                setSelectedSubForm(table);
                                setselectedFormNumber("form2");
                                setSelectedCellTitle(row);
                              }}
                            ></button>
                          </div>
                          <div className="col-6">
                            <button
                              className="editIcon fa fa-trash"
                              type="button"
                              title="Delete Cell Title"
                              onClick={(e) => {
                                setConfirmation({
                                  isOpen: true,
                                  color: "error",
                                  title: "Are you sure to delete this cell?",
                                  subTitle: "You can't undo this operation!",
                                  onConfirm: () => {
                                    onDeleteCell(index, table, "form2");
                                  },
                                });
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                  <td key={row + table + index}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={
                        CourtCheckForms[table]["form1"][row].infoProvided
                      }
                      name="infoProvided"
                      onBlur={(e) => {
                        addVal(e, table, "form1", row);
                      }}
                    />
                  </td>
                  <td>
                    <InfoVerifiedDropDown
                      fun={(e) => addVal(e, table, "form1", row)}
                      currentValue={
                        CourtCheckForms[table]["form1"][row].infoVerified
                      }
                    />
                  </td>
                </tr>
              </>
            ))}
            {Object.keys(CourtCheckForms[table]["form2"]).map((row, index) => (
              <tr key={row + table + index}>
                {row === "Status" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        {row === "Original suit (Civil court)" &&
                          row === "Appeals (High court)" &&
                          row === "Criminal cases (CC)" &&
                          row ===
                            "Private Compliant Report (PCR) – (Magistrate court)" &&
                          row === "Criminal appeals (Sessions court)" &&
                          row === "Criminal appeals (High court)" && (
                            <div className="col-6">
                              <button
                                className="editIcon fa fa-pencil"
                                type="button"
                                data-bs-toggle="modal"
                                title="Edit Cell Title"
                                data-bs-target={`#${modelId}`}
                                data-bs-whatever="@mdo"
                                onClick={() => {
                                  setSelectedCellPosition(index);
                                  setSelectedSubForm(table);
                                  setselectedFormNumber("form2");
                                  setSelectedCellTitle(row);
                                }}
                              ></button>
                            </div>
                          )}
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form2");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>
                )}

                {row === "Status" ? (
                  <ColorCode
                    namefield={row}
                    cls={CourtCheckForms[table]["form2"][row]}
                    fun={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                    spanNum={2}
                  />
                ) : row === "Original suit (Civil court)" ||
                  row === "Appeals (High court)" ||
                  row === "Criminal cases (CC)" ||
                  row ===
                    "Private Compliant Report (PCR) – (Magistrate court)" ||
                  row === "Criminal appeals (Sessions court)" ||
                  row === "Criminal appeals (High court)" ? (
                  <td colSpan={2}>
                    <CustomDropDown
                      fun={(e) => addVal(e, table, "form2", row)}
                      currentValue={CourtCheckForms[table]["form2"][row]}
                      arr={["Record Found", "No Record Found"]}
                    />
                  </td>
                ) : (
                  <td key={row + table + index} colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      id={table + row + "ROCUpdate"}
                      defaultValue={CourtCheckForms[table]["form2"][row]}
                      disabled={row === "Annexure Details" ? true : false}
                      name={row}
                      onBlur={(e) => {
                        addVal(e, table, "form2", row);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={2}>
                <RecursiveModal
                  mainTable="Court Verification"
                  subTable={table}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>{" "}
            {/* Upload Area */}
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>{" "}
            {/* Annexure Area */}
            <tr>
              <td>
                {" "}
                <button
                  type="button"
                  className="btn btn-sm btn-outline-dark col-sm-8"
                  style={{
                    paddingRight: "4px",
                    paddingLeft: "4px",
                  }}
                  data-bs-toggle="modal"
                  title="Add One More Cell"
                  data-bs-target={`#addCellModelEmpVerification${index}`}
                  data-bs-whatever="@mdo"
                >
                  <b
                    style={{
                      color: "#32cd32",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Add Cell
                  </b>
                </button>
              </td>
              <td colSpan={2}></td>
            </tr>{" "}
            {/* Add Cell Area */}
            <AddCellModel
              inputFields={CourtCheckForms[table]["form1"]}
              onaddCell={(typecellref, position, fieldType) =>
                addCell(
                  typecellref,
                  position,
                  fieldType,
                  "Employment Verification",
                  table
                )
              }
              uniqueName={`EmpVerification${index}`}
            />
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default CourtCheckNew;
