import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  fillCourtCheckDbForm,
  editCellInCourtCheckDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import "../styles/pageStyles.css";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import { BASE_URL } from "../constants";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const CourtCheck = ({ annexure }) => {
  const modelId = "CourtCheckCellTileEdit";

  const CourtCheckForms = useSelector(
    (state) => state.pdfdata.forms["Court Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const FatherName = useSelector((state) => state.pdfdata["Father Name"]);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addFiles = (e) => {
    const ip = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < ip.length; i++) {
      formData.append(e.target.name, ip[i]);
    }
    axios({
      method: "post",
      url: `${BASE_URL}form/file/validate`,
      data: formData,
    })
      .then((res) => {
        dispatch(addFilesToState([e.target.name, e.target.files]));
      })
      .catch(function (error) {
        setNotify({
          isOpen: true,
          message: "One or more files are invalid",
          type: "error",
        });
      });
  };
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(
      fillCourtCheckDbForm([
        "Court Verification",
        table,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(CourtCheckForms).forEach((table) => {
      if (CourtCheckForms[table]["Name of the Applicant"] === "") {
        document.getElementById(`CourtVerification${table}Applicant`).value =
          applicantName.value;
      }
      if (CourtCheckForms[table]["Father Name"] === "" && FatherName) {
        document.getElementById(`CourtVerification${table}FatherName`).value =
          FatherName.value;
      }
    });
  }, [applicantName, FatherName, CourtCheckForms]);

  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (selectedSubForm === "" || selectedCellPosition === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    const mainTable = "Court Verification";
    dispatch(
      editCellInCourtCheckDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Court Verification</u>
      </h3>
      <>
        {Object.keys(CourtCheckForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading" colSpan={2}>
                <h3>{type}</h3>
              </th>
            </tr>
            {Object.keys(CourtCheckForms[type]).map((row, index) => (
              <tr>
                {row === "Name of the Applicant" ? (
                  <td>{row}</td>
                ) : row === "Color Code" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(type);
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Name of the Applicant" ? (
                  <td>
                    {" "}
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CourtVerification${type}Applicant`}
                      defaultValue={
                        CourtCheckForms[type][row] !== ""
                          ? CourtCheckForms[type][row]
                          : applicantName
                      }
                      name={row}
                      onChange={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                ) : row === "Father Name" && FatherName ? (
                  <td>
                    {" "}
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CourtVerification${type}FatherName`}
                      defaultValue={
                        CourtCheckForms[type][row] !== ""
                          ? CourtCheckForms[type][row]
                          : FatherName
                      }
                      name={row}
                      onChange={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                ) : row === "Color Code" ? (
                  <ColorCode
                    cls={CourtCheckForms[type][row]}
                    namefield={row}
                    fun={(e) => addVal(e, type)}
                    spanNum={1}
                  />
                ) : row === "Upload Document" ? (
                  <td>
                    <input
                      type="file"
                      name={type}
                      multiple
                      onChange={(e) => addFiles(e)}
                    />
                    <span className="alert">
                      Files of format .jpg, .jpeg, .png, .eml are accepted!
                    </span>
                  </td>
                ) : (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      defaultValue={CourtCheckForms[type][row]}
                      name={row}
                      onBlur={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Court Verification"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                />
                {/* <UploadPopUp
                  mainTable="Court Verification"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                /> */}
                {/* <UploadExtension
                  annexure={annexure + (index + 1)}
                  mainTable="Court Verification"
                  nme={type}
                /> */}
                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Court Verification"
              namefield={type}
              spanNum={1}
            />
            <tr>
              <td colSpan={2}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default CourtCheck;
