import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillForm16 } from "../store/pdfdata/pdfdataSlice";
import RecursiveModal from "./RecursiveModal";
import SummaryRemarks from "./SummaryRemarks";

const Form16 = ({ annexure }) => {
  const UANForms = useSelector(
    (state) => state.pdfdata.forms["Form16 Verification"]
  );
  const dispatch = useDispatch();

  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillForm16([table, e.target.name, e.target.value]));
  };
  return (
    <div>
      <h3 className="text-center mt-4">
        <u>Form16 Verification</u>
      </h3>
      {Object.keys(UANForms).map((table, index) => (
        <table>
          {Object.keys(UANForms[table]).map((row) => (
            <tr>
              <td>{row}</td>
              <td style={{ backgroundColor: "white" }}>
                <textarea
                  style={{ width: "100%", height: 30 }}
                  type="text"
                  name={row}
                  defaultValue={UANForms[table][row]}
                  onBlur={(e) => {
                    addVal(e, table);
                  }}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Form16 Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Form16 Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </div>
  );
};
export default Form16;
