import React, { useState, useEffect } from "react";
import "../styles/pageStyles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  addImagePropsTitle,
  deleteImageFromDb,
  removeImagesUsingTitle,
  fillEduEmpForm,
  editCellInSummayDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import axios from "axios";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import { BASE_URL } from "../constants";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import { setError } from "../store/error/errorSlice";
import uniqueString from "unique-string";
import { TailSpin } from "react-loader-spinner";
import NotificationAlert from "./NotificationAlert";

const Employment = ({ annexure }) => {
  const modelId = "employmentCellTileEdit";

  const empForms = useSelector(
    (state) => state.pdfdata.forms["Employment Verification"]
  );

  const attachmentList = useSelector((state) => state.pdfdata.attachments);
  const imgStruc = useSelector(
    (state) => state.pdfdata.imgStruc["Employment Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [companyNameList, setCompanyNameList] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();

  useEffect(() => {
    async function findCompanyNames() {
      axios
        .get(`${BASE_URL}client/companies`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setCompanyNameList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Company Name List!", e, false])
          );
        });
    }
    findCompanyNames();
  }, []);

  const addRocDetails = (e, table, form, component, value) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Employment Verification",
        table,
        form,
        component,
        component,
        value,
      ])
    );
  };

  const addRocDocument = async (e, subTable, annexure) => {
    document.getElementById(`tailspin${subTable}`).style.visibility = "visible";
    console.log("VALUE", e.target.value);
    let inputVal = e.target.value.trim().toLowerCase();
    let formVal = empForms[subTable]["form1"][
      "Name of Origination (and branch worked in)"
    ].infoProvided
      .trim()
      .toLowerCase();

    console.log("FORM", empForms);
    if (formVal === "") {
      await axios({
        url: `${BASE_URL}client/company/${encodeURIComponent(e.target.value)}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          return axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .then((img) => {
              console.log(img);
              const file = new File([img.data], `Roc Snapshot.png`, {
                lastModified: new Date(),
                type: "image/png",
              });
              addRocDetails(
                e,
                subTable,
                "form2",
                "ROC Registration",
                res.data.data.CompanyName +
                  "\nDate of Incorporation: " +
                  res.data.data.DateOfIncorporation
              );
              document.getElementById(
                subTable + "ROC RegistrationROCUpdate"
              ).value =
                res.data.data.CompanyName +
                "\nDate of Incorporation: " +
                res.data.data.DateOfIncorporation;
              //   empForms[subTable]["form2"]["ROC Registration"];
              addRocDetails(
                e,
                subTable,
                "form2",
                "Registration Number",
                res.data.data.RegistrationNumber
              );
              document.getElementById(
                subTable + "Registration NumberROCUpdate"
              ).value = res.data.data.RegistrationNumber;
              addRocDetails(e, subTable, "form2", "CIN", res.data.data.CIN);
              document.getElementById(subTable + "CINROCUpdate").value =
                res.data.data.CIN;
              dispatch(
                addImagePropsTitle([
                  "",
                  "Employment Verification",
                  subTable,
                  currentKey,
                  "ROC Snapshot",
                  annexure,
                ])
              );
              dispatch(addFilesToState([currentKey, { ...[file] }]));
              addVal(
                e,
                subTable,
                "form1",
                "Name of Origination (and branch worked in)"
              );
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            });
        })
        .catch((error) => {
          dispatch(setError(["Error in fetching Company info!", error, false]));
        });
    } else if (inputVal !== formVal) {
      const keyObject = Object.entries(imgStruc[subTable]).filter((item) => {
        console.log(item);
        return item[1].title === "ROC Snapshot";
      });
      console.log(keyObject);
      if (
        keyObject !== undefined &&
        keyObject.length !== 0 &&
        keyObject.length[0] !== 0
      ) {
        const key = keyObject[0][0];
        console.log(key);
        const mainTable = "Employment Verification";
        dispatch(
          removeImagesUsingTitle({
            mainTable,
            subTable,
            key,
          })
        );
        if (
          attachmentList[key] !== undefined &&
          attachmentList[key].length > 0
        ) {
          const path = attachmentList[key][0];
          console.log(path);
          console.log(imgStruc[subTable][key]);
          dispatch(deleteImageFromDb(path));
        }
      }

      await axios({
        url: `${BASE_URL}client/company/${encodeURIComponent(e.target.value)}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          const img = await axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            })
            .finally(() => {
              document.getElementById(`tailspin${subTable}`).style.visibility =
                "hidden";
            });
          console.log(img);
          const file = new File([img.data], `Roc Snapshot.png`, {
            lastModified: new Date(),
            type: "image/png",
          });
          addRocDetails(
            e,
            subTable,
            "form2",
            "ROC Registration",
            res.data.data.CompanyName +
              "\nDate of Incorporation: " +
              res.data.data.DateOfIncorporation
          );
          document.getElementById(
            subTable + "ROC RegistrationROCUpdate"
          ).value =
            res.data.data.CompanyName +
            "\nDate of Incorporation: " +
            res.data.data.DateOfIncorporation;
          // empForms[subTable]["form2"]["ROC Registration"];
          addRocDetails(
            e,
            subTable,
            "form2",
            "Registration Number",
            res.data.data.RegistrationNumber
          );
          document.getElementById(
            subTable + "Registration NumberROCUpdate"
          ).value = res.data.data.RegistrationNumber;
          addRocDetails(e, subTable, "form2", "CIN", res.data.data.CIN);
          document.getElementById(subTable + "CINROCUpdate").value =
            res.data.data.CIN;
          dispatch(
            addImagePropsTitle([
              "",
              "Employment Verification",
              subTable,
              currentKey,
              "ROC Snapshot",
              annexure,
            ])
          );
          dispatch(addFilesToState([currentKey, { ...[file] }]));
          addVal(
            e,
            subTable,
            "form1",
            "Name of Origination (and branch worked in)"
          );
        })
        .catch((error) => {
          dispatch(setError(["Error in fetching Company info!", error, false]));
        })
        .finally(() => {
          document.getElementById(`tailspin${subTable}`).style.visibility =
            "hidden";
        });
    }
    document.getElementById(`tailspin${subTable}`).style.visibility = "hidden";
  };

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Employment Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (component === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Employment Verification";

    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };

  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Employment Verification</u>
      </h3>
      {Object.keys(empForms).map((table, index) => (
        <table key={table + index}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
              {
                <div
                  className="loadingAnimation"
                  id={`tailspin${table}`}
                  style={{
                    marginTop: "-25px",
                    visibility: "hidden",
                  }}
                >
                  <TailSpin
                    style={{}}
                    height="30"
                    width="40"
                    color="red"
                    ariaLabel="loading"
                  />
                  <span>&nbsp;Please Wait!!!</span>
                </div>
              }
            </th>
          </tr>
          <tr>
            <th className="centered-text">Components</th>
            <th className="centered-text">Information Provided</th>
            <th className="centered-text">Information Verified</th>
          </tr>
          {Object.keys(empForms[table]["form1"]).map((row, index) => (
            <>
              <tr>
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form1");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
                <td key={row + table + index}>
                  {row === "Name of Origination (and branch worked in)" ? (
                    <>
                      <input
                        list="companies"
                        // style={{ width: 315, height: 30 }}
                        type="text"
                        defaultValue={
                          empForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          //addVal(e, table, "form1", row);
                          addRocDocument(e, table, index + 1);
                        }}
                      />
                      <datalist id="companies">
                        <option></option>
                        {companyNameList.map((item, index) => {
                          return item ===
                            empForms[table]["form1"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </>
                  ) : (
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={empForms[table]["form1"][row].infoProvided}
                      name="infoProvided"
                      onBlur={
                        (e) => {
                          addVal(e, table, "form1", row);
                        }
                        // onBlur(e, item.component)
                      }
                    />
                  )}
                </td>
                <td>
                  <InfoVerifiedDropDown
                    fun={(e) => addVal(e, table, "form1", row)}
                    currentValue={empForms[table]["form1"][row].infoVerified}
                  />
                  {/* <input
                    type="text"
                    defaultValue={empForms[table]["form1"][row].infoVerified}
                    name="infoVerified"
                    onBlur={(e) => {
                      addVal(e, table, "form1", row);
                    }}
                  /> */}
                </td>
              </tr>
            </>
          ))}
          {Object.keys(empForms[table]["form2"]).map((row, index) => (
            <tr key={row + table + index}>
              {row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form2");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                  </div>
                  {row === "Upload Document" ? (
                    <>
                      {" "}
                      For
                      <b>
                        {" "}
                        Annexure {annexure}-{index + 1}
                      </b>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              )}

              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={empForms[table]["form2"][row]}
                  fun={(e) => {
                    addVal(e, table, "form2", row);
                  }}
                  spanNum={2}
                />
              ) : (
                <td key={row + table + index} colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    id={table + row + "ROCUpdate"}
                    defaultValue={empForms[table]["form2"][row]}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Employment Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Employment Verification"
            namefield={table}
            spanNum={2}
          />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </>
  );
};
export default Employment;
