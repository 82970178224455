import { configureStore } from "@reduxjs/toolkit";

import flagSliceReducer from "./flags/flagSlice";
import errorSliceReducer from "./error/errorSlice";
import pdfdataReducer from "./pdfdata/pdfdataSlice";
import recordSliceReducer from "./records/recordSlice";
import pdfdataReducerNew from "./pdfdata/pdfdataSliceNew";
import flagIsAnnexureTileSliceReducer from "./flags/flagIsAnnexureTileSlice";

export const store = configureStore({
  reducer: {
    pdfdata: pdfdataReducer,
    pdfdatanew: pdfdataReducerNew,
    error: errorSliceReducer,
    flags: flagSliceReducer,
    records: recordSliceReducer,
    annexureFlag: flagIsAnnexureTileSliceReducer,
  },
});
