import React, { useMemo, useState, useEffect } from "react";
import ExecutiveSummary from "./ExecutiveSummary";
import Legend from "./Legend";
import "../styles/pageStyles.css";
import { useDispatch } from "react-redux";
import {
  changeExecutiveSummary,
  fetchDataByRefNum,
  changeFormLoadingState,
  makeStateBlank,
  addCellInPersonalInfo,
  editCellInPersonalInfo,
  deleteCellInPersonalInfo,
  fetchDataByClientSpecific,
  addExecutiveSummaryTypes,
} from "../store/pdfdata/pdfdataSlice";
import { useSelector } from "react-redux";
import moment from "moment/moment.js";
import AddCellModel from "./AddCellModel";
import EditCellTitleModel from "./EditCellTitleModel";
import { BASE_URL } from "../constants";

import DatePickerReact from "./DatePickerReact";
import { setError } from "../store/error/errorSlice";
import axios from "axios";
import { setInfmRefNumNotFilled } from "../store/flags/flagSlice";
import ConfirmationDialog from "./ConfirmationDialog";
import NotificationAlert from "./NotificationAlert";

const PersonalInformation = ({
  colorCodeArray,
  setFlag,
  infomate360ReferenceNumber,
}) => {
  const modelId = "personalinfoEditCell";
  const isFormLoading = useSelector((state) => state.isFormLoading);
  const currState = useSelector((state) => state.pdfdata);
  const clientSpecificVal = useSelector(
    (state) => state.pdfdata["Client Specific"].value
  );
  const dispatch = useDispatch();
  const [clientSpecificList, setClientSpecificList] = useState([]);
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const InfmRefNumNotFilled = useSelector(
    (state) => state.flags.IsInfmRefNumNotFilled
  );
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const returnTrue = (e) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    return true;
  };
  useEffect(() => {
    if (infomate360ReferenceNumber !== "") {
      dispatch(fetchDataByRefNum([dispatch, infomate360ReferenceNumber]));
    }
  }, []);

  useEffect(() => {
    async function findColor() {
      axios
        .get(`${BASE_URL}client/specifics`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setClientSpecificList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Client Specific List!", e, false])
          );
        });
    }
    findColor();
  }, []);

  useEffect(() => {
    if (clientSpecificVal === "") {
      dispatch(addExecutiveSummaryTypes({ executiveSummary: {}, forms: {} }));
    } else {
      dispatch(fetchDataByClientSpecific([dispatch, clientSpecificVal]));
    }
  }, [clientSpecificVal]);

  const fetchDataInfoRef = (valueInfo) => {
    setFlag(false);
    if (valueInfo === "") {
      let flag = 0;
      if (Object.keys(currState["forms"]).length !== 0) {
        console.log("Here", currState["forms"]);
        flag = 1;
      } else {
        Object.keys(currState).forEach((key) => {
          if (typeof currState[key] === "string" && currState[key] !== "")
            flag = 1;
        });
      }
      let response;
      if (flag === 1) {
        response = window.confirm("Do want to clear all data?");
      }
      if (response === true) {
        dispatch(makeStateBlank());
      }
    } else {
      let res = window.confirm(`Do you want to fetch data for ${valueInfo}?`);
      if (res === true) {
        dispatch(changeFormLoadingState(true));
        dispatch(makeStateBlank());
        dispatch(fetchDataByRefNum([dispatch, valueInfo])).finally(() => {
          dispatch(setInfmRefNumNotFilled(false));
        });

        if (currState["Client Specific"].value !== "") {
          dispatch(
            fetchDataByClientSpecific([
              dispatch,
              currState["Client Specific"].value,
            ])
          );
        }
        dispatch(
          changeExecutiveSummary(["infomate360ReferenceNumber", valueInfo])
        );
      }
    }
  };
  const personalInfoFormHandler = (e) => {
    e.preventDefault();
    if (e.target.name === "Client Specific") {
      const clientSpecific = e.target.value;
      if (clientSpecific !== "") {
        dispatch(fetchDataByClientSpecific([dispatch, clientSpecific]));
      }
    }
    // else if (e.target.name === "infomate360ReferenceNumber") {
    //   setFlag(false);
    //   // if (e.target.value === "") {
    //   //   let flag = 0;
    //   //   if (Object.keys(currState["forms"]).length !== 0) {
    //   //     console.log("Here", currState["forms"]);
    //   //     flag = 1;
    //   //   } else {
    //   //     Object.keys(currState).forEach((key) => {
    //   //       if (typeof currState[key] === "string" && currState[key] !== "")
    //   //         flag = 1;
    //   //     });
    //   //   }
    //   //   let response;
    //   //   if (flag === 1) {
    //   //     response = window.confirm("Do want to clear all data?");
    //   //   }
    //   //   if (response === true) {
    //   //     dispatch(makeStateBlank());
    //   //   }
    //   // } else {
    //   // let res = window.confirm(
    //   //  `Do you want to fetch data for ${e.target.value}?`
    //   //);
    //   //if (res === true) {
    //   dispatch(changeFormLoadingState(true));
    //   dispatch(makeStateBlank());
    //   dispatch(fetchDataByRefNum([dispatch, e.target.value]));
    //   if (currState["Client Specific"].value !== "") {
    //     dispatch(
    //       fetchDataByClientSpecific([
    //         dispatch,
    //         currState["Client Specific"].value,
    //       ])
    //     );
    //   }
    //   //   }
    //   // }
    // }
    if (e.target.type === "date") {
      let formattedDate = moment(e.target.value, "YYYY-MM-DD").format(
        "DD-MMM-YYYY"
      );
      if (formattedDate === "Invalid date") formattedDate = "";
      dispatch(changeExecutiveSummary([e.target.name, formattedDate]));
    } else {
      dispatch(changeExecutiveSummary([e.target.name, e.target.value]));
    }
  };

  const inputFields = useMemo(() => {
    const p = {};
    for (const key in currState) {
      if (
        key === "executiveSummary" ||
        key === "files" ||
        key === "imgStruc" ||
        key === "forms" ||
        key === "isFormLoading" ||
        key === "attachments" ||
        key === "attachmentKeysToDelete" ||
        key === "dummyForms" ||
        key === "dummyExecutiveSummary"
      ) {
      } else {
        p[key] = currState[key];
        if (
          key === "infomate360ReferenceNumber" &&
          document.getElementById("InfmId") !== null
        ) {
          console.log("memo");
          document.getElementById("InfmId").value = currState[key];
        }
      }
    }
    return p;
  }, [currState]);

  const addCell = (typecellref, fieldType, position) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(addCellInPersonalInfo({ type: typecellref, fieldType, position }));
  };
  const [selectedCellPosition, setSelectedCellPosition] = useState();

  const EditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    dispatch(
      editCellInPersonalInfo({ type: cellTitileRef, selectedCellPosition })
    );
  };
  const onSetClientSpecific = (nam, value) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    dispatch(
      addExecutiveSummaryTypes({
        executiveSummary: {},
        forms: {},
      })
    );
    dispatch(changeExecutiveSummary([nam, value]));
  };
  const deleteCell = (index) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    dispatch(deleteCellInPersonalInfo(index));
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {InfmRefNumNotFilled && (
        <div className="col d-flex justify-content-end">
          <div className=" font-weight-bold text-danger ">
            *Please enter Infomate360 Reference Number first!!
          </div>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th colSpan={4} className="heading" style={{ textAlign: "center" }}>
              <h3>CONFIDENTIAL BACKGROUND INVESTIGATION REPORT</h3>
            </th>
          </tr>
        </thead>
        <div className="container-fluid">
          <div className="row row-cols-2">
            {Object.keys(inputFields).map((e, index) => {
              if (e === "Applicant Name") {
                return (
                  <React.Fragment key={e}>
                    <td className="col-sm-3">{e}</td>
                    <td className="col-sm-9">
                      <input
                        name={e}
                        type={inputFields[e].type}
                        value={inputFields[e].value}
                        onChange={personalInfoFormHandler}
                        disabled={InfmRefNumNotFilled}
                      />
                    </td>
                  </React.Fragment>
                );
              }
              if (
                e === "Client Reference No." ||
                e === "infomate360ReferenceNumber" ||
                e === "Client Specific" ||
                e === "Gender"
              ) {
                return (
                  <div className="col" key={e}>
                    <div className="row row-cols-2">
                      <td>
                        {e === "infomate360ReferenceNumber" ? (
                          <input
                            className="largeCelltitle"
                            type="text"
                            readOnly
                            value="Infomate360 Reference No"
                            title="Infomate360 Reference No"
                            placeholder="Infomate360 Reference No"
                          />
                        ) : (
                          <input
                            className="largeCelltitle"
                            type="text"
                            readOnly
                            value={e}
                            title={e}
                            placeholder={e}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          display: "flex",
                        }}
                      >
                        {e === "Client Specific" ? (
                          <select
                            id="clientSpecificSelect"
                            name={e}
                            onBlur={(event) => {
                              if (event.target.value === "") {
                                setConfirmation({
                                  isOpen: true,
                                  color: "error",
                                  title:
                                    "Do you want to set client specific as null? Doing so may lead to data loss. Click Ok to continue and Cancel to cancel",
                                  subTitle: "You can't undo this operation!",
                                  onConfirm: () => {
                                    onSetClientSpecific(
                                      event.target.name,
                                      event.target.value
                                    );
                                  },
                                  onDisprove: () => {
                                    document.getElementById(
                                      "clientSpecificSelect"
                                    ).value = inputFields[e].value;
                                  },
                                });

                                // if (
                                //   window.confirm(
                                //     "Do you want to set client specific as null? Doing so may lead to data loss. Click Ok to continue and Cancel to cancel"
                                //   )
                                // ) {
                                //   dispatch(
                                //     addExecutiveSummaryTypes({
                                //       executiveSummary: {},
                                //       forms: {},
                                //     })
                                //   );
                                //   dispatch(
                                //     changeExecutiveSummary([
                                //       event.target.name,
                                //       event.target.value,
                                //     ])
                                //   );
                                // } else {
                                // document.getElementById(
                                //   "clientSpecificSelect"
                                // ).value = inputFields[e].value;
                                // }

                                console.log(inputFields[e].value);
                              } else personalInfoFormHandler(event);
                            }}
                            style={{ width: "100%" }}
                            disabled={InfmRefNumNotFilled}
                          >
                            <option></option>
                            {clientSpecificList.map((item, index) => {
                              return item === inputFields[e].value ? (
                                <option value={item} selected>
                                  {item}
                                </option>
                              ) : (
                                <option value={item}>{item}</option>
                              );
                            })}
                          </select>
                        ) : e === "Gender" ? (
                          <select
                            name={e}
                            onBlur={personalInfoFormHandler}
                            style={{ width: "100%" }}
                            disabled={InfmRefNumNotFilled}
                          >
                            <option></option>
                            {inputFields[e].value === "Male" ? (
                              <option value="Male" selected>
                                Male
                              </option>
                            ) : (
                              <option value="Male">Male</option>
                            )}
                            {inputFields[e].value === "Female" ? (
                              <option value="Female" selected>
                                Female
                              </option>
                            ) : (
                              <option value="Female">Female</option>
                            )}
                            {inputFields[e].value === "Others" ? (
                              <option value="Others" selected>
                                Others
                              </option>
                            ) : (
                              <option value="Others">Others</option>
                            )}
                          </select>
                        ) : e === "infomate360ReferenceNumber" ? (
                          <>
                            <input
                              id="InfmId"
                              name={e}
                              type="text"
                              defaultValue={inputFields[e]}
                              disabled={!InfmRefNumNotFilled}
                              // onBlur={(e) => {
                              //   if (e.target.value.trim() !== "")
                              //     dispatch(setInfmRefNumNotFilled(false));

                              // }}
                            />
                            <button
                              className="btn btn-outline-dark fa fa-search"
                              disabled={!InfmRefNumNotFilled}
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  document
                                    .getElementById("InfmId")
                                    .value.trim() !== ""
                                )
                                  fetchDataInfoRef(
                                    document.getElementById("InfmId").value
                                  );
                                personalInfoFormHandler(e);
                              }}
                            ></button>
                          </>
                        ) : (
                          <input
                            name={e}
                            type="text"
                            value={inputFields[e].value}
                            onChange={personalInfoFormHandler}
                            disabled={InfmRefNumNotFilled}
                          />
                        )}
                      </td>
                    </div>
                  </div>
                );
              }
              if (inputFields[e].type === "date") {
                return (
                  <React.Fragment key={e}>
                    <div className="col" key={e}>
                      <div className="row row-cols-2 ">
                        {inputFields.infomate360ReferenceNumber ? (
                          <div className="col">
                            <div className="cellTitle row row-cols-2">
                              <td
                                className="col-9"
                                style={{ borderRight: "none" }}
                              >
                                <input
                                  className="largeCelltitle"
                                  type="text"
                                  readOnly
                                  value={e}
                                  title={e}
                                  placeholder={e}
                                />
                              </td>
                              <td className="cellTitlewithIcon col-3">
                                <div className="row">
                                  <div className="col-6">
                                    <button
                                      className="editIcon fa fa-pencil"
                                      type="button"
                                      data-bs-toggle="modal"
                                      title="Edit Cell Title"
                                      data-bs-target={`#${modelId + index}`}
                                      data-bs-whatever="@mdo"
                                      onClick={() => {
                                        setSelectedCellPosition(index);
                                        setSelectedCellTitle(e);
                                      }}
                                    ></button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      className="editIcon fa fa-trash"
                                      type="button"
                                      title="Delete Cell Title"
                                      onClick={(e) => {
                                        setConfirmation({
                                          isOpen: true,
                                          color: "error",
                                          title:
                                            "Are you sure to delete this cell?",
                                          subTitle:
                                            "You can't undo this operation!",
                                          onConfirm: () => {
                                            deleteCell(index);
                                          },
                                        });
                                      }}
                                    ></button>
                                  </div>
                                </div>
                              </td>
                            </div>
                          </div>
                        ) : (
                          <td className="cellTitle">{e}</td>
                        )}

                        <td>
                          {!InfmRefNumNotFilled && (
                            <DatePickerReact
                              inputTagName={e}
                              val={inputFields[e].value}
                              fun={(event) => personalInfoFormHandler(event)}
                            />
                          )}
                        </td>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              if (e === "Color Code" && (index + 1) % 2 === 0) {
                return (
                  <React.Fragment key={e}>
                    <td className="col-sm-3" id="colorCodeTd">
                      Color Code
                    </td>
                    {inputFields.infomate360ReferenceNumber ? (
                      <td className="col-sm-9">
                        <div className="row row-cols-2">
                          {inputFields["Color Code"].value === "IR-Red" ? (
                            <div className="Red col-sm-10">
                              {inputFields["Color Code"].value}
                            </div>
                          ) : inputFields["Color Code"].value.indexOf("-") ===
                            -1 ? (
                            <div
                              className={[
                                inputFields["Color Code"].value,
                                "col-sm-10",
                              ].join(" ")}
                            >
                              {inputFields["Color Code"].value}
                            </div>
                          ) : (
                            <div className="col-sm-10">
                              {inputFields["Color Code"].value
                                .split("-")
                                .map((item, index) => (
                                  <>
                                    {index !== 0 ? <span>-</span> : <></>}
                                    <span className={item}>{item}</span>
                                  </>
                                ))}
                            </div>
                          )}
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-dark col-sm-2"
                            style={{
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                            data-bs-toggle="modal"
                            title="Add One More Cell"
                            data-bs-target="#addCellModelPersonalInfo"
                            data-bs-whatever="@mdo"
                          >
                            <b
                              style={{
                                color: "#32cd32",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Add Cell
                            </b>
                          </button>
                        </div>
                      </td>
                    ) : (
                      <td className="col-sm-9">
                        <div className="row row-cols-2">
                          {inputFields["Color Code"].value === "IR-Red" ? (
                            <div className="Red col-sm-10">
                              {inputFields["Color Code"].value}
                            </div>
                          ) : inputFields["Color Code"].value.indexOf("-") ===
                            -1 ? (
                            <div
                              className={[
                                inputFields["Color Code"].value,
                                "col-sm-10",
                              ].join(" ")}
                            >
                              {inputFields["Color Code"].value}
                            </div>
                          ) : (
                            <div className="col-sm-10">
                              {inputFields["Color Code"].value
                                .split("-")
                                .map((item, index) => (
                                  <>
                                    {index !== 0 ? <span>-</span> : <></>}
                                    <span className={item}>{item}</span>
                                  </>
                                ))}
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </React.Fragment>
                );
              }
              if (e === "Color Code" && (index + 1) % 2 !== 0) {
                return (
                  <div className="col" key={e}>
                    {inputFields.infomate360ReferenceNumber ? (
                      <div className="row row-cols-3">
                        <td className="col-sm-6">{e}</td>
                        <td className="col-sm-6">
                          <div className="row row-cols-2">
                            {inputFields["Color Code"].value === "IR-Red" ? (
                              <div className="Red col-sm-10">
                                {inputFields["Color Code"].value}
                              </div>
                            ) : inputFields["Color Code"].value.indexOf("-") ===
                              -1 ? (
                              <div
                                className={[
                                  inputFields["Color Code"].value,
                                  "col-sm-10",
                                ].join(" ")}
                              >
                                {inputFields["Color Code"].value}
                              </div>
                            ) : (
                              <div className="col-sm-10">
                                {inputFields["Color Code"].value
                                  .split("-")
                                  .map((item, index) => (
                                    <>
                                      {index !== 0 ? <span>-</span> : <></>}
                                      <span className={item}>{item}</span>
                                    </>
                                  ))}
                              </div>
                            )}
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-dark col-sm-2"
                              style={{
                                paddingBottom: "0px",
                                paddingTop: "3px",
                              }}
                              data-bs-toggle="modal"
                              title="Add One More Cell"
                              data-bs-target="#addCellModelPersonalInfo"
                              data-bs-whatever="@mdo"
                            >
                              <b
                                style={{
                                  color: "#32cd32",
                                  fontWeight: "900",
                                  fontSize: "18px",
                                }}
                              >
                                +
                              </b>
                            </button>
                          </div>
                        </td>
                      </div>
                    ) : (
                      <div className="row row-cols-3">
                        <td className="col-sm-6">{e}</td>
                        <td className="col-sm-6">
                          <div className="row row-cols-2">
                            {inputFields["Color Code"].value === "IR-Red" ? (
                              <div className="Red col-sm-10">
                                {inputFields["Color Code"].value}
                              </div>
                            ) : inputFields["Color Code"].value.indexOf("-") ===
                              -1 ? (
                              <div
                                className={[
                                  inputFields["Color Code"].value,
                                  "col-sm-10",
                                ].join(" ")}
                              >
                                {inputFields["Color Code"].value}
                              </div>
                            ) : (
                              <div className="col-sm-10">
                                {inputFields["Color Code"].value
                                  .split("-")
                                  .map((item, index) => (
                                    <>
                                      {index !== 0 ? <span>-</span> : <></>}
                                      <span className={item}>{item}</span>
                                    </>
                                  ))}
                              </div>
                            )}
                          </div>
                        </td>
                      </div>
                    )}
                  </div>
                );
              }
              return (
                <React.Fragment key={e}>
                  <div className="col" key={e}>
                    <div className="row row-cols-2 ">
                      {inputFields.infomate360ReferenceNumber ? (
                        <div className="col">
                          <div className="cellTitle row row-cols-2">
                            <td
                              className="col-9"
                              style={{ borderRight: "none" }}
                            >
                              <input
                                className="largeCelltitle"
                                type="text"
                                readOnly
                                value={e}
                                title={e}
                                placeholder={e}
                              />
                            </td>
                            <td className="cellTitlewithIcon col-3">
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    className="editIcon fa fa-pencil"
                                    type="button"
                                    data-bs-toggle="modal"
                                    title="Edit Cell Title"
                                    data-bs-target={`#${modelId + index}`}
                                    data-bs-whatever="@mdo"
                                    onClick={() => {
                                      setSelectedCellPosition(index);
                                      setSelectedCellTitle(e);
                                    }}
                                  ></button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className="editIcon fa fa-trash"
                                    type="button"
                                    title="Delete Cell Title"
                                    onClick={(e) => {
                                      setConfirmation({
                                        isOpen: true,
                                        color: "error",
                                        title:
                                          "Are you sure to delete this cell?",
                                        subTitle:
                                          "You can't undo this operation!",
                                        onConfirm: () => {
                                          deleteCell(index);
                                        },
                                      });
                                    }}
                                  ></button>
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      ) : (
                        <td className="cellTitle">
                          <input
                            className="largeCelltitle"
                            type="text"
                            readOnly
                            value={e}
                            title={e}
                            placeholder={e}
                          />
                        </td>
                      )}

                      <td>
                        <input
                          name={e}
                          type="text"
                          value={inputFields[e].value}
                          onChange={personalInfoFormHandler}
                          disabled={InfmRefNumNotFilled}
                        />
                      </td>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <AddCellModel
          inputFields={inputFields}
          onaddCell={addCell}
          uniqueName="PersonalInfo"
        />
        <EditCellTitleModel
          modelId={modelId + selectedCellPosition}
          onEditCell={EditCell}
          currentTitle={selectedCellTitle}
          setCurrentTitle={setSelectedCellTitle}
        />
      </table>
      <Legend type="1" />
      {isFormLoading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <></>
      )}
      <ExecutiveSummary />
    </div>
  );
};
export default PersonalInformation;
