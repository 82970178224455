import { Route, Routes, useNavigate } from "react-router-dom";
import LoginMui from "./components/LoginMui";
import Dashboard from "./components/Dashboard";
import Clientspecificform from "./components/ClientSpecificForm";
import AppApplicant from "./AppApplicant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setError } from "./store/error/errorSlice";
import AppApplicantNew from "./AppApplicantNew";

function App() {
  const errorObj = useSelector((state) => state.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      errorObj.message === "TokenExpiredError" ||
      errorObj.message === "InvalidToken" ||
      errorObj.message === "AuthenticationFailed"
    ) {
      window.alert(
        "Your session expired! Please Login Again!! :" + errorObj.name
      );
      navigate("/");
      window.location.reload(false);
    } else if (errorObj.message !== "" && errorObj.name !== "") {
      window.alert(errorObj.message + " : " + errorObj.name);
    }
    return () => {
      dispatch(setError(["", ""]));
    };
  }, [errorObj, navigate]);

  return (
    <Routes>
      <Route path="/" exact element={<LoginMui />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/applicant" element={<AppApplicant />} />
      <Route path="/techmreport" element={<AppApplicantNew />} />
      <Route path="/client-info" element={<Clientspecificform />} />
    </Routes>
  );
}

export default App;