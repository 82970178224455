import React from "react";
import { BASE_URL } from "../constants";

function UGCDetailsModal(props) {
  const {
    universityNameList,
    inputFiles,
    university,
    setuniversity,
    isDragActive,
    getRootProps,
    getInputProps,
    handleSearch,
    handleFieldChange,
    handleUniversitySubmit,
    universityInitial,
    setcurrentForm,
    setinputFiles,
  } = props;

  return (
    <div
      className="modal fade"
      id="UGC"
      tabindex="-1"
      role="dialog"
      aria-labelledby="UGCLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog dialog-of-modal" role="document">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <h5 className="modal-title title-modal" id="companyLabel">
              University Document
            </h5>
          </div>
          <hr />

          <div className="modal-body body-modal">
            <div className="mb-2">
              <label className="form-label float-start">
                Search University:
              </label>
              <input
                className="form-control-sm border border-secondary"
                list="universities"
                name="university"
                id="universitySearch"
                onBlur={(e) => handleSearch(e)}
              />
              <datalist id="universities">
                <option></option>
                {universityNameList.map((item) => {
                  return (
                    <option value={item} selected>
                      {item}
                    </option>
                  );
                })}
              </datalist>
            </div>
            <div className="mb-2">
              <label className="form-label float-start">
                {" "}
                University Name{" "}
              </label>
              <input
                id="university"
                className="form-control-sm border border-secondary"
                type="text"
                name="UniversityName"
                value={university.UniversityName}
                onChange={(e) => handleFieldChange(e)}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {university.Attachments.map((img, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        marginTop: "2rem",
                        maxWidth: "50%",
                        border: "1px solid grey",
                        display: "block;",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        window.open(e.target.src);
                      }}
                      // key={index}
                      src={`${BASE_URL}form/image/${img}`}
                      alt={img}
                    />

                    <button
                      type="button"
                      className="button-cross button-cross-hover "
                      style={{
                        marginLeft: "4%",
                      }}
                      onClick={(e) => {
                        let deleteArray = university.attachmentKeysToDelete;
                        deleteArray.push(img);
                        let attachmentsArray = university.Attachments;
                        attachmentsArray = attachmentsArray.filter(function (
                          value,
                          index,
                          arr
                        ) {
                          return value !== img;
                        });
                        setuniversity({
                          ...university,
                          attachmentKeysToDelete: deleteArray,
                          Attachments: attachmentsArray,
                        });
                      }}
                    >
                      <b
                        style={{
                          color: "red",
                        }}
                      >
                        X
                      </b>
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="mb-2">
              <label htmlFor="" className="mb-2">
                Upload new Document or Replace existing one
              </label>
              <div
                {...getRootProps()}
                style={{
                  backgroundColor: "#ECEDEE",
                  borderStyle: "dotted",
                  borderColor: "#6C757D",
                  color: "#979EA2",
                }}
              >
                <input
                  {...getInputProps()}
                  id="companyDoc"
                  name="companyForm"
                />
                {isDragActive ? (
                  <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                    Drop the files here ...
                  </p>
                ) : (
                  <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                    {" "}
                    Drag 'n' drop some files here, or click to select files
                  </p>
                )}
              </div>
              <aside>
                <h3
                  style={{
                    display: `${inputFiles.length === 0 ? "none" : "block"}`,
                  }}
                >
                  Files
                </h3>
                <ul>
                  {inputFiles.map((file, index) => {
                    return (
                      <li key={file.path} style={{ color: "#7087ba" }}>
                        {file.path}
                      </li>
                    );
                  })}
                </ul>
              </aside>
            </div>
          </div>
          <hr />
          <div className="modal-footer justify-content-around">
            <button
              type="button"
              title="Save New Entry"
              className="btn-modal button-close button-close-hover"
              onClick={(e) => {
                handleUniversitySubmit(e);
              }}
            >
              Submit{" "}
            </button>
            <button
              type="button"
              title="Close This Modal"
              className="btn-modal button-close button-close-hover"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("universitySearch").value = "";
                setuniversity(universityInitial);
                setcurrentForm("university");
                setinputFiles([]);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UGCDetailsModal;