import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  fillIdForm,
  editCellInSummayDetailForm,
  deleteCellInSummayDetailForm,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import NotificationAlert from "./NotificationAlert";
import SummaryRemarksNew from "./SummaryRemarksNew";
import RecursiveModalNew from "./RecursiveModalNew";
import EditCellTitleModel from "./EditCellTitleModel";
import ConfirmationDialog from "./ConfirmationDialog";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";

const IdentityNew = ({ annexure }) => {
  const modelId = "identityCellTileEdit";

  const dispatch = useDispatch();

  const dob = useSelector((state) => state.pdfdatanew["Date of Birth"]);
  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const idForms = useSelector(
    (state) => state.pdfdatanew.forms["Identity Verification"]
  );

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Identity Verification"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Name of Applicant"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    let value = e.target.value;
    dispatch(fillIdForm([table, form, component, e.target.name, value]));
    if (component === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Identity Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };

  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Identity Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };
  const addDateVal = (table, form, component, name, value) => {
    dispatch(fillIdForm([table, form, component, name, value]));
  };

  useEffect(() => {
    Object.keys(idForms).map((table, index) => {
      dispatch(
        fillIdForm([
          table,
          "form1",
          "Date of birth",
          "infoProvided",
          dob?.value,
        ])
      );
      if (table === "Driving Licence") {
        dispatch(
          fillIdForm([
            "Driving Licence",
            "form1",
            "Name of the candidate",
            "infoProvided",
            applicantName?.value,
          ])
        );
      }
    });
  }, [dob, applicantName]);

  useEffect(() => {
    Object.keys(idForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Identity Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(idForms).map((table, index) => {
      dispatch(
        fillIdForm([
          table,
          "form2",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure, idForms]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Identity Verification</u>
      </h3>
      <>
        {Object.keys(idForms).map((table, index) => (
          <table key={table}>
            <tr>
              <th className="heading" colSpan={3}>
                <h3>{table}</h3>
              </th>
            </tr>
            <tr>
              <th className="centered-text">Components</th>
              <th className="centered-text">Information Provided</th>
              <th className="centered-text">Information Verified</th>
            </tr>
            {Object.keys(idForms[table]["form1"]).map((row, index) => (
              <>
                <tr>
                  {row === "Name of the candidate" ? (
                    <>
                      <td className="col-sm-6">{row}</td>

                      <td>
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          id={`IdentityVerification${table}`}
                          type="text"
                          defaultValue={
                            idForms[table]["form1"][row].infoProvided !== ""
                              ? idForms[table]["form1"][row].infoProvided
                              : applicantName?.value
                          }
                          name="infoProvided"
                          disabled={true}
                        />
                      </td>
                    </>
                  ) : row === "Date of birth" && dob ? (
                    <>
                      <td className="col-sm-6">{row}</td>

                      <td>
                        <DatePickerReact
                          inputTagName={row}
                          val={
                            idForms[table]["form1"][row].infoProvided
                              ? idForms[table]["form1"][row].infoProvided
                              : dob.value
                          }
                          disabled={true}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="cellTitle">
                        <div className="row row-cols-2">
                          <div className="col-sm-10">{row}</div>
                          <div className="col-sm-2 hoverEdit">
                            <div className="col-6">
                              <button
                                className="editIcon fa fa-pencil"
                                type="button"
                                data-bs-toggle="modal"
                                title="Edit Cell Title"
                                data-bs-target={`#${modelId}`}
                                data-bs-whatever="@mdo"
                                onClick={() => {
                                  setSelectedCellPosition(index);
                                  setSelectedSubForm(table);
                                  setselectedFormNumber("form1");
                                  setSelectedCellTitle(row);
                                }}
                              ></button>
                            </div>
                            <div className="col-6">
                              <button
                                className="editIcon fa fa-trash"
                                type="button"
                                title="Delete Cell Title"
                                onClick={(e) => {
                                  setConfirmation({
                                    isOpen: true,
                                    color: "error",
                                    title: "Are you sure to delete this cell?",
                                    subTitle: "You can't undo this operation!",
                                    onConfirm: () => {
                                      onDeleteCell(index, table, "form1");
                                    },
                                  });
                                }}
                              ></button>
                            </div>
                          </div>
                        </div>
                      </td>
                      {row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                        <td>
                          <DatePickerReact
                            inputTagName={row}
                            val={idForms[table]["form1"][row]?.infoProvided}
                            isSubtable={true}
                            fun={(name, value) => {
                              addDateVal(
                                table,
                                "form1",
                                row,
                                "infoProvided",
                                value
                              );
                            }}
                          />
                        </td>
                      ) : (
                        <td>
                          <textarea
                            style={{ width: "100%", height: 30 }}
                            type="text"
                            defaultValue={
                              idForms[table]["form1"][row].infoProvided
                            }
                            name="infoProvided"
                            onBlur={
                              (e) => {
                                addVal(e, table, "form1", row);
                              }
                              // onBlur(e, item.component)
                            }
                          />
                        </td>
                      )}
                    </>
                  )}
                  <td>
                    <InfoVerifiedDropDown
                      fun={(e) => addVal(e, table, "form1", row)}
                      currentValue={idForms[table]["form1"][row].infoVerified}
                    />
                    {/* <input
                      type="text"
                      defaultValue={idForms[table]["form1"][row].infoVerified}
                      name="infoVerified"
                      onBlur={(e) => {
                        addVal(e, table, "form1", row);
                      }}
                    /> */}
                  </td>
                </tr>
              </>
            ))}
            {Object.keys(idForms[table]["form2"]).map((row, index) => (
              <tr>
                {row === "Status" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form2");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form2");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Upload Document" ? (
                  <>
                    {" "}
                    For{" "}
                    <b>
                      Annexure {annexure}-{index + 1}
                    </b>
                  </>
                ) : (
                  <></>
                )}
                {row === "Status" ? (
                  <ColorCode
                    namefield={row}
                    cls={idForms[table]["form2"][row]}
                    fun={(e) => addVal(e, table, "form2", row)}
                    spanNum={2}
                  />
                ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                  <td key={row + table + index} colSpan={2}>
                    <DatePickerReact
                      inputTagName={row}
                      val={idForms[table]["form2"][row]}
                      isSubtable={true}
                      fun={(name, value) => {
                        addDateVal(table, "form2", row, name, value);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={idForms[table]["form2"][row]}
                      disabled={row === "Annexure Details" ? true : false}
                      name={row}
                      onBlur={(e) => {
                        addVal(e, table, "form2", row);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={2}>
                <RecursiveModalNew
                  mainTable="Identity Verification"
                  subTable={table}
                  annexure={annexure + (index + 1)}
                />
                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
            <ConfirmationDialog
              confirmation={confirmation}
              setConfirmation={setConfirmation}
            />
          </table>
        ))}
      </>
    </div>
  );
};

export default IdentityNew;
