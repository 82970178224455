import React, { useRef, useState } from "react";

const AddCellModel = ({ inputFields, onaddCell, uniqueName }) => {
  const typecellref = useRef();
  const [selectedPosition, setSelectedPosition] = useState("");
  const [fieldType, setFieldType] = useState("text");
  const handleChange = (event) => {
    setSelectedPosition(event.target.value);
  };
  const handleFieldTypeChange = (event) => {
    if (fieldType === "text") {
      setFieldType("date");
    } else {
      setFieldType("text");
    }
  };
  return (
    <div
      className="modal fade"
      id={`addCellModel${uniqueName}`}
      tabIndex="-1"
      aria-labelledby="addCellModelLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog dialog-of-modal">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <h5 className="modal-title title-modal" id="addCellModelLabel">
              Add New Cell
            </h5>
          </div>
          <hr />
          <div className="modal-body body-modal">
            <form>
              <div className="mb-3">
                <div className="form-group mt-3">
                  <label htmlFor="sel1" className="form-label float-start">
                    * New Cell Will Be Added Just After The Selected Cell
                  </label>
                  <select
                    className="form-control border border-secondary"
                    id="sel1"
                    defaultValue=""
                    value={selectedPosition}
                    onChange={handleChange}
                  >
                    <option>Select Cell...</option>
                    {Object.keys(inputFields).map((posList, index) => {
                      if (
                        posList === "Applicant Name" ||
                        posList === "Client Reference No." ||
                        posList === "infomate360ReferenceNumber" ||
                        posList === "Date of Birth" ||
                        posList === "Color Code"
                      ) {
                        return <React.Fragment key={posList}></React.Fragment>;
                      }
                      return (
                        <React.Fragment key={posList}>
                          <option
                            style={{ border: "2px solid black" }}
                            value={index + 1}
                          >
                            {posList}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </select>
                  <div className="row">
                    <div className="col-sm-6">
                      <label
                        htmlFor="recipient-name"
                        className="mt-4 float-start"
                        >
                        Cell Title:
                      </label>
                    </div>
                    {uniqueName === "PersonalInfo" ? (
                      <div className="col-sm-6">
                        <div className="form-check mt-4 float-end">
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            Date Type?
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            onChange={handleFieldTypeChange}
                            id="defaultCheck1"
                          />
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <input
                    type="text"
                    ref={typecellref}
                    className="form-control-sm border border-secondary"
                  />
                </div>
              </div>
            </form>
          </div>
          <hr />
          <div className="modal-footer justify-content-around">
            <button
              className="btn-modal button-close button-close-hover"
              title="Save New Cell"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onaddCell(
                  typecellref.current.value,
                  fieldType,
                  selectedPosition
                );
                typecellref.current.value = "";
                setSelectedPosition("");
                setFieldType("text");
                document.getElementById("defaultCheck1").checked = false;
              }}
              data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              title="Close Add New Cell Modal"
              data-bs-dismiss="modal"
              className="btn-modal button-close button-close-hover"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCellModel;