import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSummaryRemarks } from "../store/pdfdata/pdfdataSliceNew";
import { BASE_URL } from "../constants";
import { setError } from "../store/error/errorSlice";
import axios from "axios";

const SummaryRemarksNew = ({ type, namefield, spanNum }) => {
  const [summaryRemarksValues, setSummaryRemarksValues] = useState([]);
  useEffect(() => {
    async function findSummaryRemarks() {
      axios
        .get(`${BASE_URL}form/resource/remarks`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setSummaryRemarksValues(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError([
              "Error in fetching Remarks List!",
              e.response.data.message,
            ])
          );
        });
    }
    findSummaryRemarks();
  }, []);
  const summaryObj = {
    data: [...summaryRemarksValues],
  };
  const execSum = useSelector((state) => state.pdfdatanew.executiveSummary);
  const dispatch = useDispatch();
  const addToExecSummary = (e, type) => {
    dispatch(addSummaryRemarks([type, e.target.name, e.target.value]));
  };
  return (
    <tr>
      <td>Summary Remarks</td>
      <td colSpan={spanNum}>
        <input
          name={namefield}
          defaultValue={execSum[type][namefield].remarks}
          type="text"
          list="d1"
          onBlur={(e) => addToExecSummary(e, type)}
        />
        <datalist id="d1">
          {summaryObj.data.map((item, index) => {
            return <option value={item}></option>;
          })}
        </datalist>
      </td>
    </tr>
  );
};
export default SummaryRemarksNew;
