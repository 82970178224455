import React  from 'react'
function useThrottle(value, interval) {
    if (interval === void 0) { interval = 500; }
    var _a = (0, React.useState)(value), throttledValue = _a[0], setThrottledValue = _a[1];
    var lastExecuted = (0, React.useRef)(Date.now());
    (0, React.useEffect)(function () {
        if (Date.now() >= lastExecuted.current + interval) {
            lastExecuted.current = Date.now();
            setThrottledValue(value);
        }
        else {
            var timerId_1 = setTimeout(function () {
                lastExecuted.current = Date.now();
                setThrottledValue(value);
            }, interval);
            return function () { return clearTimeout(timerId_1); };
        }
    }, [value, interval]);
    return throttledValue;
}

export default useThrottle;
