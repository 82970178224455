import React, { useState } from "react";

const EditCellTitleModel = ({
  onEditCell,
  modelId,
  currentTitle,
  setCurrentTitle,
}) => {
  const [cellTitle, setCellTitle] = useState(currentTitle);

  return (
    <div
      className="modal fade"
      id={modelId? modelId : 'dummy'}
      tabIndex="-1"
      aria-labelledby="EditCellModelLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog dialog-of-modal">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <h5 className="modal-title title-modal" id="EditCellModelLabel">
              Edit Cell Title
            </h5>
          </div>
          <div className="modal-body body-modal">
            <form>
              <div className="mb-3">
                <div className="form-group mt-3">
                  <label
                    htmlFor="recipient-name"
                    className="form-label float-start"
                  >
                    New Title
                  </label>
                  <input
                    type="text"
                    value={currentTitle}
                    onChange={(e) => {
                      setCellTitle(e.target.value);
                      setCurrentTitle(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="modal-footer justify-content-around">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onEditCell(cellTitle);
                    setCellTitle("");
                  }}
                  data-bs-dismiss="modal"
                  className="btn-modal button-close button-close-hover"
                  title="Save New Title"
                >
                  Save
                </button>
                <button
                  className="btn-modal button-close button-close-hover"
                  type="button"
                  title="Close This Edit Modal"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCellTitleModel;
