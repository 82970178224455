import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillOfficialNameChangeForm,
  editCellInOfficialNameChangeDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const OfficialNameChangeVerification = ({ annexure }) => {
  const modelId = "OfficialNameChangeCellTileEdit";

  const officialNameChangeForms = useSelector(
    (state) => state.pdfdata.forms["Official Name Change Verification"]
  );

  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addVal = (e, table) => {
    e.preventDefault();
    let value = e.target.value;
    // if (e.target.name.toLowerCase().indexOf("date") !== -1) {
    //   value = moment(e.target.value, "YYYY-MM-DD").format("DD-MMM-YYYY");
    //   if (value == "Invalid date") value = "";
    // }
    dispatch(fillOfficialNameChangeForm([table, e.target.name, value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    console.log(cellTitileRef, selectedCellPosition);
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Official Name Change Verification";
    dispatch(
      editCellInOfficialNameChangeDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Official Name Change Verification</u>
      </h3>
      {Object.keys(officialNameChangeForms).map((table, index) => (
        <table key={table}>
          {Object.keys(officialNameChangeForms[table]).map((row, index) => (
            <tr>
              <td className="cellTitle col-sm-6">
                {row === "Color Code" ? (
                  <>{row}</>
                ) : (
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          console.log(row, index);
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setSelectedCellTitle(row);
                          // setselectedFormNumber("form1");
                        }}
                      ></button>
                    </div>
                  </div>
                )}
                {row === "Upload Document" ? (
                  <>
                    {" "}
                    For
                    <b> Annexure {annexure}</b>
                  </>
                ) : (
                  <></>
                )}
              </td>
              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={officialNameChangeForms[table][row]}
                  fun={(e) => {
                    addVal(e, table);
                  }}
                  spanNum={1}
                />
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    defaultValue={officialNameChangeForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Official Name Change Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Official Name Change Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default OfficialNameChangeVerification;
