import React, { useEffect, useState } from "react";
import "../styles/pageStyles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fillCriminalDbForm,
  editCellInDataBaseDetailForm,
  addDatabaseFormValue,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import RecursiveModal from "./RecursiveModal";
import CustomDropDown from "./CustomDropDown";
import ColorCheckFun from "./ColorCheckFunNew";
import NotificationAlert from "./NotificationAlert";
import SummaryRemarksNew from "./SummaryRemarksNew";
import RecursiveModalNew from "./RecursiveModalNew";
import EditCellTitleModel from "./EditCellTitleModel";

const DatabaseVerificationNew = ({ annexure }) => {
  const modelId = "dataBaseCellTileEdit";

  const dispatch = useDispatch();

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Database Verification"]
  );
  const FatherName = useSelector(
    (state) => state.pdfdatanew["caseDetails"]["Father Name"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Applicant Name"]
  );
  const databaseForms = useSelector(
    (state) => state.pdfdatanew.forms["Database Verification"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addVal = (e, table) => {
    console.log(" e.target.name", e.target.name);
    console.log(" e.target.value", e.target.value);
    e.preventDefault();
    dispatch(
      addDatabaseFormValue([
        "Database Verification",
        table,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const getColor = (databaseForms, table) => {
    const colorSet = new Set(["Stop Check", "Red", "Amber", "Green"]);
    let status = "NA";
    for (const field of Object.keys(databaseForms[table])) {
      if (field !== "Status") {
        if (colorSet.has(field)) {
          status = field;
          break;
        }
      }
    }
    return status;
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    const mainTable = "Database Verification";
    dispatch(
      editCellInDataBaseDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };

  useEffect(() => {
    Object.keys(databaseForms).map((table, index) => {
      if (imgStruc && imgStruc["db"]) {
        Object.keys(imgStruc["db"]).map((img) => {
          if (imgStruc["db"][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Database Verification",
                "db",
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(databaseForms).map((table, index) => {
      dispatch(
        addDatabaseFormValue([
          "Database Verification",
          table,
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  useEffect(() => {
    Object.keys(databaseForms).forEach((table) => {
      if (databaseForms[table]["Name of the Applicant"] === "") {
        document.getElementById(`DatabaseVerification${table}Applicant`).value =
          applicantName?.value;
      }
      if (databaseForms[table]["Father Name"] === "" && FatherName) {
        document.getElementById(`DatabaseVerification${table}Father`).value =
          FatherName?.value;
      }
      let colorArray = [];
      Object.keys(databaseForms[table]).forEach((fields) => {
        if (fields !== "Status") {
          colorArray.push(databaseForms[table][fields]);
        }
      });
      let sColorValue = "";
      if (Object.values(colorArray).includes("Stop Check"))
        sColorValue = "Stop Check";
      else if (Object.values(colorArray).includes("Red")) sColorValue = "Red";
      else if (Object.values(colorArray).includes("Amber"))
        sColorValue = "Amber";
      else if (Object.values(colorArray).includes("Green"))
        sColorValue = "Green";
      else sColorValue = "NA";
      if (sColorValue)
        dispatch(
          addDatabaseFormValue([
            "Database Verification",
            table,
            "Status",
            sColorValue,
          ])
        );
      ColorCheckFun(dispatch, execSummary, table, sColorValue);
    });
  }, [applicantName, FatherName, databaseForms]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Database Verification</u>
      </h3>
      <>
        {Object.keys(databaseForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading">
                <h3>Executive Summary</h3>
              </th>
              <th className="heading">
                <h3>Database Verification</h3>
              </th>
              <th className="heading">
                <h3>Status</h3>
              </th>
            </tr>
            <tr>
              <td rowspan="4">
                India Specific Regulatory & Compliance Database (Bases Detail
                searches color code to be mentioned in executive Summary)
              </td>
              <td>Civil Litigation Database Checks – India</td>
              <ColorCode
                cls={databaseForms[type].IndiaCL}
                namefield={"IndiaCL"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">
                Credit and Reputational Risk Database Checks – India
              </td>
              <ColorCode
                cls={databaseForms[type].IndiaCR}
                namefield={"IndiaCR"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">
                Criminal Records Database Checks – India
              </td>
              <ColorCode
                cls={databaseForms[type].IndiaCRR}
                namefield={"IndiaCRR"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">Directorship Watch List</td>
              <ColorCode
                cls={databaseForms[type].DirectorWL}
                namefield={"DirectorWL"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td rowspan="4">
                Database Global (Bases Detail searches color code to be
                mentioned in executive Summary)
              </td>
              <td>Compliance Authorities Database Checks – Global</td>
              <ColorCode
                cls={databaseForms[type].DirectorWLA}
                namefield={"DirectorWLA"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">
                Regulatory Authorities Database Checks – Global
              </td>
              <ColorCode
                cls={databaseForms[type].GlobalRA}
                namefield={"GlobalRA"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">
                Serious and Organized Crimes Database Checks – Global
              </td>
              <ColorCode
                cls={databaseForms[type].GlobalSR}
                namefield={"GlobalSR"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td className="no-bg">Web and Media Searches - Global</td>
              <ColorCode
                cls={databaseForms[type].GlobalWB}
                namefield={"GlobalWB"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            <tr>
              <td>
                Compliance Link (OFAC) (Prohibited Parties)(Bases Detail
                searches color code to be mentioned in executive Summary)
              </td>
              <td>OFAC Specific Database</td>
              <ColorCode
                cls={databaseForms[type].OFACDT}
                namefield={"OFACDT"}
                fun={(e) => addVal(e, type)}
                spanNum={1}
              />
            </tr>

            {Object.keys(databaseForms[type]).map((row, index) => (
              <tr>
                {row === "Annexure Details" ? (
                  <>
                    <td>{row}</td>
                    <td style={{ backgroundColor: "white" }} colSpan={2}>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        name={row}
                        disabled={row === "Annexure Details" ? true : false}
                        defaultValue={databaseForms[type][row]}
                      />
                    </td>
                  </>
                ) : row === "Status" ? (
                  <>
                    <td>{row}</td>
                    <ColorCode
                      disabled={true}
                      namefield={row}
                      cls={databaseForms[type][row]}
                      fun={(e) => {
                        addVal(e, type);
                      }}
                      spanNum={2}
                    />
                  </>
                ) : null}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={2}>
                <RecursiveModalNew
                  mainTable="Database Verification"
                  subTable={"db"}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>

            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default DatabaseVerificationNew;
