import React, { useState, useEffect } from "react";
import "../styles/pageStyles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  addImagePropsTitle,
  deleteImageFromDb,
  removeImagesUsingTitle,
  fillEduEmpForm,
  updateImgStrucAnnexure,
  editCellInSummayDetailForm,
  addCellInSubFormsType2,
  deleteCellInSummayDetailForm,
} from "../store/pdfdata/pdfdataSliceNew";
import ColorCode from "./ColorCode";
import axios from "axios";
import ColorCheckFun from "./ColorCheckFunNew";
import { BASE_URL } from "../constants";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModalNew";
import { setError } from "../store/error/errorSlice";
import uniqueString from "unique-string";
import { TailSpin } from "react-loader-spinner";
import NotificationAlert from "./NotificationAlert";
import AddCellModel from "./AddCellModel";
import ConfirmationDialog from "./ConfirmationDialog";
import DatePickerReact from "./DatePickerReact";
import CustomDropDown from "./CustomDropDown";
import { MenuItem, Select } from "@mui/material";

const EmploymentNew = ({ annexure }) => {
  const modelId = "employmentCellTileEdit";

  const dispatch = useDispatch();

  const attachmentList = useSelector((state) => state.pdfdatanew.attachments);
  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const empForms = useSelector(
    (state) => state.pdfdatanew.forms["Employment Verification"]
  );
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Employment Verification"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [companyNameList, setCompanyNameList] = useState([]);
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addRocDetails = (e, table, form, component, value) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Employment Verification",
        table,
        form,
        component,
        component,
        value,
      ])
    );
  };

  const addRocDocument = async (e, subTable, annexure) => {
    document.getElementById(`tailspin${subTable}`).style.visibility = "visible";
    console.log("VALUE", e.target.value);
    let inputVal = e.target.value.trim().toLowerCase();
    let formVal = empForms[subTable]["form1"]["Employer name"].infoProvided
      .trim()
      .toLowerCase();

    console.log("FORM", empForms);
    if (formVal === "") {
      await axios({
        url: `${BASE_URL}client/company/${encodeURIComponent(e.target.value)}`,
        method: "GET",
        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          return axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .then((img) => {
              const file = new File([img.data], `Roc Snapshot.png`, {
                lastModified: new Date(),
                type: "image/png",
              });
              document.getElementById(subTable + "MCA (CIN)ROCUpdate").value =
                res.data.data.CIN;
              dispatch(
                fillEduEmpForm([
                  "Employment Verification",
                  subTable,
                  "form2",
                  "MCA (CIN)",
                  "MCA (CIN)",
                  res.data.data.CIN,
                ])
              );
              dispatch(
                addImagePropsTitle([
                  "",
                  "Employment Verification",
                  subTable,
                  currentKey,
                  "ROC Snapshot",
                  annexure,
                ])
              );
              dispatch(addFilesToState([currentKey, { ...[file] }]));
              addVal(e, subTable, "form1", "Employer name");
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            });
        })
        .catch((error) => {
          dispatch(setError(["Error in fetching Company info!", error, false]));
        });
    } else if (inputVal !== formVal) {
      const keyObject = Object.entries(imgStruc[subTable]).filter((item) => {
        console.log(item);
        return item[1].title === "ROC Snapshot";
      });
      console.log(keyObject);
      if (
        keyObject !== undefined &&
        keyObject.length !== 0 &&
        keyObject.length[0] !== 0
      ) {
        const key = keyObject[0][0];
        console.log(key);
        const mainTable = "Employment Verification";
        dispatch(
          removeImagesUsingTitle({
            mainTable,
            subTable,
            key,
          })
        );
        if (
          attachmentList[key] !== undefined &&
          attachmentList[key].length > 0
        ) {
          const path = attachmentList[key][0];
          console.log(path);
          console.log(imgStruc[subTable][key]);
          dispatch(deleteImageFromDb(path));
        }
      }

      await axios({
        url: `${BASE_URL}client/company/${encodeURIComponent(e.target.value)}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      })
        .then(async (res) => {
          console.log(res.data);
          const currentKey = uniqueString();
          const img = await axios
            .get(`${BASE_URL}form/image/${res.data.data.Attachments[0]}`, {
              responseType: "blob",
            })
            .catch(async (e) => {
              try {
                const msg = JSON.parse(await e.response.data.text()).message;

                if (msg !== undefined) {
                  dispatch(
                    setError([
                      "Error in downloading image document!",
                      msg,
                      true,
                    ])
                  );
                }
              } catch {
                dispatch(
                  setError([
                    "Error in downloading image document!",
                    e.toString(),
                    false,
                  ])
                );
              }
            })
            .finally(() => {
              document.getElementById(`tailspin${subTable}`).style.visibility =
                "hidden";
            });
          console.log(img);
          const file = new File([img.data], `Roc Snapshot.png`, {
            lastModified: new Date(),
            type: "image/png",
          });
          addRocDetails(
            e,
            subTable,
            "form2",
            "ROC Registration",
            res.data.data.CompanyName +
              "\nDate of Incorporation: " +
              res.data.data.DateOfIncorporation
          );
          document.getElementById(
            subTable + "ROC RegistrationROCUpdate"
          ).value =
            res.data.data.CompanyName +
            "\nDate of Incorporation: " +
            res.data.data.DateOfIncorporation;
          // empForms[subTable]["form2"]["ROC Registration"];
          addRocDetails(
            e,
            subTable,
            "form2",
            "Registration Number",
            res.data.data.RegistrationNumber
          );
          document.getElementById(
            subTable + "Registration NumberROCUpdate"
          ).value = res.data.data.RegistrationNumber;
          addRocDetails(e, subTable, "form2", "CIN", res.data.data.CIN);
          dispatch(
            fillEduEmpForm([
              "Employment Verification",
              subTable,
              "form2",
              "MCA (CIN)",
              "MCA (CIN)",
              res.data.data.CIN,
            ])
          );
          document.getElementById(subTable + "CINROCUpdate").value =
            res.data.data.CIN;
          document.getElementById(subTable + "MCA (CIN)ROCUpdate").value =
            res.data.data.CIN;
          dispatch(
            addImagePropsTitle([
              "",
              "Employment Verification",
              subTable,
              currentKey,
              "ROC Snapshot",
              annexure,
            ])
          );
          dispatch(addFilesToState([currentKey, { ...[file] }]));
          addVal(e, subTable, "form1", "Employer name");
        })
        .catch((error) => {
          dispatch(setError(["Error in fetching Company info!", error, false]));
        })
        .finally(() => {
          document.getElementById(`tailspin${subTable}`).style.visibility =
            "hidden";
        });
    }
    document.getElementById(`tailspin${subTable}`).style.visibility = "hidden";
  };

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Employment Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (component === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Employment Verification";

    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };

  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType2({
        type: typecellref,
        fieldType,

        position,
        mainTable,
        subTable,
      })
    );
  };

  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Employment Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (table, form, component, name, value) => {
    dispatch(
      fillEduEmpForm([
        "Employment Verification",
        table,
        form,
        component,
        name,
        value,
      ])
    );
  };

  useEffect(() => {
    async function findCompanyNames() {
      axios
        .get(`${BASE_URL}client/companies`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setCompanyNameList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Company Name List!", e, false])
          );
        });
    }
    findCompanyNames();
  }, []);

  useEffect(() => {
    Object.keys(empForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Employment Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(empForms).map((table, index) => {
      dispatch(
        fillEduEmpForm([
          "Employment Verification",
          table,
          "form4",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Employment Verification</u>
      </h3>
      {Object.keys(empForms).map((table, index) => (
        <table key={table + index}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
              {
                <div
                  className="loadingAnimation"
                  id={`tailspin${table}`}
                  style={{
                    marginTop: "-25px",
                    visibility: "hidden",
                  }}
                >
                  <TailSpin
                    style={{}}
                    height="30"
                    width="40"
                    color="red"
                    ariaLabel="loading"
                  />
                  <span>&nbsp;Please Wait!!!</span>
                </div>
              }
            </th>
          </tr>
          <tr>
            <th className="centered-text" colSpan={2}>
              Details as per Subject’s Application Form
            </th>
            <th className="centered-text">Verification Remarks</th>
          </tr>
          {Object.keys(empForms[table]["form1"]).map((row, index) => (
            <>
              <tr>
                {row === "Employer name" ||
                row === "Joining Date" ||
                row === "Leaving Date" ||
                row === "Period of Employment - As per document" ? (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-12">{row}</div>
                    </div>
                  </td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form1");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>

                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form2");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                <td key={row + table + index}>
                  {row === "Employer name" ? (
                    <>
                      <input
                        list="companies"
                        // style={{ width: 315, height: 30 }}
                        type="text"
                        defaultValue={
                          empForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          //addVal(e, table, "form1", row);
                          addRocDocument(e, table, index + 1);
                        }}
                      />

                      <datalist id="companies">
                        <option></option>
                        {companyNameList.map((item, index) => {
                          return item ===
                            empForms[table]["form1"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </>
                  ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                    <>
                      <DatePickerReact
                        inputTagName={row}
                        val={
                          empForms[table]["form1"][row].infoProvided ===
                          "Active"
                            ? ""
                            : empForms[table]["form1"][row].infoProvided
                        }
                        isSubtable={true}
                        fun={(name, value) => {
                          addDateVal(
                            table,
                            "form1",
                            row,
                            "infoProvided",
                            value
                          );
                        }}
                      />
                      {row === "Leaving Date" ? (
                        <Select
                          variant="standard"
                          name="infoProvided"
                          defaultValue={
                            empForms[table]["form1"][row].infoProvided ===
                            "Active"
                              ? "Active"
                              : ""
                          }
                          value={
                            empForms[table]["form1"][row].infoProvided ===
                            "Active"
                              ? "Active"
                              : ""
                          }
                          onChange={(e) =>
                            e.target.value === "Active"
                              ? addVal(e, table, "form1", row)
                              : null
                          }
                          label="Active Employment"
                          sx={{ m: 1, minWidth: 50 }}
                        >
                          <MenuItem value={"Active"}>
                            <em>Active</em>
                          </MenuItem>
                        </Select>
                      ) : null}
                    </>
                  ) : (
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={empForms[table]["form1"][row].infoProvided}
                      name="infoProvided"
                      onBlur={(e) => {
                        addVal(e, table, "form1", row);
                      }}
                    />
                  )}
                </td>
                <td>
                  <InfoVerifiedDropDown
                    fun={(e) => addVal(e, table, "form1", row)}
                    currentValue={empForms[table]["form1"][row].infoVerified}
                  />
                  {/* <input
                    type="text"
                    defaultValue={empForms[table]["form1"][row].infoVerified}
                    name="infoVerified"
                    onBlur={(e) => {
                      addVal(e, table, "form1", row);
                    }}
                  /> */}
                </td>
              </tr>
            </>
          ))}

          {Object.keys(empForms[table]["form2"]).map((row, index) => (
            <tr key={row + table + index}>
              {row === "Status" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-2 hoverEdit">
                      {row !== "Suspicious Database Check (Any findings)" &&
                        row !==
                          "If Not In MCA/ EPFO Site visit status required (Mandate site visit if paid up capital less than 5 lacs)" && (
                          <div className="col-6">
                            <button
                              className="editIcon fa fa-pencil"
                              type="button"
                              data-bs-toggle="modal"
                              title="Edit Cell Title"
                              data-bs-target={`#${modelId}`}
                              data-bs-whatever="@mdo"
                              onClick={() => {
                                setSelectedCellPosition(index);
                                setSelectedSubForm(table);
                                setselectedFormNumber("form2");
                                setSelectedCellTitle(row);
                              }}
                            ></button>
                          </div>
                        )}
                      <div className="col-6">
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              color: "error",
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, table, "form2");
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </div>
                  {row === "Upload Document" ? (
                    <>
                      {" "}
                      For
                      <b>
                        {" "}
                        Annexure {annexure}-{index + 1}
                      </b>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              )}

              {row === "Status" ? (
                <ColorCode
                  namefield={row}
                  cls={empForms[table]["form2"][row]}
                  fun={(e) => {
                    addVal(e, table, "form2", row);
                  }}
                  spanNum={2}
                />
              ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                <td key={row + table + index} colSpan={2}>
                  <DatePickerReact
                    inputTagName={row}
                    val={empForms[table]["form2"][row]}
                    isSubtable={true}
                    fun={(name, value) => {
                      addDateVal(table, "form2", row, name, value);
                    }}
                  />
                </td>
              ) : row === "Suspicious Database Check (Any findings)" ? (
                <td colSpan={2}>
                  <CustomDropDown
                    fun={(e) => addVal(e, table, "form2", row)}
                    currentValue={empForms[table]["form2"][row]}
                    arr={["Found", "Not Found"]}
                  />
                </td>
              ) : row ===
                "If Not In MCA/ EPFO Site visit status required (Mandate site visit if paid up capital less than 5 lacs)" ? (
                <td colSpan={2}>
                  <CustomDropDown
                    fun={(e) => addVal(e, table, "form2", row)}
                    currentValue={empForms[table]["form2"][row]}
                    arr={["Applicable", "Not Applicable"]}
                  />
                </td>
              ) : (
                <td key={row + table + index} colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    id={table + row + "ROCUpdate"}
                    defaultValue={empForms[table]["form2"][row]}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}

          {Object.keys(empForms[table]["form3"]).map((row, index) => (
            <>
              <tr>
                {row === "Employer name" ||
                row === "Joining Date" ||
                row === "Leaving Date" ||
                row === "Period of Employment - As per document" ? (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-12">{row}</div>
                    </div>
                  </td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-2 hoverEdit">
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form3");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-6">
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                color: "error",
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form3");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </td>
                )}
                <td key={row + table + index}>
                  {row === "Employer name" ? (
                    <>
                      <input
                        list="companies"
                        // style={{ width: 315, height: 30 }}
                        type="text"
                        defaultValue={
                          empForms[table]["form3"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          //addVal(e, table, "form1", row);
                          addRocDocument(e, table, index + 1);
                        }}
                      />
                      <datalist id="companies">
                        <option></option>
                        {companyNameList.map((item, index) => {
                          return item ===
                            empForms[table]["form3"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </>
                  ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                    <DatePickerReact
                      inputTagName={row}
                      val={empForms[table]["form3"][row].infoProvided}
                      isSubtable={true}
                      fun={(name, value) => {
                        addDateVal(table, "form3", row, "infoProvided", value);
                      }}
                    />
                  ) : (
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={empForms[table]["form3"][row].infoProvided}
                      name="infoProvided"
                      onBlur={
                        (e) => {
                          addVal(e, table, "form3", row);
                        }
                        // onBlur(e, item.component)
                      }
                    />
                  )}
                </td>
                <td>
                  <InfoVerifiedDropDown
                    fun={(e) => addVal(e, table, "form3", row)}
                    currentValue={empForms[table]["form3"][row].infoVerified}
                  />
                  {/* <input
                    type="text"
                    defaultValue={empForms[table]["form1"][row].infoVerified}
                    name="infoVerified"
                    onBlur={(e) => {
                      addVal(e, table, "form1", row);
                    }}
                  /> */}
                </td>
              </tr>
            </>
          ))}

          {Object.keys(empForms[table]["form4"]).map((row, index) => (
            <tr key={row + table + index}>
              {row === "Status" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-2 hoverEdit">
                      {row !== "Exit formalities completed" &&
                        row !== "Eligible for rehire" &&
                        row !== "Is the document authentic?" && (
                          <div className="col-6">
                            <button
                              className="editIcon fa fa-pencil"
                              type="button"
                              data-bs-toggle="modal"
                              title="Edit Cell Title"
                              data-bs-target={`#${modelId}`}
                              data-bs-whatever="@mdo"
                              onClick={() => {
                                setSelectedCellPosition(index);
                                setSelectedSubForm(table);
                                setselectedFormNumber("form4");
                                setSelectedCellTitle(row);
                              }}
                            ></button>
                          </div>
                        )}
                      <div className="col-6">
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              color: "error",
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, table, "form4");
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </div>
                  {row === "Upload Document" ? (
                    <>
                      {" "}
                      For
                      <b>
                        {" "}
                        Annexure {annexure}-{index + 1}
                      </b>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              )}

              {row === "Status" ? (
                <ColorCode
                  namefield={row}
                  cls={empForms[table]["form4"][row]}
                  fun={(e) => {
                    addVal(e, table, "form4", row);
                  }}
                  spanNum={2}
                />
              ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                <td key={row + table + index} colSpan={2}>
                  <DatePickerReact
                    inputTagName={row}
                    val={empForms[table]["form4"][row]}
                    isSubtable={true}
                    fun={(name, value) => {
                      addDateVal(table, "form4", row, name, value);
                    }}
                  />
                </td>
              ) : row === "Exit formalities completed" ||
                row === "Eligible for rehire" ||
                row === "Is the document authentic?" ? (
                <td colSpan={2}>
                  <CustomDropDown
                    fun={(e) => addVal(e, table, "form4", row)}
                    currentValue={empForms[table]["form4"][row]}
                    arr={["Yes", "No", "Not Disclosed"]}
                  />
                </td>
              ) : (
                <td key={row + table + index} colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    id={table + row + "ROCUpdate"}
                    defaultValue={empForms[table]["form4"][row]}
                    disabled={row === "Annexure Details" ? true : false}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form4", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Employment Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <button
                type="button"
                className="btn btn-sm btn-outline-dark col-sm-8"
                style={{
                  paddingRight: "4px",
                  paddingLeft: "4px",
                }}
                data-bs-toggle="modal"
                title="Add One More Cell"
                data-bs-target={`#addCellModelEmpVerification${index}`}
                data-bs-whatever="@mdo"
              >
                <b
                  style={{
                    color: "#32cd32",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Add Cell
                </b>
              </button>
            </td>
            <td colSpan={2}></td>
          </tr>
          <AddCellModel
            inputFields={empForms[table]["form4"]}
            onaddCell={(typecellref, position, fieldType) =>
              addCell(
                typecellref,
                position,
                fieldType,
                "Employment Verification",
                table
              )
            }
            uniqueName={`EmpVerification${index}`}
          />
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
    </>
  );
};
export default EmploymentNew;
