import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillWebMediaSearchForm,
  editCellInWebAndMediaDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import "../styles/pageStyles.css";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import DatePickerReact from "./DatePickerReact";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const WebMediaSearch = ({ annexure }) => {
  const modelId = "WebAndMediaCellTileEdit";

  const webmediaForms = useSelector(
    (state) => state.pdfdata.forms["Web and Media Search"]
  );
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addDateVal = (name, value, table) => {
    dispatch(
      fillWebMediaSearchForm(["Web and Media Search", table, name, value])
    );
  };
  const addVal = (e, table) => {
    e.preventDefault();
    let value = e.target.value;

    dispatch(
      fillWebMediaSearchForm([
        "Web and Media Search",
        table,
        e.target.name,
        value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Web and Media Search";
    dispatch(
      editCellInWebAndMediaDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Web and Media Search</u>
      </h3>
      <>
        {Object.keys(webmediaForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading" colSpan={2}>
                <h3>{type}</h3>
              </th>
            </tr>
            {Object.keys(webmediaForms[type]).map((row, index) => (
              <tr>
                <td className="cellTitle col-sm-6">
                  {row === "Company Registration Date" ||
                  row === "Color Code" ? (
                    <>{row}</>
                  ) : (
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(type);
                            setSelectedCellTitle(row);
                            // setselectedFormNumber("form1");
                          }}
                        ></button>
                      </div>
                    </div>
                  )}

                  {row === "Upload Document" ? (
                    <>
                      {" "}
                      For
                      <b>
                        {" "}
                        Annexure {annexure}-{index + 1}
                      </b>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                {row === "Company Registration Date" ? (
                  <DatePickerReact
                    inputTagName={row}
                    val={webmediaForms[type][row]}
                    isSubtable={true}
                    fun={(name, value) => {
                      addDateVal(name, value, type);
                    }}
                  />
                ) : row === "Color Code" ? (
                  <ColorCode
                    cls={webmediaForms[type][row]}
                    namefield={row}
                    fun={(e) => addVal(e, type)}
                    spanNum={1}
                  />
                ) : (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      defaultValue={webmediaForms[type][row]}
                      name={row}
                      onBlur={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Web and Media Search"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                />
                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Web and Media Search"
              namefield={type}
              spanNum={1}
            />
            <tr>
              <td colSpan={2}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default WebMediaSearch;
