import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { setError } from "../error/errorSlice";

export const fetchRecordsInfo = createAsyncThunk(
  "fetchRecordsOnRefresh",
  async (dispatch) => {
    return axios
      .get(`${BASE_URL}form/resource/totalFormTillDate`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        return axios
          .get(`${BASE_URL}form/resource/totalFormToday`, {
            responseType: "json",
            withCredentials: true,
          })
          .then((result) => {
            // console.log(res.data, result.data);
            return [res.data, result.data];
            // return result.data;
          })
          .catch((e) => {
            dispatch(
              setError([
                "An Error occured while fetching record data! ",
                e,
                false,
              ])
            );
          });
      })
      .catch((e) => {
        dispatch(
          setError(["An Error occured while fetching record data! ", e, false])
        );
      });
  }
);

const initialState = {
  numberOfRecordsCreatedToday: -1,
  numberOfRecordsTillDate: -1,
  lastRefreshedAt: "",
  isFirstFetch: true,
  listOfRecordsCreatedToday: [],
  isRefreshLoading: false,
};

export const recordSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    changeRefreshLoadingState: (state, action) => {
      state.isRefreshLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecordsInfo.fulfilled, (state, action) => {
      const [numberOfRecordsTillDate, recordData] = action.payload;
      state.numberOfRecordsTillDate = numberOfRecordsTillDate.count;
      state.numberOfRecordsCreatedToday = recordData.count;
      state.listOfRecordsCreatedToday = recordData.data;
      state.isRefreshLoading = false;
      //var date = new Date();
      const date = new Date();
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;

      state.lastRefreshedAt =
        formattedToday +
        " | " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      state.isFirstFetch = false;
    });
  },
});

export const { changeRefreshLoadingState } = recordSlice.actions;
export default recordSlice.reducer;
