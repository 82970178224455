import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { setError } from "../error/errorSlice";
export const fetchDataByRefNum = createAsyncThunk(
  "fetchDataUsingInfomate360RefNum",
  async ([dispatch, infomate360ReferenceNumber]) => {
    return axios
      .get(`${BASE_URL}form/user/${infomate360ReferenceNumber}`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        dispatch(
          setError([
            "New Record will be created using the entered Infomate360 Reference Number!",
            e,
            false,
          ])
        );
      });
  }
);
export const fetchDataByClientSpecific = createAsyncThunk(
  "fetchDataUsingClientSpecific",
  async ([dispatch, clientSpecific]) => {
    console.log(clientSpecific);
    return axios
      .get(`${BASE_URL}client/specific/${clientSpecific}`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        dispatch(
          setError(["Error Fetching Data Using Client Specific!", e, false])
        );
      });
  }
);
const initialState = {
  "Applicant Name": { value: "", type: "text" },
  "Client Reference No.": { value: "", type: "text" },
  infomate360ReferenceNumber: "",
  "Client Name": { value: "", type: "text" },
  Gender: { value: "", type: "text" },
  "Client Specific": { value: "", type: "text" },
  "Applicant Joining Location": { value: "", type: "text" },
  "Date of Joining": { value: "", type: "date" },
  "Date of Initiation": { value: "", type: "date" },
  "Date of Closure": { value: "", type: "date" },
  "Father Name": { value: "", type: "text" },
  "Date of Birth": { value: "", type: "date" },
  "Color Code": { value: "", type: "text" },
  isFormLoading: false,
  files: {},
  executiveSummary: {},
  forms: {},
  imgStruc: {},
  attachments: {},
  attachmentKeysToDelete: [],
  dummyExecutiveSummary: {},
  dummyForms: {},
};
export const pdfdataSlice = createSlice({
  name: "pdfdata",
  initialState,
  reducers: {
    changeFormLoadingState: (state, action) => {
      state.isFormLoading = action.payload;
    },
    addCellInPersonalInfo(state, action) {
      const newData = Object.entries(state);
      newData.splice(action.payload.position, 0, [
        action.payload.type,
        { value: "", type: action.payload.fieldType },
      ]);
      return Object.fromEntries(newData);
    },
    addCellInSubFormsType1(state, action) {
      const { mainTable, subTable } = action.payload;
      const newData = Object.entries(state.forms[mainTable][subTable]);

      newData.splice(action.payload.position, 0, [action.payload.type, ""]);
      state.forms[mainTable][subTable] = Object.fromEntries(newData);
      state.dummyForms[mainTable][subTable] = Object.fromEntries(newData);
    },
    addCellInSubFormsType2(state, action) {
      const { mainTable, subTable } = action.payload;
      const newData = Object.entries(
        current(state).forms[mainTable][subTable]["form1"]
      );
      newData.splice(action.payload.position, 0, [
        action.payload.type,
        { infoProvided: "", infoVerified: "" },
      ]);
      state.forms[mainTable][subTable]["form1"] = Object.fromEntries(newData);
      state.dummyForms[mainTable][subTable]["form1"] =
        Object.fromEntries(newData);
    },
    editCellInPersonalInfo(state, action) {
      const newData = Object.entries(state);
      const val = newData[action.payload.selectedCellPosition][1];
      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      return Object.fromEntries(newData);
    },
    deleteCellInPersonalInfo(state, action) {
      const newData = Object.entries(state);
      newData.splice(action.payload, 1);
      return Object.fromEntries(newData);
    },
    editCellInExecutiveSummary(state, action) {
      const {
        selectedPreviousCellTitle,
        selectedSubtable,
        selectedCellTitle,
        selectedCellPosition,
      } = action.payload;
      const newData = Object.entries(state.executiveSummary[selectedSubtable]);
      const newData1 = Object.entries(state.forms[selectedSubtable]);
      if (
        current(state.imgStruc)[selectedSubtable] !== undefined &&
        current(state.imgStruc)[selectedSubtable][selectedPreviousCellTitle] !==
          undefined
      ) {
        const position = Object.keys(state.imgStruc[selectedSubtable]).indexOf(
          selectedPreviousCellTitle
        );
        const imgStruc = Object.entries(state.imgStruc[selectedSubtable]);
        const value = imgStruc[position][1];
        imgStruc.splice(position, 1, [selectedCellTitle, value]);
        state.imgStruc[selectedSubtable] = Object.fromEntries(imgStruc);
      }

      const val = newData[selectedCellPosition][1];
      const val1 = newData1[selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        selectedCellTitle,
        val,
      ]);
      newData1.splice(action.payload.selectedCellPosition, 1, [
        selectedCellTitle,
        val1,
      ]);
      state.executiveSummary[selectedSubtable] = Object.fromEntries(newData);
      state.dummyExecutiveSummary[selectedSubtable] =
        Object.fromEntries(newData);
      state.forms[selectedSubtable] = Object.fromEntries(newData1);
      state.dummyForms[selectedSubtable] = Object.fromEntries(newData1);
    },
    editCellInSummayDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm][
          action.payload.selectedFormNumber
        ]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm][
        action.payload.selectedFormNumber
      ] = Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ][action.payload.selectedFormNumber] = Object.fromEntries(newData);
    },
    deleteCellInSummayDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.subform][
          action.payload.form
        ]
      );
      newData.splice(action.payload.cellPosition, 1);
      state.forms[action.payload.mainTable][action.payload.subform][
        action.payload.form
      ] = Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][action.payload.subform][
        action.payload.form
      ] = Object.fromEntries(newData);
    },
    deleteCellInCriminalVerification(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.subform]
      );
      newData.splice(action.payload.cellPosition, 1);
      state.forms[action.payload.mainTable][action.payload.subform] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][action.payload.subform] =
        Object.fromEntries(newData);
    },
    editCellInGapDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInCriminalVerificationDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInDirectorshipDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInFranchiseDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInOfficialNameChangeDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInBankStatementDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInVendorVerificationDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInCompanyCheckDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInCourtCheckDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInDataBaseDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    editCellInWebAndMediaDetailForm(state, action) {
      const newData = Object.entries(
        state.forms[action.payload.mainTable][action.payload.selectedSubForm]
      );

      const val = newData[action.payload.selectedCellPosition][1];

      newData.splice(action.payload.selectedCellPosition, 1, [
        action.payload.type,
        val,
      ]);
      state.forms[action.payload.mainTable][action.payload.selectedSubForm] =
        Object.fromEntries(newData);
      state.dummyForms[action.payload.mainTable][
        action.payload.selectedSubForm
      ] = Object.fromEntries(newData);
    },
    makeStateBlank: (state, action) => {
      const blankState = {
        "Applicant Name": { value: "", type: "text" },
        "Client Reference No.": { value: "", type: "text" },
        infomate360ReferenceNumber: "",
        "Client Name": { value: "", type: "text" },
        Gender: { value: "", type: "text" },
        "Client Specific": { value: "", type: "text" },
        "Applicant Joining Location": { value: "", type: "text" },
        "Date of Joining": { value: "", type: "date" },
        "Date of Initiation": { value: "", type: "date" },
        "Date of Closure": { value: "", type: "date" },
        "Date of Leaving": { value: "", type: "date" },
        "Father Name": { value: "", type: "text" },
        "Date of Birth": { value: "", type: "date" },
        "Color Code": { value: "", type: "text" },
        isFormLoading: false,
        files: {},
        executiveSummary: {},
        forms: {},
        imgStruc: {},
        attachments: {},
        attachmentKeysToDelete: [],
        dummyExecutiveSummary: {},
        dummyForms: {},
      };
      return blankState;
    },
    editAttachmentTitle(state, action) {
      const [mainTable, subTable, currentKey, newTitle, newTitleCheck] =
        action.payload;
      state.imgStruc[mainTable][subTable][currentKey].title = newTitle;
      state.imgStruc[mainTable][subTable][currentKey].isChecked = newTitleCheck;
    },
    changeOverallColorCode: (state, action) => {
      state["Color Code"].value = action.payload;
    },
    changeExecutiveSummary: (state, action) => {
      const [key, value] = action.payload;
      console.log("changeES: State :: ", state[key]);
      if (key === "infomate360ReferenceNumber") {
        console.log("changeES: If", action.payload);
        state[key] = value;
      } else if (state[key]?.value) {
        console.log("changeES: Else If", action.payload);
        state[key].value = value;
      } else {
        console.log("changeES: Else", action.payload);
        if (key !== undefined && key !== "") {
          state[key].value = value;
          console.log("changeES: Else State :: ", state[key]);
        }
      }
    },
    fillStateUsingInformate360ReferenceNumber: (state, action) => {
      state["Applicant Name"] = action.payload["Applicant Name"];
      state.clientSpecific = action.payload.clientName;
    },
    addExecutiveSummaryTypes: (state, action) => {
      state.executiveSummary = action.payload.executiveSummary;
      state.dummyExecutiveSummary = action.payload.executiveSummary;
      state.forms = action.payload.forms;
      state.dummyForms = action.payload.forms;
    },
    fillCreditForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        if (type === "status") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["Credit Check"][table].form1[component][type] = value;
        state.dummyForms["Credit Check"][table].form1[component][type] = value;
      } else {
        state.forms["Credit Check"][table].form2[component] = value;
        state.dummyForms["Credit Check"][table].form2[component] = value;
      }
    },
    fillDrugVerificationForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        if (type === "status") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["Drug Verification"][table].form1[component][type] = value;
        state.dummyForms["Drug Verification"][table].form1[component][type] =
          value;
      } else {
        state.forms["Drug Verification"][table].form2[component] = value;
        state.dummyForms["Drug Verification"][table].form2[component] = value;
      }
    },
    fillAustralianFederalPoliceVerificationForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        if (type === "status") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["National Police Check & 100 Points Check"][table].form1[
          component
        ][type] = value;
        state.dummyForms["National Police Check & 100 Points Check"][
          table
        ].form1[component][type] = value;
      } else {
        state.forms["National Police Check & 100 Points Check"][table].form2[
          component
        ] = value;
        state.dummyForms["National Police Check & 100 Points Check"][
          table
        ].form2[component] = value;
      }
    },
    fillCourtCheckDbForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      if (type === "Court Verification" || type === "Database Verification") {
        state.forms[type][table][key] = value;
        state.dummyForms[type][table][key] = value;
      }
      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },
    fillCompanyCheckForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      if (
        type === "Company Check Verification" ||
        type === "Database Verification"
      ) {
        state.forms[type][table][key] = value;
        state.dummyForms[type][table][key] = value;
      }
      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },
    fillVendorForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      if (type === "Vendor Verification" || type === "Database Verification") {
        state.forms[type][table][key] = value;
        state.dummyForms[type][table][key] = value;
      }
      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },
    fillBankStatementForm: (state, action) => {
      const [key, value, table] = action.payload;
      state.forms["Bank Statement Verification"][table][key] = value;
      state.dummyForms["Bank Statement Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["Bank Statement Verification"][table].status =
          value;
        state.dummyExecutiveSummary["Bank Statement Verification"][
          table
        ].status = value;
      }
    },
    fillOfficialNameChangeForm: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["Official Name Change Verification"][table][key] = value;
      state.dummyForms["Official Name Change Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["Official Name Change Verification"][
          table
        ].status = value;
        state.dummyExecutiveSummary["Official Name Change Verification"][
          table
        ].status = value;
      }
    },
    fillFranchiseVerificationForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      state.forms[type][table][key] = value;
      state.dummyForms[type][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },

    fillDirectorshipForm: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["Directorship Verification"][table][key] = value;
      state.dummyForms["Directorship Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["Directorship Verification"][table].status =
          value;
        state.dummyExecutiveSummary["Directorship Verification"][table].status =
          value;
      }
    },
    fillPoliceForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        state.forms[mainType][table].form1[component][type] = value;
        state.dummyForms[mainType][table].form1[component][type] = value;
      } else {
        if (component === "Color Code") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["Police Verification"][table].form2[component] = value;
        state.dummyForms["Police Verification"][table].form2[component] = value;
      }
    },
    fillWebMediaSearchForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      if (type === "Web and Media Search") {
        state.forms[type][table][key] = value;
        state.dummyForms[type][table][key] = value;
      }
      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },
    fillAddressForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        state.forms["Address Verification"][table].form1[component][type] =
          value;
        state.dummyForms["Address Verification"][table].form1[component][type] =
          value;
      } else {
        if (component === "Color Code") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["Address Verification"][table].form2[component] = value;
        state.dummyForms["Address Verification"][table].form2[component] =
          value;
      }
    },
    fillEduEmpForm: (state, action) => {
      const [mainType, table, form, component, type, value] = action.payload;
      if (form === "form1") {
        if (mainType === "Education Verification") {
          state.forms["Education Verification"][table].form1[component][type] =
            value;
          state.dummyForms["Education Verification"][table].form1[component][
            type
          ] = value;
        } else if (mainType === "Employment Verification") {
          state.forms["Employment Verification"][table].form1[component][type] =
            value;
          state.dummyForms["Employment Verification"][table].form1[component][
            type
          ] = value;
        }
      } else {
        if (component === "Color Code") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        if (mainType === "Education Verification") {
          state.forms["Education Verification"][table].form2[component] = value;
          state.dummyForms["Education Verification"][table].form2[component] =
            value;
        } else if (mainType === "Employment Verification") {
          state.forms["Employment Verification"][table].form2[component] =
            value;
          state.dummyForms["Employment Verification"][table].form2[component] =
            value;
        }
      }
    },
    fillGapForm: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["Gap Verification"][table][key] = value;
      state.dummyForms["Gap Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["Gap Verification"][table].status = value;
        state.dummyExecutiveSummary["Gap Verification"][table].status = value;
      }
    },
    fillUANForm: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["UAN Verification"][table][key] = value;
      state.dummyForms["UAN Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["UAN Verification"][table].status = value;
        state.dummyExecutiveSummary["UAN Verification"][table].status = value;
      }
    },
    fillForm26Form: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["Form26-AS Verification"][table][key] = value;
      state.dummyForms["Form26-AS Verification"][table][key] = value;

      if (key === "Color Code") {
        state.executiveSummary["Form26-AS Verification"][table].status = value;
        state.dummyExecutiveSummary["Form26-AS Verification"][table].status =
          value;
      }
    },
    fillForm16: (state, action) => {
      const [table, key, value] = action.payload;
      state.forms["Form16 Verification"][table][key] = value;
      state.dummyForms["Form16 Verification"][table][key] = value;
    },
    fillReferenceCheckForm: (state, action) => {
      const [mainType, table, form, component, value] = action.payload;
      if (form === "form1") {
        state.forms["Professional Reference Check"][table].form1[component] =
          value;
        state.dummyForms["Professional Reference Check"][table].form1[
          component
        ] = value;
      } else {
        if (component === "Color Code") {
          state.executiveSummary[mainType][table].status = value;
          state.dummyExecutiveSummary[mainType][table].status = value;
        }
        state.forms["Professional Reference Check"][table].form2[component] =
          value;
        state.dummyForms["Professional Reference Check"][table].form2[
          component
        ] = value;
      }
    },
    fillIdForm: (state, action) => {
      const [table, form, component, type, value] = action.payload;
      if (form === "form1") {
        state.forms["Identity Verification"][table].form1[component][type] =
          value;
        state.dummyForms["Identity Verification"][table].form1[component][
          type
        ] = value;
      } else {
        if (component === "Color Code") {
          state.executiveSummary["Identity Verification"][table].status = value;
          state.dummyExecutiveSummary["Identity Verification"][table].status =
            value;
        }
        state.forms["Identity Verification"][table].form2[component] = value;
        state.dummyForms["Identity Verification"][table].form2[component] =
          value;
      }
    },
    fillRightToWorkForm: (state, action) => {
      const [table, form, component, type, value] = action.payload;
      if (form === "form1") {
        state.forms["Right To Work Verification"][table].form1[component][
          type
        ] = value;
        state.dummyForms["Right To Work Verification"][table].form1[component][
          type
        ] = value;
      } else {
        if (component === "Color Code") {
          state.executiveSummary["Right To Work Verification"][table].status =
            value;
          state.dummyExecutiveSummary["Right To Work Verification"][
            table
          ].status = value;
        }
        state.forms["Right To Work Verification"][table].form2[component] =
          value;
        state.dummyForms["Right To Work Verification"][table].form2[component] =
          value;
      }
    },
    fillCriminalDbForm: (state, action) => {
      const [type, table, key, value] = action.payload;
      if (
        type === "Criminal Verification" ||
        type === "Database Verification"
      ) {
        state.forms[type][table][key] = value;
        state.dummyForms[type][table][key] = value;
      }
      if (key === "Color Code") {
        state.executiveSummary[type][table].status = value;
        state.dummyExecutiveSummary[type][table].status = value;
      }
    },
    addSummaryRemarks: (state, action) => {
      const [table, name, value] = action.payload;
      state.executiveSummary[table][name].remarks = value;
      state.dummyExecutiveSummary[table][name].remarks = value;
    },
    addFilesToState: (state, action) => {
      const [name, value] = action.payload;
      state.files[name] = value;
    },
    deleteImages: (state, action) => {
      const [mainTable, subTable, name] = action.payload;
      delete state.imgStruc[mainTable][subTable][name];
      if (state.files[name] !== undefined) {
        delete state.files[name];
      }
    },
    deleteFilesFromState: (state, action) => {
      state.files = {};
    },
    deleteImageFromDb: (state, action) => {
      if (state["attachmentKeysToDelete"] === undefined)
        state["attachmentKeysToDelete"] = [];
      state["attachmentKeysToDelete"].push(action.payload);
    },
    removeImagesUsingTitle: (state, action) => {
      const { mainTable, subTable, key } = action.payload;
      console.log(mainTable, subTable, key);
      if (state.attachments[key] !== undefined) {
        delete state.attachments[key];
      }
      //following lines can cause issue, test properly
      if (state.imgStruc[mainTable][subTable][key] !== undefined) {
        delete state.imgStruc[mainTable][subTable][key];
      }

      if (state.files[key] !== undefined) {
        delete state.files[key];
      }
    },
    removeImageFromAttachments: (state, action) => {
      const { mainTable, subTable, key, index } = action.payload;
      if (state.attachments[key] !== undefined) {
        state.attachments[key].splice(index, 1);
      }
      //following lines can cause issue, test properly
      if (
        state.attachments[key].length === 0 &&
        state.imgStruc[mainTable][subTable][key] !== undefined
      ) {
        delete state.imgStruc[mainTable][subTable][key];
      }

      if (state.files[key] !== undefined) {
        let temp = Object.entries(state.files[key]).splice(index, 1);
        state.files[key] = Object.fromEntries(temp);
      }
      if (
        state.files[key] !== undefined &&
        Object.keys(state.files[key]).length === 0
      ) {
        delete state.files[key];
      }

      //delete state.files[key];
    },
    addImagePropsTitle: (state, action) => {
      const [currentKeyString, mainTable, subTable, key, title, annexure] =
        action.payload;
      if (state.imgStruc[mainTable] === undefined)
        state.imgStruc[mainTable] = {};
      if (state.imgStruc[mainTable][subTable] === undefined)
        state.imgStruc[mainTable][subTable] = {};
      if (state.imgStruc[mainTable][subTable][key] === undefined) {
        if (currentKeyString === "") {
          // state.imgStruc[mainTable][subTable][key] = {};
          const newData = Object.entries(
            current(state).imgStruc[mainTable][subTable]
          );
          newData.splice(0, 0, [key, {}]);
          state.imgStruc[mainTable][subTable] = Object.fromEntries(newData);
        } else {
          const position = Object.keys(
            current(state).imgStruc[mainTable][subTable]
          ).indexOf(currentKeyString);
          const newData = Object.entries(
            current(state).imgStruc[mainTable][subTable]
          );
          newData.splice(position + 1, 0, [key, {}]);
          state.imgStruc[mainTable][subTable] = Object.fromEntries(newData);
        }
      }
      //state.imgStruc[mainTable][subTable][key] = {};
      state.imgStruc[mainTable][subTable][key]["title"] = title;
      state.imgStruc[mainTable][subTable][key]["annexure"] = annexure;
    },
    addImagePropsIsChecked: (state, action) => {
      const [currentKeyString, mainTable, subTable, key, isChecked, annexure] =
        action.payload;
      if (state.imgStruc[mainTable] === undefined)
        state.imgStruc[mainTable] = {};
      if (state.imgStruc[mainTable][subTable] === undefined)
        state.imgStruc[mainTable][subTable] = {};
      if (state.imgStruc[mainTable][subTable][key] === undefined) {
        if (currentKeyString === "") {
          state.imgStruc[mainTable][subTable][key] = {};
        } else {
          const position = Object.keys(
            current(state).imgStruc[mainTable][subTable]
          ).indexOf(currentKeyString);
          const newData = Object.entries(
            current(state).imgStruc[mainTable][subTable]
          );
          newData.splice(position + 1, 0, [key, {}]);
          state.imgStruc[mainTable][subTable] = Object.fromEntries(newData);
        }
      }
      //state.imgStruc[mainTable][subTable][key] = {};
      state.imgStruc[mainTable][subTable][key]["isChecked"] = isChecked;
      state.imgStruc[mainTable][subTable][key]["annexure"] = annexure;
    },
    deleteSubTable: (state, action) => {
      const [mainTable, subTable] = action.payload;
      delete state.forms[mainTable][subTable];
      delete state.dummyForms[mainTable][subTable];
      delete state.executiveSummary[mainTable][subTable];
      delete state.dummyExecutiveSummary[mainTable][subTable];
      if (
        state.imgStruc[mainTable] !== undefined &&
        state.imgStruc[mainTable][subTable] !== undefined
      ) {
        Object.keys(state.imgStruc[mainTable][subTable]).forEach((key) => {
          if (state.attachments[key] !== undefined) {
            state.attachments[key].forEach((k) => {
              state.attachmentKeysToDelete.push(k);
            });
            delete state.attachments[key];
          }
          if (state.files[key] !== undefined) {
            delete state.files[key];
          }
        });
        delete state.imgStruc[mainTable][subTable];
      }
    },
    makeFileStrucEmpty: (state, action) => {
      state.files = {};
    },
    createSubTable: (state, action) => {
      const [mainTable, subTable, selectedPositionAddSubTable] = action.payload;
      const execData = Object.entries(state.executiveSummary[mainTable]);
      execData.splice(selectedPositionAddSubTable, 0, [
        subTable,
        { remarks: "", status: "" },
      ]);

      state.executiveSummary[mainTable] = Object.fromEntries(execData);
      state.dummyExecutiveSummary[mainTable] = Object.fromEntries(execData);

      let subform = {};
      switch (mainTable) {
        case "Education Verification":
          subform = {
            form1: {
              "Name of the Applicant": {
                infoProvided: "",
                infoVerified: "",
              },
              "Qualification Obtained": {
                infoProvided: "",
                infoVerified: "",
              },
              "Year Of Completion": {
                infoProvided: "",
                infoVerified: "",
              },
              "Tenure Of Study": {
                infoProvided: "",
                infoVerified: "",
              },
              "Register No / Seat No / Hall Ticket Number / Roll No/ Enrollment Number / Student ID":
                {
                  infoProvided: "",
                  infoVerified: "",
                },
              "Class Obtained": {
                infoProvided: "",
                infoVerified: "",
              },
              "Name of the University / College / Institute / Board and Location":
                {
                  infoProvided: "",
                  infoVerified: "",
                },
            },
            form2: {
              "Verified By": "",
              "Mode of Verification": "",
              "Over all Remarks": "",
              "Color Code": "",
            },
          };
          break;
        case "Employment Verification":
          subform = {
            form1: {
              "Name of Origination (and branch worked in)": {
                infoProvided: "",
                infoVerified: "",
              },
              Designation: {
                infoProvided: "",
                infoVerified: "",
              },
              "Period of Employment": {
                infoProvided: "",
                infoVerified: "",
              },
              "Employee ID": {
                infoProvided: "",
                infoVerified: "",
              },
              "Reporting Manager (Name and Designation)": {
                infoProvided: "",
                infoVerified: "",
              },
              "Salary (Per Month/Per Annum)": {
                infoProvided: "",
                infoVerified: "",
              },
              "Reasons for leaving": {
                infoProvided: "",
                infoVerified: "",
              },
              "Eligibility for Rehire": {
                infoProvided: "",
                infoVerified: "",
              },
              "Exit formalities": {
                infoProvided: "",
                infoVerified: "",
              },
            },
            form2: {
              "Verified By": "",
              "Mode of Verification": "",
              "ROC Registration": "",
              "Registration Number": "",
              CIN: "",
              UAN: "",
              "Over all Remarks": "",
              "Color Code": "",
            },
          };
          break;
        case "Gap Verification":
          subform = {
            "Name of the Applicant": "",
            "Gap between Education to Education": "",
            "Gap between Education to Employment": "",
            "Gap between Employment to Employment": "",
            "Over all Remarks": "",
            "Color Code": "",
          };
          break;
        case "Identity Verification":
          subform = {
            form1: {
              "Type of Identity Check": {
                infoProvided: "",
                infoVerified: "",
              },
              "Mentioned Name": {
                infoProvided: "",
                infoVerified: "",
              },
              "Date of Birth": {
                infoProvided: "",
                infoVerified: "",
              },
              "ID Number": {
                infoProvided: "",
                infoVerified: "",
              },
            },
            form2: {
              "Mode of Verification": "",
              "Color Code": "",
              Remarks: "",
            },
          };
          break;
        case "Right To Work Verification":
          subform = {
            form1: {
              "Type of RTW Identity Check": {
                infoProvided: "",
                infoVerified: "",
              },
              "Mentioned Name": {
                infoProvided: "",
                infoVerified: "",
              },
              "Date of Birth": {
                infoProvided: "",
                infoVerified: "",
              },
              "Pan Card Number": {
                infoProvided: "",
                infoVerified: "",
              },
            },
            form2: {
              "Mode of Verification": "",
              "Color Code": "",
              Remarks: "",
            },
          };
          break;
        case "Criminal Verification":
          subform = {
            "Name of the Applicant": "",
            "Father Name": "",
            Address: "",
            "Mode of Verification": `${
              subTable.toLowerCase().replace(/\s/g, "") ===
              "courtcheck(throughadvocate)"
                ? "Advocate Physically Visit the Court"
                : subTable.toLowerCase().replace(/\s/g, "") ===
                  "courtcheck(throughdatabase)"
                ? "Online Verification from Manupatra Database"
                : ""
            }`,
            "Verified By": `${
              subTable.toLowerCase().replace(/\s/g, "") ===
              "courtcheck(throughadvocate)"
                ? "Mr. Narasimha Rao Talluri (Advocate)"
                : ""
            }`,
            "Color Code": "",
            "Additional Comments": `${
              subTable.toLowerCase().replace(/\s/g, "") ===
              "courtcheck(throughadvocate)"
                ? "No Criminal Record found since Seven years"
                : subTable.toLowerCase().replace(/\s/g, "") ===
                  "courtcheck(throughdatabase)"
                ? "No Criminal Record found since Seven years"
                : ""
            }`,
          };
          break;
        case "Court Verification":
          subform = {
            "Name of the Applicant": "",
            "Father Name": "",
            Address: "",
            "Mode of Verification": "",
            "Sex Offenders and Global Sanctions Verification Status": "",
            "Color Code": "",
            "Additional Comments": "",
          };
          break;
        case "Database Verification":
          subform = {
            "Name of the Applicant": "",
            "Father Name": "",
            "Date of Birth": "",
            "Mode of Verification": `${
              subTable === "Global Specific Database Check"
                ? "Online Verification from World Check Database"
                : subTable === "Indian Specific Database Check"
                ? "Online Verification from Indian Check Database"
                : subTable === "OFAC Specific Database Check"
                ? "Online Verification from OFAC Check Database"
                : ""
            }`,
            "Database Verification Status": "No hits found",
            "Color Code": "",
            Remarks: "No Records Found",
          };
          break;
        case "Address Verification":
          subform = {
            form1: {
              "Complete Street Address": {
                infoProvided: "",
                infoVerified: "",
              },
              "City / Town": {
                infoProvided: "",
                infoVerified: "",
              },
              "Pin Code": {
                infoProvided: "",
                infoVerified: "",
              },
              State: {
                infoProvided: "",
                infoVerified: "",
              },
              "Type of Residence": {
                infoProvided: "",
                infoVerified: "",
              },
              "Period of Stay": {
                infoProvided: "",
                infoVerified: "",
              },
            },
            form2: {
              "Additional Comments (if any)": "",
              "Verified By": "",
              "Mode of Verification": "",
              Remarks: "",
              "Color Code": "",
              "Additional Comments": "",
            },
          };
          break;
        case "UAN Verification":
          subform = {
            "Over all Remarks": "",
            "Color Code": "",
          };
          break;
        case "Form26-AS Verification":
          subform = {
            "Over all Remarks": "",
          };
          break;
        case "Form16 Verification":
          subform = {
            "Over all Remarks": "",
          };
          break;
        case "Credit Check":
          subform = {
            form1: {
              "Credit Check": {
                briefDetails: "",
                status: "",
              },
            },
            form2: {
              Remarks: "",
            },
          };
          break;
        case "Drug Verification":
          subform = {
            form1: {
              "Drug of Abuse": {
                briefDetails: "",
                status: "",
              },
            },
            form2: {
              Comments: "",
              Remarks: "",
            },
          };
          break;
        case "National Police Check & 100 Points Check":
          subform = {
            form1: {
              "Australia (AFP)": {
                briefDetails: "",
                status: "",
              },
              "100 Points Check": {
                briefDetails: "",
                status: "",
              },
            },
          };
          break;
        case "Police Verification":
          subform = {
            form1: {
              "Name of the Applicant": {
                infoProvided: "",
                infoVerified: "Verified",
              },
              "Father Name": {
                infoProvided: "",
                infoVerified: "Verified",
              },
              "Date of Birth": {
                infoProvided: "",
                infoVerified: "Verified",
              },
              Address: {
                infoProvided: "",
                infoVerified: "Verified",
              },
              "Police Station": {
                infoProvided: "",
                infoVerified: "Verified",
              },
            },
            form2: {
              "Verified By": "Mr. Narasimha Rao Talluri (Advocate)",
              "Mode of Verification": "Advocate Physically Visit the Court",
              "Color Code": "",
              "Additional Comments":
                "Verified and No Records Found Since Seven Years",
            },
          };
          break;
        case "Web and Media Search":
          subform = {
            "Name of the Company": "",
            "Company Address": "",
            "Company Registered Address": "",
            "Company Registration Date": "",
            "Company Status (Active/Inactive)": "",
            "CIN Number": "",
            "Directors Name": "",
            "Mode of Verification": "",
            Remarks: "",
            "Color Code": "",
            "Additional Comments": "",
          };
          break;
        case "Company Check Verification":
          subform = {
            "Name of the company": "",
            "Company Address": "",
            "Company Registered Address": "",
            "Company Registration Date": "",
            "Company Status (Active/Inactive)": "",
            "CIN Number": "",
            "Director's Name": "",
            "Company Locality": "",
            "Company Volume": "",
            "Company Area (sq feet)": "",
            "Mode of Verification": "",
            Remarks: "",
            "Color Code": "",
            "Additional Comments": "",
          };
          break;
        case "Vendor Verification":
          subform = {
            "Name of the company": "",
            "Company Address": "",
            "Company ROC Details": "",
            "Company Owner Name": "",
            "Mode of verification": "",
            Remarks: "",
            "Color Code": "",
          };
          break;
        case "Bank Statement Verification":
          subform = {
            "Name of the Applicant": "",
            "Company Name": "",
            "Company Location": "",
            "Bank Name and Branch": "",
            "Statement Period": "",
            "Mode of Verification": "",
            Remarks: "",
            "Color Code": "",
          };
          break;
        case "Official Name Change Verification":
          subform = {
            "Name of the Applicant (Old Name)": "",
            "Name of the Applicant (New Name)": "",
            Address: "",
            "Name Change Certificate Issued date": "",
            "Certificate Number": "",
            "Mode of Verification": "",
            Remarks: "",
            "Color Code": "",
          };
          break;
        case "Franchise Verification":
          subform = {
            "Name of the Applicant": "",
            "Applicant Address": "",
            "Father Name": "",
            "Date of Birth": "",
            "Applicant Education Qualification": "",
            "References Contacts": "",
            "Global and India Database Checks": "",
            "Credit Check": "",
            "Directorship Check": "",
            Remarks: "",
            "Color Code": "",
          };
          break;
        case "Directorship Verification":
          subform = {
            "Name of the Director": "",
            "Company Name": "",
            "Company Location": "",
            "TAN and PAN Number": "",
            "CIN Number": "",
            "Mode of Verification": "",
            Remarks: "",
            "Color Code": "",
          };
          break;
        case "Professional Reference Check":
          subform = {
            form1: {
              "Name and designation of the Reference": "",
              "Reference employed at (name and address of the employer)": "",
              "Reference's contact details": "",
              "Official Email ID": "",
            },
            form2: {
              "How long have you known the candidate?": "",
              "What is the nature of your association- personal/professional? (If professional)What was the working relationship you had with this person?":
                "",
              "How would you rate the individual on interpersonal skills such as - integrity, communication skills, industry standing, client management, team management, skill building and people management? From a scale of 1 to 10?":
                "",
              "Did the candidate receive any promotions while with your company?":
                "",
              "Did Candidate miss a lot of work? Was she/he frequently late? Were there any issues you are aware of that impacted her/his job performance?":
                "",
              "Did she/he get along well with Superiors and co-workers?": "",
              "Did Candidate prefer to work on a team or independently?": "",
              "What were Candidate strengths and weaknesses as an employee?":
                "",
              "How did candidate handle conflict? How about pressure? Stress?":
                "",
              "Would you rehire/ recommend that this candidate be rehired in your company again?":
                "",
              "How would you rate the candidate's overall performance from a scale of 1 to 10? (Technical Skills & Social Conduct)":
                "",
              "Any other information that you want us to know about the candidate?":
                "",
              Remarks: "",
              "Color Code": "",
            },
          };
          break;
        default:
          console.log("default case");
      }
      const newData = Object.entries(state.forms[mainTable]);

      newData.splice(selectedPositionAddSubTable, 0, [subTable, subform]);

      state.forms[mainTable] = Object.fromEntries(newData);
      state.dummyForms[mainTable] = Object.fromEntries(newData);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchDataByRefNum.fulfilled, (state, action) => {
      // Add user to the state array
      const newData = action.payload;
      console.log("newData: Pdf OLD :: ", newData);
      if (newData !== undefined) {
        delete newData["updatedDate"];
        delete newData["createdDate"];
        newData["dummyForms"] = newData["forms"];
        newData["dummyExecutiveSummary"] = newData["executiveSummary"];
        if (newData["Gender"]) {
          return newData;
        }
      }

      console.log("newData", newData);
      //return newData;

      // Object.keys(state).forEach((key) => {
      //   state[key] = action.payload[key];
      // });
    });
    builder.addCase(fetchDataByClientSpecific.fulfilled, (state, action) => {
      const initialExecSum = current(state);
      state.executiveSummary = {};
      state.forms = {};
      Object.keys(action.payload).forEach((mainTable) => {
        if (
          !(
            action.payload[mainTable].length === 1 &&
            action.payload[mainTable][0] === ""
          )
        ) {
          switch (mainTable) {
            case "EducationVerification":
              if (
                state.dummyExecutiveSummary["Education Verification"] ===
                undefined
              )
                state.executiveSummary["Education Verification"] = {};
              else {
                state.executiveSummary["Education Verification"] =
                  state.dummyExecutiveSummary["Education Verification"];
              }
              if (state.dummyForms["Education Verification"] === undefined)
                state.forms["Education Verification"] = {};
              else {
                state.forms["Education Verification"] =
                  state.dummyForms["Education Verification"];
              }
              break;
            case "EmploymentVerification":
              if (
                state.dummyExecutiveSummary["Employment Verification"] ===
                undefined
              )
                state.executiveSummary["Employment Verification"] = {};
              else {
                state.executiveSummary["Employment Verification"] =
                  state.dummyExecutiveSummary["Employment Verification"];
              }
              if (state.dummyForms["Employment Verification"] === undefined)
                state.forms["Employment Verification"] = {};
              else {
                state.forms["Employment Verification"] =
                  state.dummyForms["Employment Verification"];
              }
              break;
            case "GapVerification":
              if (state.dummyExecutiveSummary["Gap Verification"] === undefined)
                state.executiveSummary["Gap Verification"] = {};
              else {
                state.executiveSummary["Gap Verification"] =
                  state.dummyExecutiveSummary["Gap Verification"];
              }
              if (state.dummyForms["Gap Verification"] === undefined)
                state.forms["Gap Verification"] = {};
              else {
                state.forms["Gap Verification"] =
                  state.dummyForms["Gap Verification"];
              }
              break;
            case "IdentityVerification":
              if (
                state.dummyExecutiveSummary["Identity Verification"] ===
                undefined
              )
                state.executiveSummary["Identity Verification"] = {};
              else {
                state.executiveSummary["Identity Verification"] =
                  state.dummyExecutiveSummary["Identity Verification"];
              }
              if (state.dummyForms["Identity Verification"] === undefined)
                state.forms["Identity Verification"] = {};
              else {
                state.forms["Identity Verification"] =
                  state.dummyForms["Identity Verification"];
              }
              break;
            case "RightToWorkVerification":
              if (
                state.dummyExecutiveSummary["Right To Work Verification"] ===
                undefined
              )
                state.executiveSummary["Right To Work Verification"] = {};
              else {
                state.executiveSummary["Right To Work Verification"] =
                  state.dummyExecutiveSummary["Right To Work Verification"];
              }
              if (state.dummyForms["Right To Work Verification"] === undefined)
                state.forms["Right To Work Verification"] = {};
              else {
                state.forms["Right To Work Verification"] =
                  state.dummyForms["Right To Work Verification"];
              }
              break;
            case "CriminalVerification":
              if (
                state.dummyExecutiveSummary["Criminal Verification"] ===
                undefined
              )
                state.executiveSummary["Criminal Verification"] = {};
              else {
                state.executiveSummary["Criminal Verification"] =
                  state.dummyExecutiveSummary["Criminal Verification"];
              }
              if (state.dummyForms["Criminal Verification"] === undefined)
                state.forms["Criminal Verification"] = {};
              else {
                state.forms["Criminal Verification"] =
                  state.dummyForms["Criminal Verification"];
              }
              break;
            case "CourtVerification":
              if (
                state.dummyExecutiveSummary["Court Verification"] === undefined
              )
                state.executiveSummary["Court Verification"] = {};
              else {
                state.executiveSummary["Court Verification"] =
                  state.dummyExecutiveSummary["Court Verification"];
              }
              if (state.dummyForms["Court Verification"] === undefined)
                state.forms["Court Verification"] = {};
              else {
                state.forms["Court Verification"] =
                  state.dummyForms["Court Verification"];
              }
              break;
            case "DatabaseVerification":
              if (
                state.dummyExecutiveSummary["Database Verification"] ===
                undefined
              )
                state.executiveSummary["Database Verification"] = {};
              else {
                state.executiveSummary["Database Verification"] =
                  state.dummyExecutiveSummary["Database Verification"];
              }
              if (state.dummyForms["Database Verification"] === undefined)
                state.forms["Database Verification"] = {};
              else {
                state.forms["Database Verification"] =
                  state.dummyForms["Database Verification"];
              }
              break;
            case "AddressVerification":
              if (
                state.dummyExecutiveSummary["Address Verification"] ===
                undefined
              )
                state.executiveSummary["Address Verification"] = {};
              else {
                state.executiveSummary["Address Verification"] =
                  state.dummyExecutiveSummary["Address Verification"];
              }
              if (state.dummyForms["Address Verification"] === undefined)
                state.forms["Address Verification"] = {};
              else {
                state.forms["Address Verification"] =
                  state.dummyForms["Address Verification"];
              }
              break;
            case "ReferenceCheck":
              if (
                state.dummyExecutiveSummary["Professional Reference Check"] ===
                undefined
              )
                state.executiveSummary["Professional Reference Check"] = {};
              else {
                state.executiveSummary["Professional Reference Check"] =
                  state.dummyExecutiveSummary["Professional Reference Check"];
              }
              if (
                state.dummyForms["Professional Reference Check"] === undefined
              )
                state.forms["Professional Reference Check"] = {};
              else {
                state.forms["Professional Reference Check"] =
                  state.dummyForms["Professional Reference Check"];
              }
              break;
            case "UANVerification":
              if (state.dummyExecutiveSummary["UAN Verification"] === undefined)
                state.executiveSummary["UAN Verification"] = {};
              else {
                state.executiveSummary["UAN Verification"] =
                  state.dummyExecutiveSummary["UAN Verification"];
              }
              if (state.dummyForms["UAN Verification"] === undefined)
                state.forms["UAN Verification"] = {};
              else {
                state.forms["UAN Verification"] =
                  state.dummyForms["UAN Verification"];
              }
              break;

            case "Form26-AS Verification":
              if (
                state.dummyExecutiveSummary["Form26-AS Verification"] ===
                undefined
              )
                state.executiveSummary["Form26-AS Verification"] = {};
              else {
                state.executiveSummary["Form26-AS Verification"] =
                  state.dummyExecutiveSummary["Form26-AS Verification"];
              }
              if (state.dummyForms["Form26-AS Verification"] === undefined)
                state.forms["Form26-AS Verification"] = {};
              else {
                state.forms["Form26-AS Verification"] =
                  state.dummyForms["Form26-AS Verification"];
              }
              break;

            case "Form16Verification":
              if (
                state.dummyExecutiveSummary["Form16 Verification"] === undefined
              )
                state.executiveSummary["Form16 Verification"] = {};
              else {
                state.executiveSummary["Form16 Verification"] =
                  state.dummyExecutiveSummary["Form16 Verification"];
              }
              if (state.dummyForms["Form16 Verification"] === undefined)
                state.forms["Form16 Verification"] = {};
              else {
                state.forms["Form16 Verification"] =
                  state.dummyForms["Form16 Verification"];
              }
              break;
            case "CreditVerification":
              if (state.dummyExecutiveSummary["Credit Check"] === undefined)
                state.executiveSummary["Credit Check"] = {};
              else {
                state.executiveSummary["Credit Check"] =
                  state.dummyExecutiveSummary["Credit Check"];
              }
              if (state.dummyForms["Credit Check"] === undefined)
                state.forms["Credit Check"] = {};
              else
                state.forms["Credit Check"] = state.dummyForms["Credit Check"];
              break;
            case "DrugVerification":
              if (
                state.dummyExecutiveSummary["Drug Verification"] === undefined
              )
                state.executiveSummary["Drug Verification"] = {};
              else {
                state.executiveSummary["Drug Verification"] =
                  state.dummyExecutiveSummary["Drug Verification"];
              }
              if (state.dummyForms["Drug Verification"] === undefined)
                state.forms["Drug Verification"] = {};
              else {
                state.forms["Drug Verification"] =
                  state.dummyForms["Drug Verification"];
              }
              break;
            case "AustralianFederalPoliceVerification":
              if (
                state.dummyExecutiveSummary[
                  "National Police Check & 100 Points Check"
                ] === undefined
              )
                state.executiveSummary[
                  "National Police Check & 100 Points Check"
                ] = {};
              else {
                state.executiveSummary[
                  "National Police Check & 100 Points Check"
                ] =
                  state.dummyExecutiveSummary[
                    "National Police Check & 100 Points Check"
                  ];
              }
              if (
                state.dummyForms["National Police Check & 100 Points Check"] ===
                undefined
              )
                state.forms["National Police Check & 100 Points Check"] = {};
              else {
                state.forms["National Police Check & 100 Points Check"] =
                  state.dummyForms["National Police Check & 100 Points Check"];
              }
              break;
            case "PoliceVerification":
              if (
                state.dummyExecutiveSummary["Police Verification"] === undefined
              )
                state.executiveSummary["Police Verification"] = {};
              else
                state.executiveSummary["Police Verification"] =
                  state.dummyExecutiveSummary["Police Verification"];
              if (state.dummyForms["Police Verification"] === undefined)
                state.forms["Police Verification"] = {};
              else
                state.forms["Police Verification"] =
                  state.dummyForms["Police Verification"];
              break;
            case "WebAndMediaSearch":
              if (
                state.dummyExecutiveSummary["Web and Media Search"] ===
                undefined
              )
                state.executiveSummary["Web and Media Search"] = {};
              else
                state.executiveSummary["Web and Media Search"] =
                  state.dummyExecutiveSummary["Web and Media Search"];
              if (state.dummyForms["Web and Media Search"] === undefined)
                state.forms["Web and Media Search"] = {};
              else
                state.forms["Web and Media Search"] =
                  state.dummyForms["Web and Media Search"];
              break;
            case "CompanyCheckVerification":
              if (
                state.dummyExecutiveSummary["Company Check Verification"] ===
                undefined
              )
                state.executiveSummary["Company Check Verification"] = {};
              else
                state.executiveSummary["Company Check Verification"] =
                  state.dummyExecutiveSummary["Company Check Verification"];
              if (state.dummyForms["Company Check Verification"] === undefined)
                state.forms["Company Check Verification"] = {};
              else
                state.forms["Company Check Verification"] =
                  state.dummyForms["Company Check Verification"];
              break;
            case "VendorVerification":
              if (
                state.dummyExecutiveSummary["Vendor Verification"] === undefined
              )
                state.executiveSummary["Vendor Verification"] = {};
              else
                state.executiveSummary["Vendor Verification"] =
                  state.dummyExecutiveSummary["Vendor Verification"];
              if (state.dummyForms["Vendor Verification"] === undefined)
                state.forms["Vendor Verification"] = {};
              else
                state.forms["Vendor Verification"] =
                  state.dummyForms["Vendor Verification"];
              break;
            case "BankStatementVerification":
              if (
                state.dummyExecutiveSummary["Bank Statement Verification"] ===
                undefined
              )
                state.executiveSummary["Bank Statement Verification"] = {};
              else
                state.executiveSummary["Bank Statement Verification"] =
                  state.dummyExecutiveSummary["Bank Statement Verification"];
              if (state.dummyForms["Bank Statement Verification"] === undefined)
                state.forms["Bank Statement Verification"] = {};
              else
                state.forms["Bank Statement Verification"] =
                  state.dummyForms["Bank Statement Verification"];
              break;
            case "OfficialNameChangeVerification":
              if (
                state.dummyExecutiveSummary[
                  "Official Name Change Verification"
                ] === undefined
              )
                state.executiveSummary["Official Name Change Verification"] =
                  {};
              else
                state.executiveSummary["Official Name Change Verification"] =
                  state.dummyExecutiveSummary[
                    "Official Name Change Verification"
                  ];
              if (
                state.dummyForms["Official Name Change Verification"] ===
                undefined
              )
                state.forms["Official Name Change Verification"] = {};
              else
                state.forms["Official Name Change Verification"] =
                  state.dummyForms["Official Name Change Verification"];
              break;
            case "FranchiseVerification":
              if (
                state.dummyExecutiveSummary["Franchise Verification"] ===
                undefined
              )
                state.executiveSummary["Franchise Verification"] = {};
              else
                state.executiveSummary["Franchise Verification"] =
                  state.dummyExecutiveSummary["Franchise Verification"];
              if (state.dummyForms["Franchise Verification"] === undefined)
                state.forms["Franchise Verification"] = {};
              else
                state.forms["Franchise Verification"] =
                  state.dummyForms["Franchise Verification"];
              break;
            case "DirectorshipVerification":
              if (
                state.dummyExecutiveSummary["Directorship Verification"] ===
                undefined
              )
                state.executiveSummary["Directorship Verification"] = {};
              else
                state.executiveSummary["Directorship Verification"] =
                  state.dummyExecutiveSummary["Directorship Verification"];
              if (state.dummyForms["Directorship Verification"] === undefined)
                state.forms["Directorship Verification"] = {};
              else
                state.forms["Directorship Verification"] =
                  state.dummyForms["Directorship Verification"];
              break;
            default:
              console.log("Default Case");
          }
          // switch (mainTable) {
          //   case "EducationVerification":
          //     if (
          //       initialExecSum.executiveSummary["Education Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Education Verification"] = {};
          //     else
          //       state.executiveSummary["Education Verification"] =
          //         initialExecSum.executiveSummary["Education Verification"];
          //     if (initialExecSum.forms["Education Verification"] === undefined)
          //       state.forms["Education Verification"] = {};
          //     else
          //       state.forms["Education Verification"] =
          //         initialExecSum.forms["Education Verification"];
          //     break;
          //   case "EmploymentVerification":
          //     if (
          //       initialExecSum.executiveSummary["Employment Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Employment Verification"] = {};
          //     else
          //       state.executiveSummary["Employment Verification"] =
          //         initialExecSum.executiveSummary["Employment Verification"];
          //     if (initialExecSum.forms["Employment Verification"] === undefined)
          //       state.forms["Employment Verification"] = {};
          //     else
          //       state.forms["Employment Verification"] =
          //         initialExecSum.forms["Employment Verification"];
          //     break;
          //   case "GapVerification":
          //     if (
          //       initialExecSum.executiveSummary["Gap Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Gap Verification"] = {};
          //     else
          //       state.executiveSummary["Gap Verification"] =
          //         initialExecSum.executiveSummary["Gap Verification"];
          //     if (initialExecSum.forms["Gap Verification"] === undefined)
          //       state.forms["Gap Verification"] = {};
          //     else
          //       state.forms["Gap Verification"] =
          //         initialExecSum.forms["Gap Verification"];
          //     break;
          //   case "IdentityVerification":
          //     if (
          //       initialExecSum.executiveSummary["Identity Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Identity Verification"] = {};
          //     else
          //       state.executiveSummary["Identity Verification"] =
          //         initialExecSum.executiveSummary["Identity Verification"];
          //     if (initialExecSum.forms["Identity Verification"] === undefined)
          //       state.forms["Identity Verification"] = {};
          //     else
          //       state.forms["Identity Verification"] =
          //         initialExecSum.forms["Identity Verification"];
          //     break;
          //   case "RightToWorkVerification":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "Right To Work Verification"
          //       ] === undefined
          //     )
          //       state.executiveSummary["Right To Work Verification"] = {};
          //     else
          //       state.executiveSummary["Right To Work Verification"] =
          //         initialExecSum.executiveSummary["Right To Work Verification"];
          //     if (
          //       initialExecSum.forms["Right To Work Verification"] === undefined
          //     )
          //       state.forms["Right To Work Verification"] = {};
          //     else
          //       state.forms["Right To Work Verification"] =
          //         initialExecSum.forms["Right To Work Verification"];
          //     break;
          //   case "CriminalVerification":
          //     if (
          //       initialExecSum.executiveSummary["Criminal Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Criminal Verification"] = {};
          //     else
          //       state.executiveSummary["Criminal Verification"] =
          //         initialExecSum.executiveSummary["Criminal Verification"];
          //     if (initialExecSum.forms["Criminal Verification"] === undefined)
          //       state.forms["Criminal Verification"] = {};
          //     else
          //       state.forms["Criminal Verification"] =
          //         initialExecSum.forms["Criminal Verification"];
          //     break;
          //   case "CourtVerification":
          //     if (
          //       initialExecSum.executiveSummary["Court Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Court Verification"] = {};
          //     else
          //       state.executiveSummary["Court Verification"] =
          //         initialExecSum.executiveSummary["Court Verification"];
          //     if (initialExecSum.forms["Court Verification"] === undefined)
          //       state.forms["Court Verification"] = {};
          //     else
          //       state.forms["Court Verification"] =
          //         initialExecSum.forms["Court Verification"];
          //     break;
          //   case "DatabaseVerification":
          //     if (
          //       initialExecSum.executiveSummary["Database Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Database Verification"] = {};
          //     else
          //       state.executiveSummary["Database Verification"] =
          //         initialExecSum.executiveSummary["Database Verification"];
          //     if (initialExecSum.forms["Database Verification"] === undefined)
          //       state.forms["Database Verification"] = {};
          //     else
          //       state.forms["Database Verification"] =
          //         initialExecSum.forms["Database Verification"];
          //     break;
          //   case "AddressVerification":
          //     if (
          //       initialExecSum.executiveSummary["Address Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Address Verification"] = {};
          //     else
          //       state.executiveSummary["Address Verification"] =
          //         initialExecSum.executiveSummary["Address Verification"];
          //     if (initialExecSum.forms["Address Verification"] === undefined)
          //       state.forms["Address Verification"] = {};
          //     else
          //       state.forms["Address Verification"] =
          //         initialExecSum.forms["Address Verification"];
          //     break;
          //   case "ReferenceCheck":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "Professional Reference Check"
          //       ] === undefined
          //     )
          //       state.executiveSummary["Professional Reference Check"] = {};
          //     else
          //       state.executiveSummary["Professional Reference Check"] =
          //         initialExecSum.executiveSummary[
          //           "Professional Reference Check"
          //         ];
          //     if (
          //       initialExecSum.forms["Professional Reference Check"] ===
          //       undefined
          //     )
          //       state.forms["Professional Reference Check"] = {};
          //     else
          //       state.forms["Professional Reference Check"] =
          //         initialExecSum.forms["Professional Reference Check"];
          //     break;
          //   case "UANVerification":
          //     if (
          //       initialExecSum.executiveSummary["UAN Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["UAN Verification"] = {};
          //     else
          //       state.executiveSummary["UAN Verification"] =
          //         initialExecSum.executiveSummary["UAN Verification"];
          //     if (initialExecSum.forms["UAN Verification"] === undefined)
          //       state.forms["UAN Verification"] = {};
          //     else
          //       state.forms["UAN Verification"] =
          //         initialExecSum.forms["UAN Verification"];
          //     break;
          //   case "Form16Verification":
          //     if (
          //       initialExecSum.executiveSummary["Form16 Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Form16 Verification"] = {};
          //     else
          //       state.executiveSummary["Form16 Verification"] =
          //         initialExecSum.executiveSummary["Form16 Verification"];
          //     if (initialExecSum.forms["Form16 Verification"] === undefined)
          //       state.forms["Form16 Verification"] = {};
          //     else
          //       state.forms["Form16 Verification"] =
          //         initialExecSum.forms["Form16 Verification"];
          //     break;
          //   case "CreditVerification":
          //     if (initialExecSum.executiveSummary["Credit Check"] === undefined)
          //       state.executiveSummary["Credit Check"] = {};
          //     else
          //       state.executiveSummary["Credit Check"] =
          //         initialExecSum.executiveSummary["Credit Check"];
          //     if (initialExecSum.forms["Credit Check"] === undefined)
          //       state.forms["Credit Check"] = {};
          //     else
          //       state.forms["Credit Check"] =
          //         initialExecSum.forms["Credit Check"];
          //     break;
          //   case "DrugVerification":
          //     if (
          //       initialExecSum.executiveSummary["Drug Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Drug Verification"] = {};
          //     else
          //       state.executiveSummary["Drug Verification"] =
          //         initialExecSum.executiveSummary["Drug Verification"];
          //     if (initialExecSum.forms["Drug Verification"] === undefined)
          //       state.forms["Drug Verification"] = {};
          //     else
          //       state.forms["Drug Verification"] =
          //         initialExecSum.forms["Drug Verification"];
          //     break;
          //   case "AustralianFederalPoliceVerification":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "National Police Check & 100 Points Check"
          //       ] === undefined
          //     )
          //       state.executiveSummary[
          //         "National Police Check & 100 Points Check"
          //       ] = {};
          //     else
          //       state.executiveSummary[
          //         "National Police Check & 100 Points Check"
          //       ] =
          //         initialExecSum.executiveSummary[
          //           "National Police Check & 100 Points Check"
          //         ];
          //     if (
          //       initialExecSum.forms[
          //         "National Police Check & 100 Points Check"
          //       ] === undefined
          //     )
          //       state.forms["National Police Check & 100 Points Check"] = {};
          //     else
          //       state.forms["National Police Check & 100 Points Check"] =
          //         initialExecSum.forms[
          //           "National Police Check & 100 Points Check"
          //         ];
          //     break;
          //   case "PoliceVerification":
          //     if (
          //       initialExecSum.executiveSummary["Police Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Police Verification"] = {};
          //     else
          //       state.executiveSummary["Police Verification"] =
          //         initialExecSum.executiveSummary["Police Verification"];
          //     if (initialExecSum.forms["Police Verification"] === undefined)
          //       state.forms["Police Verification"] = {};
          //     else
          //       state.forms["Police Verification"] =
          //         initialExecSum.forms["Police Verification"];
          //     break;
          //   case "WebAndMediaSearch":
          //     if (
          //       initialExecSum.executiveSummary["Web and Media Search"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Web and Media Search"] = {};
          //     else
          //       state.executiveSummary["Web and Media Search"] =
          //         initialExecSum.executiveSummary["Web and Media Search"];
          //     if (initialExecSum.forms["Web and Media Search"] === undefined)
          //       state.forms["Web and Media Search"] = {};
          //     else
          //       state.forms["Web and Media Search"] =
          //         initialExecSum.forms["Web and Media Search"];
          //     break;
          //   case "CompanyCheckVerification":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "Company Check Verification"
          //       ] === undefined
          //     )
          //       state.executiveSummary["Company Check Verification"] = {};
          //     else
          //       state.executiveSummary["Company Check Verification"] =
          //         initialExecSum.executiveSummary["Company Check Verification"];
          //     if (
          //       initialExecSum.forms["Company Check Verification"] === undefined
          //     )
          //       state.forms["Company Check Verification"] = {};
          //     else
          //       state.forms["Company Check Verification"] =
          //         initialExecSum.forms["Company Check Verification"];
          //     break;
          //   case "VendorVerification":
          //     if (
          //       initialExecSum.executiveSummary["Vendor Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Vendor Verification"] = {};
          //     else
          //       state.executiveSummary["Vendor Verification"] =
          //         initialExecSum.executiveSummary["Vendor Verification"];
          //     if (initialExecSum.forms["Vendor Verification"] === undefined)
          //       state.forms["Vendor Verification"] = {};
          //     else
          //       state.forms["Vendor Verification"] =
          //         initialExecSum.forms["Vendor Verification"];
          //     break;
          //   case "BankStatementVerification":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "Bank Statement Verification"
          //       ] === undefined
          //     )
          //       state.executiveSummary["Bank Statement Verification"] = {};
          //     else
          //       state.executiveSummary["Bank Statement Verification"] =
          //         initialExecSum.executiveSummary[
          //           "Bank Statement Verification"
          //         ];
          //     if (
          //       initialExecSum.forms["Bank Statement Verification"] ===
          //       undefined
          //     )
          //       state.forms["Bank Statement Verification"] = {};
          //     else
          //       state.forms["Bank Statement Verification"] =
          //         initialExecSum.forms["Bank Statement Verification"];
          //     break;
          //   case "OfficialNameChangeVerification":
          //     if (
          //       initialExecSum.executiveSummary[
          //         "Official Name Change Verification"
          //       ] === undefined
          //     )
          //       state.executiveSummary["Official Name Change Verification"] =
          //         {};
          //     else
          //       state.executiveSummary["Official Name Change Verification"] =
          //         initialExecSum.executiveSummary[
          //           "Official Name Change Verification"
          //         ];
          //     if (
          //       initialExecSum.forms["Official Name Change Verification"] ===
          //       undefined
          //     )
          //       state.forms["Official Name Change Verification"] = {};
          //     else
          //       state.forms["Official Name Change Verification"] =
          //         initialExecSum.forms["Official Name Change Verification"];
          //     break;
          //   case "FranchiseVerification":
          //     if (
          //       initialExecSum.executiveSummary["Franchise Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Franchise Verification"] = {};
          //     else
          //       state.executiveSummary["Franchise Verification"] =
          //         initialExecSum.executiveSummary["Franchise Verification"];
          //     if (initialExecSum.forms["Franchise Verification"] === undefined)
          //       state.forms["Franchise Verification"] = {};
          //     else
          //       state.forms["Franchise Verification"] =
          //         initialExecSum.forms["Franchise Verification"];
          //     break;
          //   case "DirectorshipVerification":
          //     if (
          //       initialExecSum.executiveSummary["Directorship Verification"] ===
          //       undefined
          //     )
          //       state.executiveSummary["Directorship Verification"] = {};
          //     else
          //       state.executiveSummary["Directorship Verification"] =
          //         initialExecSum.executiveSummary["Directorship Verification"];
          //     if (
          //       initialExecSum.forms["Directorship Verification"] === undefined
          //     )
          //       state.forms["Directorship Verification"] = {};
          //     else
          //       state.forms["Directorship Verification"] =
          //         initialExecSum.forms["Directorship Verification"];
          //     break;
          //   default:
          //     console.log("Default Case");
          // }
        }
      });
    });
  },
});
export const {
  changeExecutiveSummary,
  addExecutiveSummaryTypes,
  fillEduEmpForm,
  fillGapForm,
  fillIdForm,
  fillRightToWorkForm,
  fillCriminalDbForm,
  addSummaryRemarks,
  addFilesToState,
  deleteFilesFromState,
  deleteImageFromDb,
  removeImageFromAttachments,
  removeImagesUsingTitle,
  changeFormLoadingState,
  makeStateBlank,
  changeOverallColorCode,
  createSubTable,
  deleteImages,
  deleteSubTable,
  fillPoliceForm,
  fillWebMediaSearchForm,
  fillAddressForm,
  fillUANForm,
  fillForm26Form,
  fillForm16,
  fillReferenceCheckForm,

  editAttachmentTitle,

  makeFileStrucEmpty,
  addCellInSubFormsType1,
  addCellInSubFormsType2,
  fillDrugVerificationForm,
  fillAustralianFederalPoliceVerificationForm,

  fillCreditForm,

  fillBankStatementForm,
  fillCourtCheckDbForm,

  fillCompanyCheckForm,
  fillVendorForm,
  fillDirectorshipForm,
  fillOfficialNameChangeForm,
  fillFranchiseVerificationForm,
  addImagePropsIsChecked,
  addImagePropsTitle,
  addCellInPersonalInfo,
  editCellInPersonalInfo,
  deleteCellInPersonalInfo,
  editCellInExecutiveSummary,
  editCellInSummayDetailForm,
  editCellInGapDetailForm,
  editCellInCriminalVerificationDetailForm,
  editCellInDirectorshipDetailForm,
  editCellInFranchiseDetailForm,
  editCellInOfficialNameChangeDetailForm,
  editCellInBankStatementDetailForm,
  editCellInVendorVerificationDetailForm,
  editCellInCompanyCheckDetailForm,
  editCellInCourtCheckDetailForm,
  editCellInDataBaseDetailForm,
  editCellInWebAndMediaDetailForm,
  deleteCellInSummayDetailForm,
  deleteCellInCriminalVerification,
} = pdfdataSlice.actions;
export default pdfdataSlice.reducer;
