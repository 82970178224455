import React from "react";
import "../styles/pageStyles.css";
const Legend = (type) => {
  return (
    <table className="legend">
      {type.type == 1 ? (
        <tr>
          <td className="Green">Green- Clear Report</td>
          <td className="Amber">
            Amber-Inaccessible for verification / Unable to verify
          </td>
          <td className="Red">Red-Major Discrepancy</td>
        </tr>
      ) : (
        <tr>
          <td className="Red">Major Discrepancy</td>
          <td className="Amber" style={{ textAlign: "center" }}>
            Inaccessible for Verification / Unable to Verify / Inputs Required /
            Minor Discrepancy
          </td>
          <td className="Green">Verified Report</td>
        </tr>
      )}
    </table>
  );
};
export default Legend;
