import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  fillIdForm,
  editCellInSummayDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import ColorCheckFun from "./ColorCheckFun";
import SummaryRemarks from "./SummaryRemarks";

import { BASE_URL } from "../constants";
// import moment from "moment/moment.js";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
// import DatePickerComponent from "./DatePickerComponent";
import { useEffect, useState } from "react";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const Identity = ({ annexure }) => {
  const modelId = "identityCellTileEdit";

  const idForms = useSelector(
    (state) => state.pdfdata.forms["Identity Verification"]
  );
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dob = useSelector((state) => state.pdfdata["Date of Birth"]);

  const dispatch = useDispatch();

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    let value = e.target.value;
    dispatch(fillIdForm([table, form, component, e.target.name, value]));
    if (component === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(idForms).forEach((table) => {
      if (idForms[table]["form1"]["Mentioned Name"].infoProvided === "")
        document.getElementById(`IdentityVerification${table}`).value =
          applicantName.value;
    });
  }, [applicantName, idForms]);
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Identity Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Identity Verification</u>
      </h3>
      <>
        {Object.keys(idForms).map((table, index) => (
          <table key={table}>
            <tr>
              <th className="heading" colSpan={3}>
                <h3>{table}</h3>
              </th>
            </tr>
            <tr>
              <th className="centered-text">Components</th>
              <th className="centered-text">Information Provided</th>
              <th className="centered-text">Information Verified</th>
            </tr>
            {Object.keys(idForms[table]["form1"]).map((row, index) => (
              <>
                <tr>
                  {row === "Mentioned Name" ? (
                    <>
                      <td className="col-sm-6">{row}</td>

                      <td>
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          id={`IdentityVerification${table}`}
                          type="text"
                          defaultValue={
                            idForms[table]["form1"][row].infoProvided !== ""
                              ? idForms[table]["form1"][row].infoProvided
                              : applicantName.value
                          }
                          name="infoProvided"
                          onChange={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      </td>
                    </>
                  ) : row === "Date of Birth" && dob ? (
                    <>
                      <td className="col-sm-6">{row}</td>

                      <td>
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          id={`IdentityVerification${table}`}
                          type="text"
                          defaultValue={
                            idForms[table]["form1"][row].infoProvided !== ""
                              ? idForms[table]["form1"][row].infoProvided
                              : dob.value
                          }
                          name="infoProvided"
                          onChange={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="cellTitle col-sm-6">
                        <div className="row row-cols-2">
                          <div className="col-sm-11">{row}</div>
                          <div className="col-sm-1">
                            <button
                              className="editIcon fa fa-pencil"
                              type="button"
                              data-bs-toggle="modal"
                              title="Edit Cell Title"
                              data-bs-target={`#${modelId}`}
                              data-bs-whatever="@mdo"
                              onClick={() => {
                                setSelectedCellPosition(index);
                                setSelectedSubForm(table);
                                setselectedFormNumber("form1");
                                setSelectedCellTitle(row);
                              }}
                            ></button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          type="text"
                          defaultValue={
                            idForms[table]["form1"][row].infoProvided
                          }
                          name="infoProvided"
                          onBlur={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      </td>
                    </>
                  )}
                  <td>
                    <InfoVerifiedDropDown
                      fun={(e) => addVal(e, table, "form1", row)}
                      currentValue={idForms[table]["form1"][row].infoVerified}
                    />
                    {/* <input
                      type="text"
                      defaultValue={idForms[table]["form1"][row].infoVerified}
                      name="infoVerified"
                      onBlur={(e) => {
                        addVal(e, table, "form1", row);
                      }}
                    /> */}
                  </td>
                </tr>
              </>
            ))}
            {Object.keys(idForms[table]["form2"]).map((row, index) => (
              <tr>
                {row === "Color Code" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setselectedFormNumber("form2");
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Upload Document" ? (
                  <>
                    {" "}
                    For{" "}
                    <b>
                      Annexure {annexure}-{index + 1}
                    </b>
                  </>
                ) : (
                  <></>
                )}
                {row === "Color Code" ? (
                  <ColorCode
                    namefield={row}
                    cls={idForms[table]["form2"][row]}
                    fun={(e) => addVal(e, table, "form2", row)}
                    spanNum={2}
                  />
                ) : (
                  <td colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      defaultValue={idForms[table]["form2"][row]}
                      name={row}
                      onBlur={(e) => {
                        addVal(e, table, "form2", row);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={2}>
                <RecursiveModal
                  mainTable="Identity Verification"
                  subTable={table}
                  annexure={annexure + (index + 1)}
                />
                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Identity Verification"
              namefield={table.split(":")[0]}
              spanNum={2}
            />
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default Identity;
