import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { BASE_URL } from "../constants";
import { setError } from "../store/error/errorSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import NotificationAlert from "./NotificationAlert";

function GeneratePdfModal() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const GeneratePDF = (e, infomate360ReferenceNumber) => {
    e.preventDefault();
    if (infomate360ReferenceNumber === "")
      setNotify({
        isOpen: true,
        message:
          "Please enter Infomate360 Reference Number to generate the pdf!",
        type: "warning",
      });
    else {
      setLoading(true);
      axios
        .get(`${BASE_URL}form/user/${infomate360ReferenceNumber}`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          const mydata = res.data;
          const pdfName =
            (mydata["Client Reference No."].value === ""
              ? "NA"
              : mydata["Client Reference No."].value) +
            "-" +
            (mydata.infomate360ReferenceNumber === ""
              ? "NA"
              : mydata.infomate360ReferenceNumber) +
            "-" +
            (mydata["Applicant Name"].value === ""
              ? "NA"
              : mydata["Applicant Name"].value) +
            "-" +
            (mydata["Color Code"].value === ""
              ? "NA"
              : mydata["Color Code"].value) +
            "-Report";
          axios
            .get(`${BASE_URL}pdf/generate/${infomate360ReferenceNumber}`, {
              responseType: "blob",
              withCredentials: true,
            })
            .then((res) => {
              const pdfBlob = new Blob([res.data], {
                type: "application/pdf",
              });
              saveAs(pdfBlob, `${pdfName}.pdf`);
              setLoading(false);
            })

            .catch(async (e) => {
              setLoading(false);
              try {
                const msg = JSON.parse(await e.response.data.text()).message;
                //console.log(error.toString());
                if (msg !== undefined) {
                  dispatch(setError(["Error in generating pdf!", msg, true]));
                }
              } catch {
                dispatch(
                  setError(["Error in generating pdf!", e.toString(), false])
                );
              }
            });
        })
        .catch((e) => {
          setLoading(false);
          dispatch(
            setError(["Infomate360 Reference Number doesn't exist!", e, false])
          );
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <div className="modal-dialog dialog-of-modal">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <h5 className="modal-title title-modal" id="staticBackdropLabel">
              Enter the Infomate360 Reference Number for which you want to
              generate the PDF
            </h5>
          </div>
          <hr />
          <div className="modal-body body-modal">
            <div className="mb-3">
              <label
                htmlFor="recipient-name"
                className="form-label float-start"
              >
                Infomate360 Reference No:
              </label>
              <input
                className="form-control-sm border border-secondary"
                id="myInput"
              />
            </div>
          </div>
          {loading ? (
            <div
              className="modal-footer justify-content-around"
              style={{ fontFamily: "Times New Roman" }}
            >
              <button
                type="button"
                title="Generate PDF"
                className="btn-modal button-close button-close-hover"
              >
                <i className="fa fa-spinner fa-spin"></i>
                Generating PDF...
              </button>
              <button
                type="button"
                title="Close This Modal"
                className="btn-modal button-close button-close-hover"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          ) : (
            <div
              className="modal-footer justify-content-around"
              style={{ fontFamily: "Times New Roman" }}
            >
              <button
                type="button"
                title="Generate PDF"
                className="btn-modal button-close button-close-hover"
                onClick={(e) => {
                  const input = document.getElementById("myInput").value;
                  GeneratePDF(e, input);
                }}
              >
                Generate PDF
              </button>
              <button
                type="button"
                title="Close This Modal"
                className="btn-modal button-close button-close-hover"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("myInput").value = "";
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GeneratePdfModal;