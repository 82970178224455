import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillGapForm,
  editCellInGapDetailForm,
  deleteCellInSubForm,
  addCellInSubFormsType1,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import AddCellModel from "./AddCellModel";
import ColorCheckFun from "./ColorCheckFunNew";
import NotificationAlert from "./NotificationAlert";
import SummaryRemarksNew from "./SummaryRemarksNew";
import RecursiveModalNew from "./RecursiveModalNew";
import EditCellTitleModel from "./EditCellTitleModel";
import ConfirmationDialog from "./ConfirmationDialog";

const GapNew = ({ annexure }) => {
  const modelId = "GapCellTileEdit";

  const dispatch = useDispatch();

  const gapForms = useSelector(
    (state) => state.pdfdatanew.forms["Gap Verification"]
  );
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Gap Verification"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Applicant Name"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addVal = (e, table) => {
    e.preventDefault();
    console.log("added data", table, e.target.name, e.target.value);
    dispatch(fillGapForm([table, e.target.name, e.target.value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType1({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Gap Verification";
    dispatch(
      editCellInGapDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };

  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Gap Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSubForm({
        subform,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  useEffect(() => {
    Object.keys(gapForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Gap Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(gapForms).map((table, index) => {
      dispatch(
        fillGapForm([
          table,
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />

      <h3 className="text-center mt-4">
        <u>Gap Verification</u>
      </h3>

      {Object.keys(gapForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Gap Justification</th>
            <th className="centered-text">Remarks</th>
          </tr>
          {Object.keys(gapForms[table]).map((row, index) => (
            <tr>
              {row === "Name of the Applicant" || row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-2">
                      <div className="col-6">
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              color: "error",
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, table, "form1");
                              },
                            });
                          }}
                        ></button>
                      </div>
                      <div className="col-6">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setSelectedCellTitle(row);
                            // setselectedFormNumber("form1");
                          }}
                        ></button>
                      </div>
                    </div>
                  </div>
                </td>
              )}
              {row === "Name of the Applicant" ? (
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    id={`GapVerification${table}`}
                    type="text"
                    name={row}
                    defaultValue={
                      gapForms[table][row] !== ""
                        ? gapForms[table][row]
                        : applicantName
                    }
                    onChange={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              ) : row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={gapForms[table][row]}
                  fun={(e) => {
                    addVal(e, table);
                  }}
                  spanNum={1}
                />
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    disabled={row === "Annexure Details" ? true : false}
                    defaultValue={gapForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModalNew
                mainTable="Gap Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              {" "}
              <button
                type="button"
                className="btn btn-sm btn-outline-dark col-sm-8"
                style={{
                  paddingRight: "4px",
                  paddingLeft: "4px",
                }}
                data-bs-toggle="modal"
                title="Add One More Cell"
                data-bs-target={`#addCellModelGapVerification${index}`}
                data-bs-whatever="@mdo"
              >
                <b
                  style={{
                    color: "#32cd32",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Add Cell
                </b>
              </button>
            </td>
          </tr>

          <AddCellModel
            inputFields={gapForms[table]}
            onaddCell={(typecellref, fieldType, position) =>
              addCell(
                typecellref,
                fieldType,
                position,
                "Gap Verification",
                table
              )
            }
            uniqueName={`GapVerification${index}`}
          />

          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default GapNew;
