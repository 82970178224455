import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

function PreviewPdfModal(props) {
  const {
    previewLoader,
    previewModalFlag,
    setPreviewModalFlag,
    noPreviewModal,
    pdfFileUrl,
  } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      {noPreviewModal ? (
        <div
          className="modal fade"
          id="staticBackdropPreviewModal"
          tabIndex="-1"
          aria-labelledby="staticBackdropPreviewModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog dialog-of-modal">
            <div
              className="modal-content content-modal"
              style={{ fontFamily: "Manrope" }}
            >
              <div className="modal-header">
                <h5 className="modal-title title-modal">Preview</h5>
              </div>
              <hr />
              <div className="modal-body body-modal">
                Before trying to preview the PDF, please press the submit
                button!
              </div>
              <hr />
              <div className="modal-footer justify-content-around">
                <button
                  type="button"
                  className="btn-modal button-close button-close-hover"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="modal fade"
          id="staticBackdropPreviewModal"
          tabIndex="-1"
          aria-labelledby="staticBackdropPreviewModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-lg">
            <div
              className="modal-content content-modal"
              style={{ fontFamily: "Manrope" }}
            >
              <div className="modal-header">
                <h5 className="modal-title title-modal" id="exampleModalLabel">
                  Preview
                </h5>
              </div>
              <hr />
              <div className="modal-body body-modal">
                {previewLoader ? (
                  <div className="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : previewModalFlag ? (
                  <center>
                    <div>
                      <Document
                        file={pdfFileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            size="A4"
                          />
                        ))}
                      </Document>
                    </div>
                  </center>
                ) : (
                  <></>
                )}
              </div>
              <hr />

              <div className="modal-footer justify-content-around">
                {previewLoader ? (
                  <button
                    type="button"
                    className="btn-modal button-close button-close-hover"
                    data-bs-dismiss="modal"
                    disabled
                  >
                    Close
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-modal button-close button-close-hover"
                    data-bs-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      setPreviewModalFlag(false);
                    }}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreviewPdfModal;