import React from "react";

function DownloadTrackerModal(props) {
  const { loading, modalId } = props;

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby="staticBackdropTrackerLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog dialog-of-modal">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="modal-body body-modal">
            {loading ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i className="fa fa-spinner fa-spin"></i>Downloading
                  Tracker...........
                </div>
              </div>
            ) : (
              <div>Tracker Downloaded Successfully</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadTrackerModal;
