import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  addImagePropsTitle,
  removeImagesUsingTitle,
  deleteImageFromDb,
  fillEduEmpForm,
  editCellInSummayDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCheckFun from "./ColorCheckFun";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import { BASE_URL } from "../constants";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import { setError } from "../store/error/errorSlice";
import uniqueString from "unique-string";
import NotificationAlert from "./NotificationAlert";

const Education = ({ annexure }) => {
  const modelId = "educationCellTileEdit";

  const eduForms = useSelector(
    (state) => state.pdfdata.forms["Education Verification"]
  );
  const attachmentList = useSelector((state) => state.pdfdata.attachments);
  const imgStruc = useSelector(
    (state) => state.pdfdata.imgStruc["Education Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [loading, setloading] = useState(false);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillEduEmpForm([
        "Education Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );

    if (component === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    axios
      .get(`${BASE_URL}client/universities`, {
        withCredentials: true,
      })
      .then((res) => {});
  }, []);
  useEffect(() => {
    f();
    Object.keys(eduForms).forEach((table) => {
      if (eduForms[table]["form1"]["Name of the Applicant"].infoProvided === "")
        document.getElementById(`EducationVerification${table}`).value =
          applicantName.value;
    });
  }, [applicantName, eduForms]);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Education Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };

  const [universityNameList, setUniversityNameList] = useState([]);

  useEffect(() => {
    async function findUniversityNames() {
      axios
        .get(`${BASE_URL}client/universities`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setUniversityNameList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Company Name List!", e, false])
          );
        });
    }
    findUniversityNames();
  }, []);

  const addUGCDocument = async (e, subTable, annexure, row) => {
    document.getElementById(`tailspin${subTable}`).style.visibility = "visible";
    let inputVal = e.target.value.trim().toLowerCase();
    let formVal = eduForms[subTable]["form1"][row].infoProvided
      .trim()
      .toLowerCase();

    if (formVal === "") {
      await axios({
        url: `${BASE_URL}client/university/${encodeURIComponent(
          e.target.value
        )}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      }).then(async (res) => {
        console.log(res.data);
        const currentKey = uniqueString();
        return axios
          .get(
            `${BASE_URL}form/image/${res.data.data.Attachments[0]}`,
            {
              responseType: "blob",
            }
          )
          .then((img) => {
            console.log(img);
            const file = new File([img.data], `UGC Snapshot.png`, {
              lastModified: new Date(),
              type: "image/png",
            });
            dispatch(
              addImagePropsTitle([
                "",
                "Education Verification",
                subTable,
                currentKey,
                "UGC Snapshot",
                annexure,
              ])
            );
            dispatch(addFilesToState([currentKey, { ...[file] }]));
            addVal(e, subTable, "form1", row);
          })
          .catch(async (e) => {
            try {
              const msg = JSON.parse(await e.response.data.text()).message;

              if (msg !== undefined) {
                dispatch(
                  setError(["Error in downloading image document!", msg, true])
                );
              }
            } catch {
              dispatch(
                setError([
                  "Error in downloading image document!",
                  e.toString(),
                  false,
                ])
              );
            }
          });
      });
    } else if (inputVal !== formVal) {
      const keyObject = Object.entries(imgStruc[subTable]).filter((item) => {
        console.log(item);
        return item[1].title === "UGC Snapshot";
      });
      if (
        keyObject !== undefined &&
        keyObject.length !== 0 &&
        keyObject.length[0] !== 0
      ) {
        const key = keyObject[0][0];
        const mainTable = "Education Verification";
        dispatch(
          removeImagesUsingTitle({
            mainTable,
            subTable,
            key,
          })
        );
        if (
          attachmentList[key] !== undefined &&
          attachmentList[key].length > 0
        ) {
          const path = attachmentList[key][0];

          dispatch(deleteImageFromDb(path));
        }
      }

      await axios({
        url: `${BASE_URL}client/university/${encodeURIComponent(
          e.target.value
        )}`,
        method: "GET",

        responseType: "json",
        withCredentials: true,
      }).then(async (res) => {
        console.log(res.data);
        const currentKey = uniqueString();
        return axios
          .get(
            `${BASE_URL}form/image/${res.data.data.Attachments[0]}`,
            {
              responseType: "blob",
            }
          )
          .then((img) => {
            console.log(img);
            const file = new File([img.data], `UGC Snapshot.png`, {
              lastModified: new Date(),
              type: "image/png",
            });
            dispatch(
              addImagePropsTitle([
                "",
                "Education Verification",
                subTable,
                currentKey,
                "UGC Snapshot",
                annexure,
              ])
            );
            dispatch(addFilesToState([currentKey, { ...[file] }]));
            addVal(e, subTable, "form1", row);
            setloading(false);
          })
          .catch(async (e) => {
            try {
              const msg = JSON.parse(await e.response.data.text()).message;

              if (msg !== undefined) {
                dispatch(
                  setError(["Error in downloading image document!", msg, true])
                );
              }
            } catch {
              dispatch(
                setError([
                  "Error in downloading image document!",
                  e.toString(),
                  false,
                ])
              );
            }
          });
      });
    }
    document.getElementById(`tailspin${subTable}`).style.visibility = "hidden";
  };
  function f() {
    return Object.keys(eduForms).map((table, index) => (
      <table key={table}>
        <tr>
          <th className="heading" colSpan={3}>
            <h3>
              {table}
              {
                <div
                  className="loadingAnimation"
                  id={`tailspin${table}`}
                  style={{
                    marginTop: "-25px",
                    visibility: "hidden",
                  }}
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Please Wait!!!</span>
                </div>
              }
            </h3>
          </th>
        </tr>
        <tr>
          <th className="centered-text">Components</th>
          <th className="centered-text">Information Provided</th>
          <th className="centered-text">Information Verified</th>
        </tr>
        {Object.keys(eduForms[table]["form1"]).map((row, index) => (
          <>
            <tr>
              {row === "Name of the Applicant" ? (
                <>
                  <td className="col-sm-6">{row}</td>
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`EducationVerification${table}`}
                      type="text"
                      defaultValue={
                        eduForms[table]["form1"][row].infoProvided !== ""
                          ? eduForms[table]["form1"][row].infoProvided
                          : applicantName
                      }
                      name="infoProvided"
                      onChange={
                        (e) => {
                          addVal(e, table, "form1", row);
                        }
                        // onBlur(e, item.component)
                      }
                    />
                  </td>
                </>
              ) : (
                <>
                  <td className="cellTitle">
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setselectedFormNumber("form1");
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>

                  {row.includes("University") ||
                  row.includes("College") ||
                  row.includes("Institute") ||
                  row.includes("Board") ||
                  row.includes("Location") ? (
                    <td>
                      <input
                        type="text"
                        list="universities"
                        style={{ width: 315, height: 30 }}
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={(e) => {
                          addVal(e, table, "form1", row);
                          if (e.target.value !== "")
                            addUGCDocument(e, table, index + 1, row);
                        }}
                      />
                      <datalist id="universities">
                        <option></option>
                        {universityNameList.map((item, index) => {
                          return item ===
                            eduForms[table]["form1"][row].infoProvided ? (
                            <option value={item} selected>
                              {item}
                            </option>
                          ) : (
                            <option value={item}>{item}</option>
                          );
                        })}
                      </datalist>
                    </td>
                  ) : (
                    <td>
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        defaultValue={
                          eduForms[table]["form1"][row].infoProvided
                        }
                        name="infoProvided"
                        onBlur={
                          (e) => {
                            addVal(e, table, "form1", row);
                          }
                          // onBlur(e, item.component)
                        }
                      />
                    </td>
                  )}
                </>
              )}
              <td>
                <InfoVerifiedDropDown
                  fun={(e) => addVal(e, table, "form1", row)}
                  currentValue={eduForms[table]["form1"][row].infoVerified}
                />
              </td>
            </tr>
          </>
        ))}
        {Object.keys(eduForms[table]["form2"]).map((row, index) => (
          <tr>
            {row === "Color Code" ? (
              <td>{row}</td>
            ) : (
              <td className="cellTitle">
                <div className="row row-cols-2">
                  <div className="col-sm-11">{row}</div>
                  <div className="col-sm-1">
                    <button
                      className="editIcon fa fa-pencil"
                      type="button"
                      data-bs-toggle="modal"
                      title="Edit Cell Title"
                      data-bs-target={`#${modelId}`}
                      data-bs-whatever="@mdo"
                      onClick={() => {
                        setSelectedCellPosition(index);
                        setSelectedSubForm(table);
                        setselectedFormNumber("form2");
                        setSelectedCellTitle(row);
                      }}
                    ></button>
                  </div>
                </div>
              </td>
            )}
            {row === "Color Code" ? (
              <ColorCode
                namefield={row}
                cls={eduForms[table]["form2"][row]}
                fun={(e) => addVal(e, table, "form2", row)}
                spanNum={2}
              />
            ) : (
              <td colSpan={2}>
                <textarea
                  style={{ width: "100%", height: 30 }}
                  type="text"
                  defaultValue={eduForms[table]["form2"][row]}
                  name={row}
                  onBlur={(e) => {
                    addVal(e, table, "form2", row);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
        <tr>
          <td>Upload Document</td>
          <td colSpan={2}>
            <RecursiveModal
              mainTable="Education Verification"
              subTable={table}
              annexure={annexure + (index + 1)}
            />

            <span className="alert">
              Files of format .jpg, .jpeg, .png, .eml are accepted!
            </span>
          </td>
        </tr>
        <SummaryRemarks
          type="Education Verification"
          namefield={table}
          spanNum={2}
        />
        <tr>
          <td colSpan={3}>
            Please Refer{" "}
            <b>
              Annexure-{annexure}
              {index + 1}
            </b>
          </td>
        </tr>
        <EditCellTitleModel
          modelId={modelId}
          onEditCell={onEditCell}
          currentTitle={selectedCellTitle}
          setCurrentTitle={setSelectedCellTitle}
        />
      </table>
    ));
  }
  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-5">
        <u>Education Verification</u>
      </h3>
      {f()}
    </>
  );
};
export default Education;
