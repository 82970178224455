import React, { useMemo, useState, useEffect } from "react";
import ExecutiveSummary from "./ExecutiveSummary";
import Legend from "./Legend";
import "../styles/pageStyles.css";
import { useDispatch } from "react-redux";
import {
  changeExecutiveSummary,
  fetchDataByRefNum,
  changeFormLoadingState,
  makeStateBlank,
  addCellInPersonalInfo,
  editCellInPersonalInfo,
  deleteCellInPersonalInfo,
  fetchDataByClientSpecific,
  addExecutiveSummaryTypes,
} from "../store/pdfdata/pdfdataSliceNew";
import { useSelector } from "react-redux";
import moment from "moment/moment.js";
import AddCellModel from "./AddCellModel";
import EditCellTitleModel from "./EditCellTitleModel";
import { BASE_URL } from "../constants";

import DatePickerReact from "./DatePickerReact";
import { setError } from "../store/error/errorSlice";
import axios from "axios";
import { setInfmRefNumNotFilled } from "../store/flags/flagSlice";
import ConfirmationDialog from "./ConfirmationDialog";
import NotificationAlert from "./NotificationAlert";
import CaseDetails from "./CaseDetails";

const PersonalInformationNew = ({
  colorCodeArray,
  setFlag,
  infomate360ReferenceNumber,
}) => {
  const modelId = "personalinfoEditCell";
  const isFormLoading = useSelector((state) => state.isFormLoading);
  const currState = useSelector((state) => state.pdfdatanew);
  const clientSpecificVal = useSelector(
    (state) => state.pdfdatanew["Client Specific"]?.value
  );
  const dispatch = useDispatch();
  const [clientSpecificList, setClientSpecificList] = useState([]);
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const InfmRefNumNotFilled = useSelector(
    (state) => state.flags.IsInfmRefNumNotFilled
  );
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const returnTrue = (e) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    return true;
  };
  useEffect(() => {
    console.log("infomate360ReferenceNumber", infomate360ReferenceNumber);
    if (infomate360ReferenceNumber !== "") {
      dispatch(fetchDataByRefNum([dispatch, infomate360ReferenceNumber]));
    }
  }, []);

  useEffect(() => {
    async function findColor() {
      axios
        .get(`${BASE_URL}client/specifics`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setClientSpecificList(res.data.data);
        })
        .catch((e) => {
          dispatch(
            setError(["Error in fetching Client Specific List!", e, false])
          );
        });
    }
    findColor();
  }, []);

  useEffect(() => {
    if (!clientSpecificVal) {
      dispatch(addExecutiveSummaryTypes({ executiveSummary: {}, forms: {} }));
    } else {
      //dispatch(fetchDataByClientSpecific([dispatch, clientSpecificVal]));
    }
  }, [clientSpecificVal]);

  const fetchDataInfoRef = (valueInfo) => {
    setFlag(false);
    if (valueInfo === "") {
      let flag = 0;
      if (Object.keys(currState["forms"]).length !== 0) {
        console.log("Here", currState["forms"]);
        flag = 1;
      } else {
        Object.keys(currState).forEach((key) => {
          if (typeof currState[key] === "string" && currState[key] !== "")
            flag = 1;
        });
      }
      let response;
      if (flag === 1) {
        response = window.confirm("Do want to clear all data?");
      }
      if (response === true) {
        dispatch(makeStateBlank());
      }
    } else {
      let res = window.confirm(`Do you want to fetch data for ${valueInfo}?`);
      if (res === true) {
        dispatch(changeFormLoadingState(true));
        dispatch(makeStateBlank());
        dispatch(fetchDataByRefNum([dispatch, valueInfo])).finally(() => {
          dispatch(setInfmRefNumNotFilled(false));
        });

        if (currState["Client Specific"]?.value !== "") {
          // dispatch(
          //   fetchDataByClientSpecific([
          //     dispatch,
          //     currState["Client Specific"]?.value,
          //   ])
          // );
        }
        console.log("Before CES: ", valueInfo);
        dispatch(
          changeExecutiveSummary(["infomate360ReferenceNumber", valueInfo])
        );
        console.log("After CES: ");
      }
    }
  };
  const personalInfoFormHandler = (e) => {
    e.preventDefault();
    console.log("Before PIF");
    if (e.target.name && e.target.value) {
      if (e.target.name === "Client Specific") {
        const clientSpecific = e.target.value;
        if (clientSpecific !== "") {
          // dispatch(fetchDataByClientSpecific([dispatch, clientSpecific]));
        }
      }
      if (e.target.type === "date") {
        let formattedDate = moment(e.target.value, "YYYY-MM-DD").format(
          "DD-MMM-YYYY"
        );
        if (formattedDate === "Invalid date") formattedDate = "";
        dispatch(changeExecutiveSummary([e.target.name, formattedDate]));
      } else {
        dispatch(changeExecutiveSummary([e.target.name, e.target.value]));
      }
      console.log("After PIF: Inside");
    }
    console.log("After PIF: IF");
  };

  const inputFields = useMemo(() => {
    const p = {};
    for (const key in currState) {
      if (
        key === "executiveSummary" ||
        key === "files" ||
        key === "imgStruc" ||
        key === "forms" ||
        key === "isFormLoading" ||
        key === "attachments" ||
        key === "attachmentKeysToDelete" ||
        key === "dummyForms" ||
        key === "dummyExecutiveSummary" ||
        key === "caseDetails"
      ) {
      } else {
        p[key] = currState[key];
        if (
          key === "infomate360ReferenceNumber" &&
          document.getElementById("InfmId") !== null
        ) {
          console.log("memo");
          if (currState[key]) {
            document.getElementById("InfmId").value = currState[key];
          } else {
            document.getElementById("InfmId").value = "";
          }
        }
      }
    }
    return p;
  }, [currState]);

  const addCell = (typecellref, fieldType, position) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(addCellInPersonalInfo({ type: typecellref, fieldType, position }));
  };
  const [selectedCellPosition, setSelectedCellPosition] = useState();

  const EditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    dispatch(
      editCellInPersonalInfo({ type: cellTitileRef, selectedCellPosition })
    );
  };
  const onSetClientSpecific = (nam, value) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    dispatch(
      addExecutiveSummaryTypes({
        executiveSummary: {},
        forms: {},
      })
    );
    dispatch(changeExecutiveSummary([nam, value]));
  };
  const deleteCell = (index) => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    dispatch(deleteCellInPersonalInfo(index));
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (name, val) => {
    dispatch(changeExecutiveSummary([name, val]));
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      {InfmRefNumNotFilled && (
        <div className="col d-flex justify-content-end">
          <div className=" font-weight-bold text-danger ">
            *Please enter Infomate360 Reference Number first!!
          </div>
        </div>
      )}
      <div className="informateHeading">
        <input
          id="InfmId"
          type="text"
          name="infomate360ReferenceNumber"
          placeholder="Please Enter Infomate360 Reference No"
          disabled={!InfmRefNumNotFilled}
        />
        <button
          className="btn btn-outline-dark fa fa-search"
          disabled={!InfmRefNumNotFilled}
          onClick={(e) => {
            e.preventDefault();
            if (
              document.getElementById("InfmId") &&
              document.getElementById("InfmId").value &&
              document.getElementById("InfmId").value.trim() !== ""
            ) {
              fetchDataInfoRef(document.getElementById("InfmId").value);
            }

            personalInfoFormHandler(e);
          }}
        ></button>
      </div>

      <table>
        <div className="container-fluid">
          <div className="row row-cols-2">
            {Object.keys(inputFields).map((e, index) => {
              if (e === "Applicant Name") {
                return (
                  <React.Fragment key={e}>
                    <td className="col-sm-3">{e}</td>
                    <td className="col-sm-9">
                      <input
                        name={e}
                        type={inputFields[e].type}
                        value={inputFields[e].value}
                        onChange={personalInfoFormHandler}
                        disabled={InfmRefNumNotFilled}
                      />
                    </td>
                  </React.Fragment>
                );
              }
              if (e === "infomate360ReferenceNumber") {
                return <></>;
              }
              if (inputFields[e].type === "date") {
                return (
                  <React.Fragment key={e}>
                    <div className="col" key={e}>
                      <div className="row row-cols-2 ">
                        {inputFields.infomate360ReferenceNumber ? (
                          <div className="col">
                            <div className="cellTitle row row-cols-2">
                              <td
                                className="col-9"
                                style={{ borderRight: "none" }}
                              >
                                <input
                                  className="largeCelltitle"
                                  type="text"
                                  readOnly
                                  value={e}
                                  title={e}
                                  placeholder={e}
                                />
                              </td>
                              <td className="cellTitlewithIcon col-3">
                                <div className="row">
                                  <div className="col-6">
                                    <button
                                      className="editIcon fa fa-pencil"
                                      type="button"
                                      data-bs-toggle="modal"
                                      title="Edit Cell Title"
                                      data-bs-target={`#${modelId + index}`}
                                      data-bs-whatever="@mdo"
                                      onClick={() => {
                                        setSelectedCellPosition(index);
                                        setSelectedCellTitle(e);
                                      }}
                                    ></button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      className="editIcon fa fa-trash"
                                      type="button"
                                      title="Delete Cell Title"
                                      onClick={(e) => {
                                        setConfirmation({
                                          isOpen: true,
                                          color: "error",
                                          title:
                                            "Are you sure to delete this cell?",
                                          subTitle:
                                            "You can't undo this operation!",
                                          onConfirm: () => {
                                            deleteCell(index);
                                          },
                                        });
                                      }}
                                    ></button>
                                  </div>
                                </div>
                              </td>
                            </div>
                          </div>
                        ) : (
                          <td className="cellTitle">{e}</td>
                        )}

                        <td>
                          {!InfmRefNumNotFilled && (
                            <DatePickerReact
                              inputTagName={e}
                              val={inputFields[e].value}
                              isSubtable={true}
                              fun={(name, value) => {
                                addDateVal(name, value);
                              }}
                            />
                          )}
                        </td>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              console.log("Personal Information(e): ", e);
              return (
                <React.Fragment key={e}>
                  <div className="col" key={e}>
                    <div className="row row-cols-2 ">
                      {inputFields.infomate360ReferenceNumber ? (
                        <div className="col">
                          <div className="cellTitle row row-cols-2">
                            <td
                              className="col-9"
                              style={{ borderRight: "none" }}
                            >
                              <input
                                className="largeCelltitle"
                                type="text"
                                readOnly
                                value={e}
                                title={e}
                                placeholder={e}
                              />
                            </td>
                            <td className="cellTitlewithIcon col-3">
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    className="editIcon fa fa-pencil"
                                    type="button"
                                    data-bs-toggle="modal"
                                    title="Edit Cell Title"
                                    data-bs-target={`#${modelId + index}`}
                                    data-bs-whatever="@mdo"
                                    onClick={() => {
                                      setSelectedCellPosition(index);
                                      setSelectedCellTitle(e);
                                    }}
                                  ></button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className="editIcon fa fa-trash"
                                    type="button"
                                    title="Delete Cell Title"
                                    onClick={(e) => {
                                      setConfirmation({
                                        isOpen: true,
                                        color: "error",
                                        title:
                                          "Are you sure to delete this cell?",
                                        subTitle:
                                          "You can't undo this operation!",
                                        onConfirm: () => {
                                          deleteCell(index);
                                        },
                                      });
                                    }}
                                  ></button>
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      ) : (
                        <td className="cellTitle">
                          <input
                            className="largeCelltitle"
                            type="text"
                            readOnly
                            value={e}
                            title={e}
                            placeholder={e}
                          />
                        </td>
                      )}

                      <td>
                        <input
                          name={e}
                          type="text"
                          value={inputFields[e].value}
                          onChange={personalInfoFormHandler}
                          disabled={InfmRefNumNotFilled}
                        />
                      </td>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {inputFields.infomate360ReferenceNumber && (
              <React.Fragment>
                <td className="col-sm-9">
                  <div className="row row-cols-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-dark col-sm-2"
                      style={{
                        paddingRight: "4px",
                        paddingLeft: "4px",
                      }}
                      data-bs-toggle="modal"
                      title="Add One More Cell"
                      data-bs-target="#addCellModelPersonalInfo"
                      data-bs-whatever="@mdo"
                    >
                      <b
                        style={{
                          color: "#32cd32",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Add Cell
                      </b>
                    </button>
                  </div>
                </td>
              </React.Fragment>
            )}
          </div>
        </div>
        <AddCellModel
          inputFields={inputFields}
          onaddCell={addCell}
          uniqueName="PersonalInfo"
        />
        <EditCellTitleModel
          modelId={modelId + selectedCellPosition}
          onEditCell={EditCell}
          currentTitle={selectedCellTitle}
          setCurrentTitle={setSelectedCellTitle}
        />
      </table>
      <Legend type="2" />
      {isFormLoading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <></>
      )}
      {inputFields.infomate360ReferenceNumber && (
        <CaseDetails
          informate360Refrence={inputFields.infomate360ReferenceNumber}
        />
      )}
    </div>
  );
};
export default PersonalInformationNew;
