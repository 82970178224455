import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillCriminalDbForm,
  editCellInCriminalVerificationDetailForm,
  deleteCellInCriminalVerification,
  addCellInSubFormsType1,
} from "../store/pdfdata/pdfdataSlice";
import "../styles/pageStyles.css";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import AddCellModel from "./AddCellModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";

const Criminal = ({ annexure }) => {
  const modelId = "criminalVerificationCellTileEdit";

  const criminalForms = useSelector(
    (state) => state.pdfdata.forms["Criminal Verification"]
  );
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const FatherName = useSelector((state) => state.pdfdata["Father Name"]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const dispatch = useDispatch();
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType1({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };
  const [selectedCellTitle, setSelectedCellTitle] = useState("");

  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(
      fillCriminalDbForm([
        "Criminal Verification",
        table,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(criminalForms).forEach((table) => {
      if (criminalForms[table]["Name of the Applicant"] === "")
        document.getElementById(
          `CriminalVerification${table}NameOfApplicant`
        ).value = applicantName.value;
    });
  }, [applicantName.value, criminalForms]);
  useEffect(() => {
    Object.keys(criminalForms).forEach((table) => {
      if (criminalForms[table]["Father Name"] === "" && FatherName)
        document.getElementById(
          `CriminalVerification${table}FatherName`
        ).value = FatherName.value;
    });
  }, [FatherName, criminalForms]);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Criminal Verification";
    dispatch(
      editCellInCriminalVerificationDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  const onDeleteCell = (index, type) => {
    if (index === "" || type === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Criminal Verification";
    const subform = type;
    const cellPosition = index;

    dispatch(
      deleteCellInCriminalVerification({
        subform,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <h3 className="text-center mt-4">
        <u>Criminal Verification</u>
      </h3>
      <>
        {Object.keys(criminalForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading" colSpan={3}>
                <div className="row row-cols-2">
                  <div className="col">
                    <h3>{type}</h3>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-dark col-sm-2 float-end"
                      style={{
                        paddingRight: "4px",
                        paddingLeft: "4px",
                      }}
                      data-bs-toggle="modal"
                      title="Add One More Cell"
                      data-bs-target={`#addCellModelCriminalVerification${index}`}
                      data-bs-whatever="@mdo"
                    >
                      <b
                        style={{
                          color: "#32cd32",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Add Cell
                      </b>
                    </button>
                  </div>
                </div>
              </th>
            </tr>
            {Object.keys(criminalForms[type]).map((row, index) => (
              <tr>
                {row === "Name of the Applicant" || row === "Color Code" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(type);
                            // setselectedFormNumber("form2");
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                      <div className="col-sm-1">
                        {" "}
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, type);
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Name of the Applicant" ? (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CriminalVerification${type}NameOfApplicant`}
                      defaultValue={
                        criminalForms[type][row] !== ""
                          ? criminalForms[type][row]
                          : applicantName.value
                      }
                      name={row}
                      onChange={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                ) : row === "Father Name" && FatherName ? (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CriminalVerification${type}FatherName`}
                      defaultValue={
                        criminalForms[type][row] !== ""
                          ? criminalForms[type][row]
                          : FatherName.value
                      }
                      name={row}
                      onChange={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                ) : row === "Color Code" ? (
                  <ColorCode
                    cls={criminalForms[type][row]}
                    namefield={row}
                    fun={(e) => addVal(e, type)}
                    spanNum={1}
                  />
                ) : (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      value={criminalForms[type][row]}
                      name={row}
                      onChange={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Criminal Verification"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Criminal Verification"
              namefield={type}
              spanNum={1}
            />
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <AddCellModel
              inputFields={criminalForms[type]}
              onaddCell={(typecellref, fieldType, position) =>
                addCell(
                  typecellref,
                  fieldType,
                  position,
                  "Criminal Verification",
                  type
                )
              }
              uniqueName={`CriminalVerification${index}`}
            />
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default Criminal;
