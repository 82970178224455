import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillReferenceCheckForm,
  editCellInSummayDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const ProfessionalReferenceCheck = ({ annexure }) => {
  const modelId = "professionalRefcheckCellTileEdit";

  const dispatch = useDispatch();

  const profRefForms = useSelector(
    (state) => state.pdfdata.forms["Professional Reference Check"]
  );
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillReferenceCheckForm([
        "Professional Reference Check",
        table,
        form,
        component,
        e.target.value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Professional Reference Check";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Professional Reference Check</u>
      </h3>
      {Object.keys(profRefForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>

          {Object.keys(profRefForms[table]["form1"]).map((row, index) => (
            <>
              <tr>
                <td className="cellTitle">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form1");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={profRefForms[table]["form1"][row]}
                    name="infoProvided"
                    onBlur={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>
              </tr>
            </>
          ))}
          {Object.keys(profRefForms[table]["form2"]).map((row, index) => (
            <tr>
              {row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-8">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form2");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
              )}

              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={profRefForms[table]["form2"][row]}
                  fun={(e) => addVal(e, table, "form2", row)}
                  spanNum={2}
                />
              ) : (
                <td colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={profRefForms[table]["form2"][row]}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Professional Reference Check"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Professional Reference Check"
            namefield={table}
            spanNum={2}
          />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </>
  );
};
export default ProfessionalReferenceCheck;
