import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Radio } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog(props) {
  const { confirmation, setConfirmation } = props;

  return (
    <div>
      <Dialog
        open={confirmation.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {" "}
          <Radio label="Text" defaultChecked />
          {confirmation.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmation.subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmation({ ...confirmation, isOpen: false });
              confirmation.onDisprove();
            }}
          >
            No
          </Button>
          <Button onClick={confirmation.onConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationDialog;
