import React from "react";
import { changeOverallColorCode } from "../store/pdfdata/pdfdataSlice";
import { useSelector } from "react-redux";
const ColorCheckFun = (dispatch, execSummary, table, value) => {
  // const colorArray=["Green","IRCEP","Amber","IRWIP"]
  //const colorArray = ["IRCEP", "Red", "Amber"];
  const colorArray = [];
  let index = 0;
  Object.keys(execSummary).forEach((mainTable) => {
    Object.keys(execSummary[mainTable]).forEach((subTable) => {
      if (colorArray.length > index + 1) {
        if (subTable === table) colorArray[index] = value;
        else colorArray[index] = execSummary[mainTable][subTable].status;
      } else {
        if (subTable === table) colorArray.push(value);
        else colorArray.push(execSummary[mainTable][subTable].status);
      }
      index++;
    });
  });
  let fc;
  let fcname = "";
  const finalColor = () => {
    let greenFlag = 0;
    let redFlag = 0;
    let amberFlag = 0;
    let ircepFlag = 0;
    let irwipFlag = 0;
    let stopCheckFlag = 0;

    colorArray.forEach((item) => {
      if (item === "Green") greenFlag = 1;
      else if (item === "Red") redFlag = 1;
      else if (item === "Amber") amberFlag = 1;
      else if (item === "IRCEP") ircepFlag = 1;
      else if (item === "IRWIP") irwipFlag = 1;
      else if (item === "Stop Check") stopCheckFlag = 1;
    });

    if (
      ircepFlag == 1 &&
      irwipFlag == 1 &&
      redFlag == 1 &&
      (amberFlag == 1 || amberFlag == 0) //Condition also changed here
    )
      fcname = "IR-Red"; //fcname = "IRCEP-Red-IRWIP-Amber";
    else if (ircepFlag == 1 && irwipFlag == 1 && amberFlag == 1) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span>
            IRWIP<span>-</span>
            <span className="amber">Amber</span>
          </span>
        </span>
      );
      fcname = "IRCEP-IRWIP-Amber";
    } else if (ircepFlag == 1 && amberFlag == 1 && redFlag == 1) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">
            Amber<span style={{ backgroundColor: "white" }}>-</span>
          </span>
          <span className="red">Red</span>
        </span>
      );
      fcname = "IR-Red";
    } else if (irwipFlag == 1 && amberFlag == 1 && redFlag == 1) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">
            Amber<span style={{ backgroundColor: "white" }}>-</span>
          </span>
          <span className="red">Red</span>
        </span>
      );
      fcname = "IR-Red";
    } else if (
      ircepFlag == 1 &&
      amberFlag == 1 &&
      redFlag == 0 &&
      irwipFlag == 0
    ) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">Amber</span>
        </span>
      );
      fcname = "IRCEP-Amber";
    } else if (
      ircepFlag == 1 &&
      amberFlag == 0 &&
      redFlag == 1 &&
      irwipFlag == 0
    ) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="red">Red</span>
        </span>
      );
      fcname = "IR-Red";
    } else if (amberFlag == 1 && redFlag == 1) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="red">Red</span>
        </span>
      );
      fcname = "Red"; //fcname = "Red-Amber";
    }
    // } else if (ircepFlag == 1 && irwipFlag == 1) {
    //   fc = (
    //     <span className="ircep">
    //       IRCEP<span style={{ backgroundColor: "white" }}>-</span>
    //       <span>IRWIP</span>
    //     </span>
    //   );
    //   fcname = "IRCEP-IRWIP";
    // }
    else if (irwipFlag == 1 && ircepFlag == 0 && amberFlag == 1) {
      fc = (
        <span>
          IRWIP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">Amber</span>
        </span>
      );
      fcname = "IRWIP-Amber";
    } else if (irwipFlag == 1 && amberFlag == 1) {
      fc = (
        <span>
          IRWIP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">Amber</span>
        </span>
      );
      fcname = "IRWIP-Amber";
    } else if (irwipFlag == 1 && redFlag == 1) {
      fc = (
        <span>
          IRWIP<span style={{ backgroundColor: "white" }}>-</span>
          <span className="amber">Amber</span>
        </span>
      );
      fcname = "IR-Red";
    } else if (stopCheckFlag == 1) {
      fc = <span className="stopCheck">StopCheck</span>;
      fcname = "Stop Check";
    } else if (ircepFlag == 1 && irwipFlag == 1) {
      fc = (
        <span className="ircep">
          IRCEP<span style={{ backgroundColor: "white" }}>-</span>
          <span>IRWIP</span>
        </span>
      );
      fcname = "IRCEP-IRWIP";
    } else if (stopCheckFlag == 1) {
      fc = <span className="stopCheck">StopCheck</span>;
      fcname = "Stop Check";
    } else if (
      greenFlag == 1 &&
      redFlag == 0 &&
      amberFlag == 0 &&
      ircepFlag == 0 &&
      irwipFlag == 0
    ) {
      fc = <span className="green">Green</span>;
      fcname = "Green";
    } else if (
      redFlag == 1 &&
      (greenFlag == 0 || greenFlag == 1) &&
      (amberFlag == 0 || amberFlag == 1) &&
      ircepFlag == 0 &&
      irwipFlag == 0
    ) {
      fc = <span className="red">Red</span>;
      fcname = "Red";
    } else if (
      amberFlag == 1 &&
      (greenFlag == 0 || greenFlag == 1) &&
      redFlag == 0 &&
      ircepFlag == 0 &&
      irwipFlag == 0
    ) {
      fc = <span className="amber">Amber</span>;
      fcname = "Amber";
    } else if (
      greenFlag == 1 &&
      ircepFlag == 1 &&
      redFlag == 0 &&
      amberFlag == 0 &&
      irwipFlag == 0
    ) {
      fc = <span className="ircep">IRCEP</span>;
      fcname = "IRCEP";
    } else if (
      greenFlag == 1 &&
      ircepFlag == 0 &&
      redFlag == 0 &&
      amberFlag == 0 &&
      irwipFlag == 1
    ) {
      fc = <span className="ircep">IRCEP</span>;
      fcname = "IRWIP";
    }
    dispatch(changeOverallColorCode(fcname));
  };
  finalColor();
  return fcname;
  // <div className="colorCheckOverall">{fc}</div>;
};

export default ColorCheckFun;
