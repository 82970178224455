import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilesToState,
  fillDirectorshipForm,
  editCellInDirectorshipDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const Directorship = ({ annexure }) => {
  const modelId = "directorshipVerificationCellTileEdit";

  const DirectorshipForms = useSelector(
    (state) => state.pdfdata.forms["Directorship Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addFiles = (e) => {
    dispatch(addFilesToState([e.target.name, e.target.files]));
  };
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillDirectorshipForm([table, e.target.name, e.target.value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Directorship Verification";
    dispatch(
      editCellInDirectorshipDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Directorship Verification</u>
      </h3>
      {Object.keys(DirectorshipForms).map((table, index) => (
        <table key={table}>
          {Object.keys(DirectorshipForms[table]).map((row, index) => (
            <tr>
              {row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setSelectedCellTitle(row);
                          // setselectedFormNumber("form1");
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
              )}

              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={DirectorshipForms[table][row]}
                  fun={(e) => {
                    addVal(e, table);
                  }}
                  spanNum={1}
                />
              ) : row === "Upload Document" ? (
                <td style={{ backgroundColor: "white" }}>
                  <input
                    type="file"
                    name={"Directorship Verification"}
                    multiple
                    onChange={(e) => addFiles(e)}
                  />
                  <span className="alert">
                    Files of format .jpg, .jpeg, .png, .eml are accepted!
                  </span>
                </td>
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    defaultValue={DirectorshipForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Directorship Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Directorship Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default Directorship;
