import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(false);
    const [loggedInUserEmail, setLoggedInUserEmail] = useState("");

    return (
        <AuthContext.Provider value={{ auth, setAuth, loggedInUserEmail, setLoggedInUserEmail }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;