import { useDispatch, useSelector } from "react-redux";
import { fillDrugVerificationForm } from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import RecursiveModal from "./RecursiveModal";

const DrugVerification = ({ annexure }) => {
  const drugVerificationForms = useSelector(
    (state) => state.pdfdata.forms["Drug Verification"]
  );
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillDrugVerificationForm([
        "Drug Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  return (
    <>
      <h3 className="text-center mt-4">
        <u>Drug Verification</u>
      </h3>
      {Object.keys(drugVerificationForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Type of Check</th>
            <th className="centered-text">Brief details</th>
            <th className="centered-text">Status</th>
          </tr>
          {Object.keys(drugVerificationForms[table]["form1"]).map((row) => (
            <>
              <tr>
                <td>{row}</td>
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={
                      drugVerificationForms[table]["form1"][row].briefDetails
                    }
                    name="briefDetails"
                    onBlur={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>

                <ColorCode
                  namefield="status"
                  cls={drugVerificationForms[table]["form1"][row].status}
                  fun={(e) => addVal(e, table, "form1", row)}
                  spanNum={1}
                />
              </tr>
            </>
          ))}
          {Object.keys(drugVerificationForms[table]["form2"]).map((row) => (
            <tr>
              <td>{row}</td>
              {
                <td colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={drugVerificationForms[table]["form2"][row]}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              }
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Drug Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Drug Verification"
            namefield={table}
            spanNum={2}
          />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </>
  );
};
export default DrugVerification;
