import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillCompanyCheckForm,
  editCellInCompanyCheckDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import "../styles/pageStyles.css";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import DatePickerReact from "./DatePickerReact";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const CompanyCheckVerification = ({ annexure }) => {
  const modelId = "CompanyCheckCellTileEdit";

  const companyCheckForms = useSelector(
    (state) => state.pdfdata.forms["Company Check Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();

  const addDateVal = (name, value, table) => {
    dispatch(
      fillCompanyCheckForm(["Company Check Verification", table, name, value])
    );
  };
  const addVal = (e, table) => {
    e.preventDefault();
    let value = e.target.value;

    dispatch(
      fillCompanyCheckForm([
        "Company Check Verification",
        table,
        e.target.name,
        value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Company Check Verification";
    dispatch(
      editCellInCompanyCheckDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Company Check Verification</u>
      </h3>
      <>
        {Object.keys(companyCheckForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading" colSpan={2}>
                <h3>{type}</h3>
              </th>
            </tr>
            {Object.keys(companyCheckForms[type]).map((row, index) => (
              <tr>
                {row === "Color Code" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(type);
                            setSelectedCellTitle(row);
                            // setselectedFormNumber("form1");
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Color Code" ? (
                  <ColorCode
                    cls={companyCheckForms[type][row]}
                    namefield={row}
                    fun={(e) => addVal(e, type)}
                    spanNum={1}
                  />
                ) : row === "Company Registration Date" ? (
                  <td>
                    <DatePickerReact
                      val={companyCheckForms[type][row]}
                      inputTagName={row}
                      isSubtable={true}
                      fun={(name, value) => addDateVal(name, value, type)}
                    />
                  </td>
                ) : (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      defaultValue={companyCheckForms[type][row]}
                      name={row}
                      onBlur={(e) => addVal(e, type)}
                    ></textarea>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Company Check Verification"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                />
                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Company Check Verification"
              namefield={type}
              spanNum={1}
            />
            <tr>
              <td colSpan={2}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default CompanyCheckVerification;
