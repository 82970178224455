import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillBankStatementForm,
  editCellInBankStatementDetailForm,
  addCellInSubFormsType1,
  deleteCellInSubForm,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import AddCellModel from "./AddCellModel";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import RecursiveModal from "./RecursiveModalNew";
import SummaryRemarks from "./ExecutiveSummaryNew";
import NotificationAlert from "./NotificationAlert";
import EditCellTitleModel from "./EditCellTitleModel";
import ConfirmationDialog from "./ConfirmationDialog";

const BankStatementVerification = ({ annexure }) => {
  const modelId = "BankStatementCellTileEdit";

  const dispatch = useDispatch();

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Bank Statement Verification"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Name of Applicant"]
  );
  const bankStatementForms = useSelector(
    (state) => state.pdfdatanew.forms["Bank Statement Verification"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType1({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(
      fillBankStatementForm([
        "Bank Statement Verification",
        table,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Bank Statement Verification";
    dispatch(
      editCellInBankStatementDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
  };
  const onDeleteCell = (index, type) => {
    if (index === "" || type === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Bank Statement Verification";
    const subform = type;
    const cellPosition = index;

    dispatch(
      deleteCellInSubForm({
        subform,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (table, component, value) => {
    dispatch(
      fillBankStatementForm([
        "Bank Statement Verification",
        table,
        component,
        value,
      ])
    );
  };

  useEffect(() => {
    Object.keys(bankStatementForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Bank Statement Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(bankStatementForms).map((table, index) => {
      dispatch(
        fillBankStatementForm([
          "Bank Statement Verification",
          table,
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  useEffect(() => {
    Object.keys(bankStatementForms).map((table, index) => {
      dispatch(
        fillBankStatementForm([
          "Bank Statement Verification",
          table,
          "Name of the Applicant",
          applicantName?.value,
        ])
      );
    });
  }, [applicantName]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />

      <h3 className="text-center mt-4">
        <u>Bank Statement Verification</u>
      </h3>

      {Object.keys(bankStatementForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <div className="row row-cols-2">
                <div className="col">
                  <h3>{table}</h3>
                </div>
              </div>
            </th>
          </tr>

          {Object.keys(bankStatementForms[table]).map((row, index) => (
            <tr>
              {row === "Name of the Applicant" || row === "Status" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                    <div className="col-sm-1">
                      {" "}
                      <button
                        className="editIcon fa fa-trash"
                        type="button"
                        title="Delete Cell Title"
                        onClick={(e) => {
                          setConfirmation({
                            isOpen: true,
                            title: "Are you sure to delete this cell?",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              onDeleteCell(index, table);
                            },
                          });
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
              )}

              {row === "Name of the Applicant" ? (
                <textarea
                  style={{ width: "100%", height: 30 }}
                  id={`BankStatementVerification${table}NameOfApplicant`}
                  defaultValue={
                    bankStatementForms[table][row] !== ""
                      ? bankStatementForms[table][row]
                      : applicantName.value
                  }
                  name={row}
                  disabled={true}
                />
              ) : row === "Status" ? (
                <ColorCode
                  namefield={row}
                  cls={bankStatementForms[table][row]}
                  fun={(e) => {
                    addVal(e, table);
                  }}
                  spanNum={1}
                />
              ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                <td key={row + table + index} colSpan={2}>
                  <DatePickerReact
                    inputTagName={row}
                    val={bankStatementForms[table][row]}
                    isSubtable={true}
                    fun={(name, value) => {
                      addDateVal(table, row, value);
                    }}
                  />
                </td>
              ) : (
                <td key={row + table + index} colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    id={table + row + "BANKUpdate"}
                    defaultValue={bankStatementForms[table][row]}
                    disabled={row === "Annexure Details" ? true : false}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}

          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Bank Statement Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          {/* <SummaryRemarks
            type="Bank Statement Verification"
            namefield={table}
            spanNum={1}
          /> */}
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>

          <AddCellModel
            inputFields={bankStatementForms[table]}
            onaddCell={(typecellref, fieldType, position) =>
              addCell(
                typecellref,
                fieldType,
                position,
                "Bank Statement Verification",
                table
              )
            }
            uniqueName={`BankStatementVerification${index}`}
          />
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default BankStatementVerification;
