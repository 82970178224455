import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillGapForm,
  editCellInGapDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const Gap = ({ annexure }) => {
  const modelId = "GapCellTileEdit";

  const gapForms = useSelector(
    (state) => state.pdfdata.forms["Gap Verification"]
  );
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const dispatch = useDispatch();
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);

  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillGapForm([table, e.target.name, e.target.value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(gapForms).forEach((table) => {
      if (gapForms[table]["Name of the Applicant"] === "")
        document.getElementById(`GapVerification${table}`).value =
          applicantName.value;
    });
  }, [applicantName, gapForms]);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Gap Verification";
    dispatch(
      editCellInGapDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Gap Verification</u>
      </h3>

      {Object.keys(gapForms).map((table, index) => (
        <table key={table}>
          {Object.keys(gapForms[table]).map((row, index) => (
            <tr>
              {row === "Name of the Applicant" || row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-11">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setSelectedCellTitle(row);
                          // setselectedFormNumber("form1");
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
              )}
              {row === "Name of the Applicant" ? (
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    id={`GapVerification${table}`}
                    type="text"
                    name={row}
                    defaultValue={
                      gapForms[table][row] !== ""
                        ? gapForms[table][row]
                        : applicantName
                    }
                    onChange={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              ) : row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={gapForms[table][row]}
                  fun={(e) => {
                    addVal(e, table);
                  }}
                  spanNum={1}
                />
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    defaultValue={gapForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Gap Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Gap Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default Gap;
