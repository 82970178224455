import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillCreditForm } from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import RecursiveModal from "./RecursiveModal";

const CreditCheck = ({ annexure }) => {
  const creditForms = useSelector(
    (state) => state.pdfdata.forms["Credit Check"]
  );
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillCreditForm([
        "Credit Check",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  return (
    <>
      <h3 className="text-center mt-4">
        <u>Credit Check</u>
      </h3>
      {Object.keys(creditForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Type of Check</th>
            <th className="centered-text">Brief details</th>
            <th className="centered-text">Status</th>
          </tr>
          {Object.keys(creditForms[table]["form1"]).map((row) => (
            <>
              <tr>
                <td>{row}</td>
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={creditForms[table]["form1"][row].briefDetails}
                    name="briefDetails"
                    onBlur={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>

                <ColorCode
                  namefield="status"
                  cls={creditForms[table]["form1"][row].status}
                  fun={(e) => addVal(e, table, "form1", row)}
                  spanNum={1}
                />
              </tr>
            </>
          ))}
          {Object.keys(creditForms[table]["form2"]).map((row) => (
            <tr>
              <td>{row}</td>
              {
                <td colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={creditForms[table]["form2"][row]}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              }
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Credit Check"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks type="Credit Check" namefield={table} spanNum={2} />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </>
  );
};
export default CreditCheck;
