import React, { useContext, useState, useCallback, useEffect } from "react";
import "./NavigationBar.css";
import {
  FaUserCheck,
  FaBuilding,
  FaFile,
  FaUniversity,
  FaSignOutAlt,
} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";

import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../contexts/AuthProvider";
import axios from "axios";
import { saveAs } from "file-saver";
import { BASE_URL } from "../constants";
import { setError } from "../store/error/errorSlice";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import GeneratePdfModal from "./GeneratePdfModal";
import DownloadTrackerModal from "./DownloadTrackerModal";
import ROCDetailsModal from "./ROCDetailsModal";
import UGCDetailsModal from "./UGCDetailsModal";

function NavigationBar() {
  const { setLoggedInUserEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [companyNameList, setCompanyNameList] = useState([]);
  const [universityNameList, setuniversityNameList] = useState([]);
  const [inputFiles, setinputFiles] = useState([]);
  const [currentForm, setcurrentForm] = useState("");

  const downloadTracker = async () => {
    axios
      .get(`${BASE_URL}tracker/generateTracker`, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res);
        const XlxBlob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(XlxBlob, `Tracker.xlsx`);
      })
      .catch(async (e) => {
        try {
          const msg = JSON.parse(await e.response.data.text()).message;
          //console.log(error.toString());
          if (msg !== undefined) {
            dispatch(setError(["Error in generating tracker!", msg, true]));
          }
        } catch {
          dispatch(
            setError(["Error in generating tracker!", e.toString(), false])
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCompanyNames = async () => {
    axios
      .get(`${BASE_URL}client/companies`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        setCompanyNameList(res.data.data);
      })
      .catch((e) => {
        dispatch(setError(["Error in fetching Company Name List!", e, false]));
      });
  };

  const getUniversityNames = async () => {
    axios
      .get(`${BASE_URL}client/universities`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        setuniversityNameList(res.data.data);
      })
      .catch((e) => {
        dispatch(
          setError(["Error in fetching University Name List!", e, false])
        );
      });
  };

  useEffect(() => {
    getCompanyNames();
    getUniversityNames();
  }, []);

  const companyInitial = {
    CompanyName: "",
    CIN: "",
    RegistrationNumber: "",
    DateOfIncorporation: "",
    attachmentKeysToDelete: [],
    files: [],
    Attachments: [],
  };

  const universityInitial = {
    UniversityName: "",
    attachmentKeysToDelete: [],
    files: [],
    Attachments: [],
  };

  const [companyForm, setcompanyForm] = useState(companyInitial);

  const [university, setuniversity] = useState(universityInitial);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { numeric: true })
    );
    setinputFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFieldChange = (e) => {
    if (e.target.id === "companyForm") {
      setcompanyForm({ ...companyForm, [e.target.name]: e.target.value });
    } else if (e.target.id === "university") {
      setuniversity({ ...university, [e.target.name]: e.target.value });
    }
  };

  const handleSearch = async (e) => {
    if (e.target.name === "company") {
      setcompanyForm(companyInitial);
      if (companyNameList.includes(e.target.value)) {
        await axios({
          url: `${BASE_URL}client/company/${encodeURIComponent(
            e.target.value
          )}`,
          method: "GET",
          responseType: "json",
          withCredentials: true,
        })
          .then((res) => {
            setcompanyForm({ ...companyInitial, ...res.data.data });
            console.log("Response Data JSON", res);
          })
          .catch((error) => {
            dispatch(setError(["Error in Company info!", error, false]));
          });
      } else if (e.target.value === "") {
        setcompanyForm(companyInitial);
        setinputFiles([]);
      } else if (
        !companyNameList.includes(e.target.value) &&
        e.target.value !== ""
      ) {
        window.alert("Please enter valid company name!");
      }
    } else if (e.target.name === "university") {
      setuniversity(universityInitial);
      if (universityNameList.includes(e.target.value)) {
        await axios({
          url: `${BASE_URL}client/university/${e.target.value}`,
          method: "GET",
          responseType: "json",
          withCredentials: true,
        })
          .then((res) => {
            setuniversity({ ...universityInitial, ...res.data.data });
            console.log(res);
          })
          .catch((error) => {
            dispatch(
              setError(["Error in fetching University info!", error, false])
            );
          });
      } else if (e.target.value === "") {
        setuniversity(universityInitial);
        setinputFiles([]);
      } else if (
        !universityNameList.includes(e.target.value) &&
        e.target.value !== ""
      ) {
        window.alert("Please enter valid University name!");
      }
    }
  };

  const handleCompanySubmit = async () => {
    const form = { ...companyForm, file: inputFiles };
    console.log("Image Array", inputFiles);
    var formData = new FormData();
    formData.append("CompanyName", form.CompanyName);
    formData.append("data", JSON.stringify(form));
    inputFiles.forEach((file) => {
      formData.append("file", file);
    });

    console.log(form);
    await axios({
      method: "POST",
      url: `${BASE_URL}client/company/saveOrUpdate`,
      data: formData,
      withCredentials: true,
    })
      .then((res) => {
        window.alert("Your Form Submitted Successfully!");
      })
      .catch((error) => {
        dispatch(setError(["Error in form submission!", error, false]));
      })
      .finally(() => {
        getCompanyNames();
      });
  };

  const handleUniversitySubmit = async (e) => {
    const form = { ...university, file: inputFiles };
    console.log(form);
    var formData = new FormData();
    formData.append("UniversityName", form.UniversityName);
    formData.append("data", JSON.stringify(form));
    inputFiles.forEach((file) => {
      formData.append("file", file);
    });

    await axios({
      method: "POST",
      url: `${BASE_URL}client/university/saveOrUpdate`,
      data: formData,
      withCredentials: true,
    })
      .then((res) => {
        window.alert("Your Form Submitted Successfully!");
      })
      .catch((error) => {
        dispatch(setError(["Error in form submission!", error, false]));
      })
      .finally(() => {
        getUniversityNames();
      });
  };

  const handleLogout = async () => {
    axios
      .get(`${BASE_URL}auth/logout`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        navigate("/");
        //setAuth(false);
        setLoggedInUserEmail("");
        return response.status;
      })
      .catch((e) => {
        dispatch(setError(["Error in logging Out!", e, false]));
      });
  };

  return (
    <>
      <div className="sidenav">
        <div className="sidenav-logo">
          <center>
            <img
              width="140px"
              height="50px"
              src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
              alt="Infomate360"
            />
          </center>
        </div>
        <ul className="links">
          <Link to="/dashboard" className="link">
            <MdDashboard className="icons" />
            <span>Dashboard</span>
          </Link>
          <Link to="/applicant" className="link">
            <FaUserCheck className="icons" />
            <span>Report Writing</span>
          </Link>
          <Link to="/techmreport" className="link">
            <FaUserCheck className="icons" />
            <span>Tech M Report Writing</span>
          </Link>
          <Link
            className="link"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            data-bs-whatever="@mdo"
          >
            <FaFile className="icons" />
            <span>Generate PDF</span>
          </Link>
          <Link
            className="link"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropTracker"
            data-bs-whatever="@mdo"
            onClick={() => {
              setLoading(true);
              downloadTracker();
            }}
          >
            <BsFileEarmarkSpreadsheetFill className="icons" />
            <span>Download Tracker</span>
          </Link>
          <Link to="/client-info" className="link">
            <HiUserGroup className="icons" />
            <span>Client Specific</span>
          </Link>
          <Link
            className="link"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#company"
            data-bs-backdrop="static"
          >
            <FaBuilding className="icons" />
            <span>ROC Detail</span>
          </Link>
          <Link
            className="link"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#UGC"
            data-bs-backdrop="static"
          >
            <FaUniversity className="icons" />
            <span>UGC Detail</span>
          </Link>

          <div className="divider"></div>

          <Link to="" onClick={handleLogout} className="link">
            <FaSignOutAlt className="icons" />
            <span>Logout</span>
          </Link>
        </ul>
      </div>

      <GeneratePdfModal />
      <DownloadTrackerModal loading={loading} modalId="staticBackdropTracker" />
      <ROCDetailsModal
        companyNameList={companyNameList}
        inputFiles={inputFiles}
        companyForm={companyForm}
        setcompanyForm={setcompanyForm}
        isDragActive={isDragActive}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        handleSearch={handleSearch}
        handleFieldChange={handleFieldChange}
        handleCompanySubmit={handleCompanySubmit}
        companyInitial={companyInitial}
        setcurrentForm={setcurrentForm}
        setinputFiles={setinputFiles}
      />
      <UGCDetailsModal
        universityNameList={universityNameList}
        inputFiles={inputFiles}
        university={university}
        setuniversity={setuniversity}
        isDragActive={isDragActive}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        handleSearch={handleSearch}
        handleFieldChange={handleFieldChange}
        handleUniversitySubmit={handleUniversitySubmit}
        universityInitial={universityInitial}
        setcurrentForm={setcurrentForm}
        setinputFiles={setinputFiles}
      />
    </>
  );
}

export default NavigationBar;
