import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillFranchiseVerificationForm,
  editCellInFranchiseDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import "../styles/pageStyles.css";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const FranchiseVerification = ({ annexure }) => {
  const modelId = "FranchiseCellTileEdit";

  const franchiseVerificationForms = useSelector(
    (state) => state.pdfdata.forms["Franchise Verification"]
  );
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const dob = useSelector((state) => state.pdfdata["Date of Birth"]);
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const FatherName = useSelector((state) => state.pdfdata["Father Name"]);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();
  const addVal = (e, table) => {
    e.preventDefault();
    let value = e.target.value;
    dispatch(
      fillFranchiseVerificationForm([
        "Franchise Verification",
        table,
        e.target.name,
        value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(franchiseVerificationForms).forEach((table) => {
      if (franchiseVerificationForms[table]["Name of the Applicant"] === "") {
        document.getElementById(
          `FranchiseVerification${table}Applicant`
        ).value = applicantName.value;
      }
      if (
        franchiseVerificationForms[table]["Father Name"] === "" &&
        FatherName
      ) {
        document.getElementById(
          `FranchiseVerification${table}FatherName`
        ).value = FatherName.value;
      }
    });
  }, [applicantName, FatherName, franchiseVerificationForms]);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Franchise Verification";
    dispatch(
      editCellInFranchiseDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Franchise Verification</u>
      </h3>
      <>
        {Object.keys(franchiseVerificationForms).map((type, index) => (
          <table key={type}>
            <tr>
              <th className="heading" colSpan={2}>
                <h3>{type}</h3>
              </th>
            </tr>
            {Object.keys(franchiseVerificationForms[type]).map((row, index) => (
              <tr>
                <td className="cellTitle col-sm-6">
                  {row === "Name of the Applicant" ||
                  row === "Color Code" ||
                  row === "Date of Birth" ? (
                    <>{row}</>
                  ) : (
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(type);
                            setSelectedCellTitle(row);
                            // setselectedFormNumber("form1");
                          }}
                        ></button>
                      </div>
                    </div>
                  )}
                </td>
                {row === "Color Code" ? (
                  <ColorCode
                    cls={franchiseVerificationForms[type][row]}
                    namefield={row}
                    fun={(e) => addVal(e, type)}
                    spanNum={1}
                  />
                ) : row === "Date of Birth" && dob ? (
                  <td>{dob.value}</td>
                ) : (
                  <td>
                    {row === "Name of the Applicant" ? (
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        id={`FranchiseVerification${type}Applicant`}
                        defaultValue={
                          franchiseVerificationForms[type][row] !== ""
                            ? franchiseVerificationForms[type][row]
                            : applicantName
                        }
                        name={row}
                        onChange={(e) => addVal(e, type)}
                      ></textarea>
                    ) : row === "Father Name" && FatherName ? (
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        id={`FranchiseVerification${type}FatherName`}
                        defaultValue={
                          franchiseVerificationForms[type][row] !== ""
                            ? franchiseVerificationForms[type][row]
                            : FatherName
                        }
                        name={row}
                        onChange={(e) => addVal(e, type)}
                      ></textarea>
                    ) : (
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        defaultValue={franchiseVerificationForms[type][row]}
                        name={row}
                        onBlur={(e) => addVal(e, type)}
                      ></textarea>
                    )}
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Franchise Verification"
                  subTable={type}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="Franchise Verification"
              namefield={type}
              spanNum={1}
            />
            <tr>
              <td colSpan={2}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default FranchiseVerification;
