import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FaUserCheck, FaBuilding, FaUniversity } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import DataTable from "react-data-table-component";

import { Link } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  changeRefreshLoadingState,
  fetchRecordsInfo,
} from "../store/records/recordSlice";
import { Button } from "@mui/material";

const Dashboard = () => {
  const recordInfo = useSelector((state) => state.records);
  const dataOfHomeDataTable = recordInfo.listOfRecordsCreatedToday;
  const numberOfReportsToday = recordInfo.numberOfRecordsCreatedToday;
  const numberOfReportsTillNow = recordInfo.numberOfRecordsTillDate;
  const lastRefreshedAt = recordInfo.lastRefreshedAt;
  const isFirstFetch = recordInfo.isFirstFetch;
  const isRefreshLoading = recordInfo.isRefreshLoading;

  const dispatch = useDispatch();
  console.log(lastRefreshedAt);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to reload? All provided data will be lost and you will be logged out.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    if (isFirstFetch) {
      dispatch(fetchRecordsInfo(dispatch));
    }
  }, []);

  const columns = [
    {
      name: "Infomate360 Ref. No.",
      cell: (row) => (
        <Link
          to={"/applicant"}
          state={{ infmNumber: row.infomate360ReferenceNumber }}
        >
          {row.infomate360ReferenceNumber}
        </Link>
      ),
    },
    {
      name: "Applicant Name",
      selector: (row) => row["Applicant Name"].value,
    },
    {
      name: "Client Specific",
      selector: (row) => row["Client Specific"].value,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {/* <button className={`btn btn-sm ${row["Color Code"].value}`}>
            {row["Color Code"].value}
          </button> */}
          {row["Color Code"].value === "IR-Red" ? (
            <div className="btn btn-sm Red">{row["Color Code"].value}</div>
          ) : row["Color Code"].value.indexOf("-") === -1 ? (
            <div className={["btn btn-sm", row["Color Code"].value].join(" ")}>
              {row["Color Code"].value}
            </div>
          ) : (
            <div className="">
              {row["Color Code"].value.split("-").map((item, index) => (
                <>
                  {index !== 0 ? (
                    <></>
                  ) : (
                    <div className={["btn btn-sm", item].join(" ")}>
                      {row["Color Code"].value}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationBar />
      <div className="main">
        <div className="container-fluid">
          <Header heading={"Dashboard"} />
          <div className="row mt-3 me-1 ms-1">
            <div className="col-4">
              <Link to="/applicant">
                <div className="card shadow h-100 py-1 card-purple">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mr-2">
                        <div className="card-heading">
                          Applicant Verification Page
                        </div>
                        <div className="card-description">
                          Create and Update Verification infomation for the
                          applicant.
                        </div>
                      </div>
                      <div className="col-auto card-icons">
                        <FaUserCheck />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/client-info">
                <div className="card shadow h-100 py-1 card-blue">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mr-2">
                        <div className="card-heading">Client Specific Page</div>
                        <div className="card-description">
                          Create and Update Client Specific details.
                        </div>
                      </div>
                      <div className="col-auto card-icons">
                        <HiUserGroup />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <div className="card shadow h-100 py-1 card-black">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="card-heading">
                        Total Number of Reports Till Now
                      </div>
                    </div>
                    <div className="col-auto">
                      <h1 className="card-heading button-number">
                        {!isFirstFetch ? (
                          numberOfReportsTillNow
                        ) : (
                          <div className="spinner-border" role="status"></div>
                        )}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 me-1 ms-1">
            <div className="col-4">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#company"
                data-bs-backdrop="static"
              >
                <div className="card shadow h-100 py-1 card-orange">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mr-2">
                        <div className="card-heading">ROC Detail Form</div>
                        <div className="card-description">
                          Create and Update ROC details for a company.
                        </div>
                      </div>
                      <div className="col-auto card-icons">
                        <FaBuilding />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#UGC"
                data-bs-backdrop="static"
              >
                <div className="card shadow h-100 py-1 card-green">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mr-2">
                        <div className="card-heading">UGC Detail Form </div>
                        <div className="card-description">
                          Create and Update UGC details for a university.
                        </div>
                      </div>
                      <div className="col-auto card-icons">
                        <FaUniversity />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-4">
              <div className="card shadow h-100 py-1 card-black">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="card-heading">
                        Number of Reports Created Today
                      </div>
                    </div>
                    <div className="col-auto">
                      <h1 className="card-heading button-number">
                        {isFirstFetch ? (
                          <div className="spinner-border" role="status"></div>
                        ) : (
                          numberOfReportsToday
                        )}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 me-1 ms-1">
            <div className="col">
              <div className="card-heading">Today's Summary</div>
            </div>
            <div className="col d-flex justify-content-center align-items-center">
              {!isRefreshLoading ? (
                <Button
                  sx={{ padding: "0" }}
                  variant="text"
                  onClick={() => {
                    dispatch(changeRefreshLoadingState(true));
                    dispatch(fetchRecordsInfo(dispatch));
                  }}
                >
                  <MdRefresh size="20px" />
                </Button>
              ) : (
                <Button sx={{ padding: "0" }} variant="text">
                  <div class="spinner-border spinner-border-sm" role="status" />
                </Button>
              )}
              Last updated at :{" "}
              {lastRefreshedAt ? <>{lastRefreshedAt}</> : <>Loading...</>}
            </div>
            <div className="col d-flex justify-content-end align-items-center">
              <div className="card-description">
                *This table shows the number of reports created today.
              </div>
            </div>
          </div>
          <hr className="header-divider" />
          {isFirstFetch ? (
            <>
              <div className="d-flex justify-content-center mt-5">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </div>
            </>
          ) : !isFirstFetch && numberOfReportsToday === 0 ? (
            <div className="d-flex justify-content-center mt-5">
              <button
                className="button-submit button-submit-hover"
                type="button"
                disabled
              >
                <>There is no report created today!</>
              </button>
            </div>
          ) : (
            <div className="row me-3 ms-1">
              <DataTable
                columns={columns}
                data={dataOfHomeDataTable}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="240px"
                highlightOnHover
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
