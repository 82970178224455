import React, { useState, useCallback } from "react";
import * as PDFJS from "pdfjs-dist";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../constants";
import uniqueString from "unique-string";
import {
  addFilesToState,
  addImagePropsIsChecked,
  addImagePropsTitle,
  editAttachmentTitle,
  deleteImageFromDb,
  removeImageFromAttachments,
  removeImagesUsingTitle,
} from "../store/pdfdata/pdfdataSlice";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { setError } from "../store/error/errorSlice";
import { HiPencil } from "react-icons/hi";
import NotificationAlert from "./NotificationAlert";
const RecursiveModal = ({ mainTable, subTable, annexure }) => {
  PDFJS.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js";
  const attachmentList = useSelector((state) => state.pdfdata.imgStruc);
  const images = useSelector((state) => state.pdfdata.attachments);
  const imageFilesStoreArray = useSelector((state) => state.pdfdata.files);
  const dispatch = useDispatch();
  const [currentKey, setCurrentKey] = useState("");
  const [currentKeyString, setCurrentKeyString] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [inputFiles, setinputFiles] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isAddImageTop, setisAddImageTop] = useState(false); // boolean state if adding new images/emls/document under an existing attachment title.
  const [fileState, setfileState] = useState(imageFilesStoreArray); // for keeping track when files are uploaded/deleted.
  const [isEditTitle, setisEditTitle] = useState(false); // boolean state if editing attachment title.
  const [currentAttachmentTitle, setcurrentAttachmentTitle] = useState(""); // state for storing currently selected attachment title(*used when editing existing attachment title).
  const [currentAttachmentCheck, setcurrentAttachmentCheck] = useState(false); // boolean state  for storing currently selected attachment title's annexure status (*used when editing existing attachment title)
  const [fileMap, setFileMap] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    acceptedFiles.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { numeric: true })
    );
    console.log(acceptedFiles);
    setinputFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const readFileData = (pdfFileResponse) => {
    return new Promise((resolve, reject) => {
      //const blobFile = new Blob([pdfFile], { type: "application/pdf" });

      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(pdfFileResponse);
    });
  };
  const pdfToImgUtil = async (pdfFileResponse) => {
    const images = [];
    const data = await readFileData(pdfFileResponse);
    console.log(data);
    const pdf = await PDFJS.getDocument(data).promise;

    for (let i = 0; i < pdf.numPages; i++) {
      const canvas = document.createElement("canvas");
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 2 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
      canvas.remove();
    }

    return images;
  };
  const convertPdfToImages = async (name, pdfFileResponse) => {
    const images = await pdfToImgUtil(pdfFileResponse);
    return images;
  };
  const dataURLtoFile = (dataUrl, fileName) => {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };
  const getRequest = async (name, eml) => {
    let form = new FormData();

    // const dataObj ={
    //     file:eml
    // }
    form.append("data", eml);
    console.log(form);
    return axios({
      method: "post",
      url: `${BASE_URL}eml/convert`,
      data: form,
      responseType: "blob",
      withCredentials: true,
    })
      .then(async (res) => {
        const images = await convertPdfToImages(name, res.data);
        let finalImgArray = [];
        images.forEach((item, index) => {
          finalImgArray.push(dataURLtoFile(item, `Img-${100 + index}`));
        });
        return finalImgArray;
      })
      .catch(async (e) => {
        try {
          const msg = JSON.parse(await e.response.data.text()).message;
          //console.log(error.toString());
          if (msg !== undefined) {
            dispatch(setError(["Error in generating pdf!", msg, true]));
          }
        } catch {
          dispatch(setError(["Error in generating pdf!", e.toString(), false]));
        }
      });
  };
  const addFiles = async (name, files) => {
    setisLoading(true);
    let uploadFiles = [];
    let isEml = false;
    let isInvalid = false;
    for (const file of files) {
      const supportedFileTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "message/rfc822",
      ];
      const fileType = file.type;
      // if (
      //   !supportedFileTypes.includes(fileType) ||
      //   ip[i].size / 1024 / 1024 >= 12
      // )
      if (!supportedFileTypes.includes(fileType)) {
        isInvalid = true;
        setNotify({
          isOpen: true,
          message: `File named ${file.name} is invalid`,
          type: "error",
        });
      }
      if (fileType === "message/rfc822") {
        isEml = true;
        const emlImages = await getRequest(name, file);
        uploadFiles.push(...emlImages);
      }
      if (fileType !== "message/rfc822" && isInvalid === false) {
        uploadFiles.push(file);
      }
    }
    if (isInvalid === false && !isEml) {
      setNotify({
        isOpen: true,
        message: "File(s) saved successfully!",
        type: "success",
      });
    }
    const renameImageArray = uploadFiles.map((file, index) => {
      let blob = file.slice(0, file.size, "image/png");
      let newFile = new File([blob], `Image-${index}.png`, {
        type: "image/png",
      });
      return newFile;
    });

    const finalImgObj = { ...renameImageArray };

    dispatch(addFilesToState([name, finalImgObj]));
    setisLoading(false);
  };
  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <div className="float-end">
        <button
          type="button"
          className="btn btn-sm btn-outline-dark"
          data-bs-toggle="modal"
          data-bs-target={"#UploadModal" + annexure}
          data-bs-whatever="@mdo"
        >
          <b
            style={{
              color: "#32cd32",
              fontWeight: "900",
              fontSize: "18px",
            }}
          >
            Upload Documents
          </b>
        </button>
      </div>
      <div
        className="modal fade"
        onBlur={() => {
          setinputFiles([]);
          if (!isAddImageTop)
            document.getElementById(`title${currentKey}`).value = "";
        }}
        id={"UploadModal" + annexure}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg dialog-of-modal" role="document">
          <div className="modal-content content-modal">
            <div className="modal-header">
              <h4 className="modal-title title-modal" id="companyLabel">
                Documents for {subTable}
              </h4>
            </div>
            <hr />
            <div className="modal-body body-modal">
              <form>
                <div>
                  <h3 className="centered-text">
                    {attachmentList[mainTable] !== undefined &&
                    attachmentList[mainTable][subTable] !== undefined
                      ? "Uploaded Documents"
                      : "Upload Documents"}
                  </h3>

                  <div>
                    {attachmentList[mainTable] !== undefined &&
                    attachmentList[mainTable][subTable] !== undefined &&
                    Object.keys(attachmentList[mainTable][subTable]).length !==
                      0 ? (
                      <table
                        style={{
                          background: "white",
                          overflow: "hidden",
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                background: "#00A6E8",
                                fontSize: "16px",
                              }}
                              className="centered-text "
                            >
                              Title
                            </th>
                            <th
                              style={{
                                background: "#00A6E8",
                                fontSize: "16px",
                              }}
                              className="centered-text "
                            >
                              Images
                            </th>
                            <th
                              style={{
                                // height: "60px",
                                borderLeftColor: "#FFED86",
                              }}
                            ></th>
                            <button
                              type="button"
                              className="m-1 button-close button-close-hover"
                              data-bs-target={`#Attachment${annexure}`}
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              data-bs-whatever="@mdo"
                              onClick={(e) => {
                                e.preventDefault();
                                setisAddImageTop(false);
                                setisEditTitle(false);
                                setcurrentAttachmentTitle("");
                                setcurrentAttachmentCheck(false);
                                setCurrentKey(uniqueString());
                                setCurrentKeyString("");
                                setinputFiles([""]);
                              }}
                            >
                              +
                            </button>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(attachmentList[mainTable][subTable]).map(
                            (key) => (
                              // images[key] !== undefined &&
                              <>
                                <tr>
                                  <td
                                    style={{
                                      width: "50%",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      style={{
                                        // marginTop:"0%",
                                        display: "grid",
                                        alignContent: "end",
                                        gridTemplateColumns: "auto auto auto",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "6rem",
                                        }}
                                      >
                                        {attachmentList[mainTable][subTable][
                                          key
                                        ].isChecked
                                          ? `Annexure ${annexure} `
                                          : ``}
                                        {
                                          attachmentList[mainTable][subTable][
                                            key
                                          ].title
                                        }
                                      </div>

                                      <div>
                                        <button
                                          className="me-1 button-edit button-edit-hover"
                                          type="button"
                                          data-bs-target={`#Attachment${annexure}`}
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                          data-bs-whatever="@mdo"
                                          onClick={() => {
                                            setisAddImageTop(false);
                                            setisEditTitle(true);

                                            setCurrentKey(key);
                                            setcurrentAttachmentTitle(
                                              attachmentList[mainTable][
                                                subTable
                                              ][key].title
                                            );
                                            setcurrentAttachmentCheck(
                                              attachmentList[mainTable][
                                                subTable
                                              ][key].isChecked
                                            );
                                          }}
                                        >
                                          <HiPencil />
                                        </button>
                                        <button
                                          type="button"
                                          className="button-cross button-cross-hover"
                                          onClick={(e) => {
                                            if (
                                              window.confirm("Are you sure?")
                                            ) {
                                              dispatch(
                                                removeImagesUsingTitle({
                                                  mainTable,
                                                  subTable,
                                                  key,
                                                })
                                              );
                                              images[key].map((path) => {
                                                dispatch(
                                                  deleteImageFromDb(path)
                                                );
                                              });
                                            }
                                          }}
                                        >
                                          X
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      width: "50%",
                                      borderRightColor: "white",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm mt-1 button-submit button-submit-hover"
                                      data-bs-target={`#Attachment${annexure}`}
                                      data-bs-toggle="modal"
                                      data-bs-dismiss="modal"
                                      data-bs-whatever="@mdo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setisEditTitle(false);
                                        setcurrentAttachmentTitle("");
                                        setcurrentAttachmentCheck(false);
                                        setisAddImageTop(true);
                                        setCurrentKey(key);
                                        setCurrentKeyString(key);
                                      }}
                                    >
                                      Add new doc.
                                    </button>
                                    {fileMap[key] !== undefined &&
                                    fileMap[key] ? (
                                      <>
                                        <span>
                                          New file(s) added or deleted. New
                                          preview would be available after
                                          submit
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {console.log(
                                          fileState[key],
                                          imageFilesStoreArray[key]
                                        )}
                                      </>
                                    )}

                                    <div>
                                      {images[key] !== undefined ? (
                                        images[key].map((path, index) => (
                                          <div style={{ margin: "5px" }}>
                                            <span>
                                              <a
                                                href={`${BASE_URL}form/image/${path}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  style={{
                                                    maxWidth: "30px",
                                                    height: "30px",
                                                    border: "1px solid black",
                                                  }}
                                                  src={`${BASE_URL}form/image/${path}`}
                                                  alt={key}
                                                ></img>
                                              </a>
                                            </span>
                                            <span className="float-end">
                                              <button
                                                type="button"
                                                className=" button-cross button-cross-hover"
                                                onClick={(e) => {
                                                  if (
                                                    window.confirm(
                                                      "Are you sure?"
                                                    )
                                                  ) {
                                                    dispatch(
                                                      removeImageFromAttachments(
                                                        {
                                                          mainTable,
                                                          subTable,
                                                          key,
                                                          index,
                                                        }
                                                      )
                                                    );
                                                    dispatch(
                                                      deleteImageFromDb(path)
                                                    );
                                                  }
                                                }}
                                              >
                                                <b style={{ color: "red" }}>
                                                  X
                                                </b>
                                              </button>
                                            </span>
                                          </div>
                                        ))
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "white",
                                    }}
                                  ></td>
                                  <td>
                                    <button
                                      type="button"
                                      className=" button-plus button-plus-hover"
                                      data-bs-target={`#Attachment${annexure}`}
                                      data-bs-toggle="modal"
                                      data-bs-dismiss="modal"
                                      data-bs-whatever="@mdo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setisAddImageTop(false);
                                        setisEditTitle(false);
                                        setcurrentAttachmentTitle("");
                                        setcurrentAttachmentCheck(false);
                                        setCurrentKey(uniqueString());
                                        setCurrentKeyString(key);
                                      }}
                                    >
                                      +
                                    </button>
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <div className="centered-text">
                        No attachments uploaded yet! Click to add some!
                        <button
                          type="button"
                          className="button-plus button-plus-hover float-end"
                          data-bs-target={`#Attachment${annexure}`}
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          data-bs-whatever="@mdo"
                          onClick={(e) => {
                            e.preventDefault();
                            setisEditTitle(false);
                            setcurrentAttachmentTitle("");
                            setcurrentAttachmentCheck(false);
                            setisAddImageTop(false);
                            setCurrentKey(uniqueString());
                            setCurrentKeyString("");
                            setinputFiles([""]);
                          }}
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div></div>
              </form>
            </div>
            <hr />

            <div className="modal-footer">
              <button
                type="button"
                className="button-close button-close-hover"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={`Attachment${annexure}`}
        aria-hidden="true"
        aria-labelledby={`AttachmentLabel2${annexure}`}
        tabIndex="-1"
      >
        <div className="modal-dialog dialog-of-modal">
          <div className="modal-content content-modal">
            <div className="modal-header">
              <h5
                className="modal-title title-modal"
                id={`AttachmentLabel2${annexure}`}
              >
                {isEditTitle
                  ? `Edit Title`
                  : isAddImageTop
                  ? `Add new documents  ${
                      currentKeyString !== "" &&
                      attachmentList[mainTable][subTable][currentKeyString] !==
                        undefined &&
                      `in  ${attachmentList[mainTable][subTable][currentKeyString].title}`
                    }`
                  : `Upload Document
                          ${
                            currentKeyString !== "" &&
                            attachmentList[mainTable][subTable][
                              currentKeyString
                            ] !== undefined
                              ? `After ${attachmentList[mainTable][subTable][currentKeyString].title}`
                              : "New Attachment"
                          }`}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  if (!isAddImageTop) {
                    document.getElementById(`title${currentKey}`).value = "";
                    document.getElementById(
                      `flexCheckDefault${currentKey}`
                    ).checked = false;
                  }
                }}
              ></button>
            </div>
            <hr />
            <div className="modal-body body-modal">
              {!isEditTitle && (
                <>
                  <div
                    {...getRootProps()}
                    style={{
                      backgroundColor: "#ECEDEE",
                      borderStyle: "dotted",
                      borderColor: "#6C757D",
                      color: "#979EA2",
                    }}
                  >
                    <input
                      {...getInputProps()}
                      id={currentKey}
                      name={currentKey}
                    />
                    {isDragActive ? (
                      <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                        Drop the files here ...
                      </p>
                    ) : (
                      <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                        {" "}
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    )}
                  </div>
                  <aside>
                    <h3
                      style={{
                        display: `${
                          inputFiles.length === 0 ? "none" : "block"
                        }`,
                      }}
                    >
                      Files
                    </h3>
                    <ul>
                      {inputFiles.map((file, index) => {
                        return (
                          <li key={file.path} style={{ color: "#7087ba" }}>
                            {file.path}
                          </li>
                        );
                      })}
                    </ul>
                  </aside>
                </>
              )}

              {isAddImageTop ? (
                <></>
              ) : (
                <div>
                  <tr>
                    <td
                      style={{
                        border: "none",
                        width: "45%",
                        backgroundColor: "#E9E9ED",
                      }}
                    >
                      <input
                        type="text"
                        id={`title${currentKey}`}
                        className="form-control border border-secondary"
                        value={currentAttachmentTitle}
                        placeholder="Attachment Title"
                        onChange={(e) =>
                          setcurrentAttachmentTitle(e.target.value)
                        }
                      ></input>
                    </td>

                    <td
                      style={{
                        border: "none",
                        //width: "32%",
                        backgroundColor: "#E9E9ED",
                      }}
                    >
                      <span
                        className="form-check"
                        style={{
                          marginLeft: "4%",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={currentAttachmentCheck}
                          onClick={(e) => {
                            setcurrentAttachmentCheck(!currentAttachmentCheck);
                            console.log(currentAttachmentCheck);
                          }}
                          id={"flexCheckDefault" + currentKey}
                        />
                        <label
                          className="form-check-label"
                          for={"flexCheckDefault" + currentKey}
                        >
                          Add Annexure Title?
                        </label>
                      </span>
                    </td>
                  </tr>
                </div>
              )}
            </div>
            <hr />
            <div className="btnRow">
              {isLoading && (
                <p className="btnRow text-center" style={{ color: "Red" }}>
                  Eml is getting converted to images! Please Wait!
                </p>
              )}
            </div>
            <div className="modal-footer justify-content-around">
              <button
                className="btn-modal button-close button-close-hover"
                onClick={(e) => {
                  e.preventDefault();
                  if (isEditTitle) {
                    if (
                      document
                        .getElementById(`title${currentKey}`)
                        .value.trim() === ""
                    ) {
                      setNotify({
                        isOpen: true,
                        message: "Attachment Title cannot be blank!",
                        type: "warning",
                      });
                    } else {
                      dispatch(
                        editAttachmentTitle([
                          mainTable,
                          subTable,
                          currentKey,
                          document.getElementById(`title${currentKey}`).value,
                          document.getElementById(
                            `flexCheckDefault${currentKey}`
                          ).checked,
                        ])
                      );
                      setNotify({
                        isOpen: true,
                        message: "Attachment title edited successfully!",
                        type: "success",
                      });
                    }
                  } else {
                    const file = inputFiles;
                    if (file.length === 0) {
                      setNotify({
                        isOpen: true,
                        message: "No files choosen! Record would be discarded!",
                        type: "warning",
                      });
                    } else if (isAddImageTop) {
                      addFiles(currentKey, file);
                    } else if (
                      document
                        .getElementById(`title${currentKey}`)
                        .value.trim() === ""
                    ) {
                      setNotify({
                        isOpen: true,
                        message:
                          "Attachment Title cannot be blank! Record would be discarded!",
                        type: "warning",
                      });
                    } else {
                      dispatch(
                        addImagePropsTitle([
                          currentKeyString,
                          mainTable,
                          subTable,
                          currentKey,
                          document.getElementById(`title${currentKey}`).value,
                          annexure,
                        ])
                      );
                      dispatch(
                        addImagePropsIsChecked([
                          currentKeyString,
                          mainTable,
                          subTable,
                          currentKey,
                          document.getElementById(
                            `flexCheckDefault${currentKey}`
                          ).checked,
                          annexure,
                        ])
                      );
                      addFiles(currentKey, file);
                    }
                  }
                  setFileMap({ ...fileMap, [currentKey]: true });
                }}
              >
                Save
              </button>
              {isLoading && (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <button
                className="btn-modal button-close button-close-hover"
                data-bs-target={"#UploadModal" + annexure}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isAddImageTop) {
                    document.getElementById(`title${currentKey}`).value = "";
                    document.getElementById(
                      `flexCheckDefault${currentKey}`
                    ).checked = false;
                  }

                  setinputFiles([]);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecursiveModal;
