import "./AppApplicant.css";
import PersonalInformation from "./components/PersonalInformation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import NavigationBar from "./components/NavigationBar";
import { BASE_URL } from "./constants";
import {
  fetchDataByRefNum,
  makeStateBlank,
} from "./store/pdfdata/pdfdataSlice";
import PreviewPdfModal from "./components/PreviewPdfModal";
import { setError } from "./store/error/errorSlice";
import { useLocation } from "react-router-dom";
import { setInfmRefNumNotFilled } from "./store/flags/flagSlice";
import NotificationAlert from "./components/NotificationAlert";
import ConfirmationDialog from "./components/ConfirmationDialog";

function AppApplicant() {
  //const {infmNumber} = useLocation().state;
  const infmNumberValue = useLocation().state;
  const infmNumber =
    infmNumberValue && infmNumberValue.infmNumber
      ? infmNumberValue.infmNumber
      : "";
  //console.log("infmNumber", infmNumber);

  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mydata = useSelector((state) => state.pdfdata);
  const InfmRefNumNotFilled = useSelector(
    (state) => state.flags.IsInfmRefNumNotFilled
  );
  const fileStruc = mydata.files;
  const colorCodeArray = [];
  const [previewLoader, setPreviewLoader] = useState(false); // This state is to show the spinner while hitting the endpoint for previewing pdf.
  const [previewModalFlag, setPreviewModalFlag] = useState(false); // This state is to avoid unnecessary calls for loading pdf files even before the preview button is clicked (proxy error).
  const [noPreviewModal, setNoPreviewModal] = useState(false); // This state is to show a separate modal notifying to press the submit button before previewing pdf.
  const [pdfFileUrl, setPdfFileUrl] = useState(""); // This state is to have the pdf file url which is being used as a file path in <Document> tag.
  /*
   * 1.Fetches the form from the backend-server.
   * 2.Returns the form data.
   */
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const fetchForm = async () => {
    return axios
      .get(`${BASE_URL}form/user/${mydata.infomate360ReferenceNumber}`, {
        responseType: "json",
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        dispatch(setInfmRefNumNotFilled(false));
      });
  };

  /*
   * 1.Fetches the form from the backend-server.
   * 2.Removes createdDate, updatedDate from fetched form. Because these fields are not present in local store form data.
   * 3.Compares the two forms and changes the IsSaved Flag.
   */
  const compareForms = async () => {
    if (mydata.infomate360ReferenceNumber !== "") {
      fetchForm()
        .then((res) => {
          console.log(res);
          delete res["createdDate"];
          delete res["updatedDate"];
          const compareObj = { ...mydata };
          delete compareObj["dummyForms"];
          delete compareObj["dummyExecutiveSummary"];
          JSON.stringify(res) === JSON.stringify(compareObj)
            ? setIsSaved(true)
            : setIsSaved(false);
        })
        .catch((e) => {
          // dispatch(
          //   setError([
          //     "Error Fetching Data Using Infomate360 Reference Number!",
          //     e,
          //     false,
          //   ])
          // );
        });
    }
  };

  /*
   * 1.UseEffect on the change of Local Store data.
   * 2. Calls compareForms function.
   */
  useEffect(() => {
    compareForms();
  }, [mydata]);

  const previewPdf = async (e) => {
    if (isSaved) {
      setNoPreviewModal(false);
      setPreviewLoader(true);
      setIsSaved(false);
      console.log("Clicked");
      axios
        .get(`${BASE_URL}pdf/generate/${mydata.infomate360ReferenceNumber}`, {
          responseType: "blob",
          withCredentials: true,
        })
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(pdfBlob); // Build a URL from the file
          setPdfFileUrl(fileURL);
          setPreviewLoader(false);
          setPreviewModalFlag(true);
        })
        .catch(async (e) => {
          setPreviewLoader(false);
          setPreviewModalFlag(true);
          try {
            const msg = JSON.parse(await e.response.data.text()).message;
            //console.log(error.toString());
            if (msg !== undefined) {
              dispatch(setError(["Error in previewing pdf!", msg, true]));
            }
          } catch {
            dispatch(
              setError(["Error in previewing pdf!", e.toString(), false])
            );
          }
        })
        .finally(() => {
          dispatch(makeStateBlank());
          dispatch(fetchDataByRefNum(mydata.infomate360ReferenceNumber));
        });
    } else {
      // window.alert("Please press the Submit button before previewing pdf!");
      setNoPreviewModal(true);
    }
  };

  useEffect(()=>{
    dispatch(makeStateBlank());
  },[])
  const createAndDownloadPdf = async (e) => {
    e.preventDefault();
    if (isSaved) {
      setIsLoading(true);
      setIsSaved(false);
      e.preventDefault();
      console.log("Clicked");
      axios
        .get(`${BASE_URL}pdf/generate/${mydata.infomate360ReferenceNumber}`, {
          responseType: "blob",
          withCredentials: true,
        })
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          const pdfName =
            (mydata["Client Reference No."]?.value === ""
              ? "NA"
              : mydata["Client Reference No."]?.value) +
            "-" +
            (mydata.infomate360ReferenceNumber === ""
              ? "NA"
              : mydata.infomate360ReferenceNumber) +
            "-" +
            (mydata["Applicant Name"]?.value === ""
              ? "NA"
              : mydata["Applicant Name"]?.value) +
            "-" +
            (mydata["Color Code"]?.value === ""
              ? "NA"
              : mydata["Color Code"]?.value) +
            "-Report";
          saveAs(pdfBlob, `${pdfName}.pdf`);
          setIsLoading(false);
        })
        .catch(async (e) => {
          setIsLoading(false);
          try {
            const msg = JSON.parse(await e.response.data.text()).message;
            if (msg !== undefined) {
              dispatch(setError(["Error in generating pdf!", msg, true]));
            }
          } catch {
            dispatch(
              setError(["Error in generating pdf!", e.toString(), false])
            );
          }
        });
      dispatch(makeStateBlank());
      document.getElementById("InfmId").value = "";
      dispatch(setInfmRefNumNotFilled(true));
    } else {
      setNotify({
        isOpen: true,
        message: "Please press the Submit button before generating pdf!",
        type: "warning",
      });
    }
  };
  const saveData = (e) => {
    e.preventDefault();
    if (mydata.infomate360ReferenceNumber === "")
      setNotify({
        isOpen: true,
        message: "Please Enter Infomate360ReferenceNumber",
        type: "warning",
      });
    else {
      console.log("Clicked");
      setIsLoading(true);
      Object.keys(mydata.executiveSummary).map((mainTable) => {
        Object.keys(mydata.executiveSummary[mainTable]).map((subTable) => {
          colorCodeArray.push(
            mydata.executiveSummary[mainTable][subTable].status
          );
        });
      });
      var formData = new FormData();
      formData.append(
        "infomate360ReferenceNumber",
        mydata.infomate360ReferenceNumber
      );
      Object.keys(fileStruc).map((key) => {
        console.log("Check FileStruc", fileStruc[key]);
        console.log("Check FileStruc", fileStruc[key].length);
        if (fileStruc[key].length === undefined) {
          Object.keys(fileStruc[key]).map((k) => {
            formData.append(key, fileStruc[key][k]);
          });
        } else {
          for (let i = 0; i < fileStruc[key].length; i++) {
            // console.log(`File${key}`, fileStruc[key][i]);
            formData.append(key, fileStruc[key][i]);
          }
        }
      });
      let copyMydata = { ...mydata };
      console.log("225", mydata);
      delete copyMydata["dummyForms"];
      delete copyMydata["dummyExecutiveSummary"];
      formData.append("data", JSON.stringify(copyMydata));
      console.log("FormData", formData);
      // console.log(formData);
      setIsSaved(true);
      //dispatch(makeFileStrucEmpty());
      // formData.append(key, JSON.stringify(fileStruc[key]))
      //console.log(JSON.stringify(mydata));
      //Delete the files from state before sending complete json
      //dispatch(deleteFilesFromState);
      axios({
        method: "post",
        url: `${BASE_URL}form/user/saveOrUpdate`,
        data: formData,
        withCredentials: true,
      })
        .then((res) => {
          console.log(res.data);
          // setIsLoading(false);
          const infomate360ReferenceNumber = mydata.infomate360ReferenceNumber;

          setNotify({
            isOpen: true,
            message: "Your Form Submitted Successfully",
            type: "success",
          });
          // const inputFileFields =
          //   document.getElementsByClassName("UploadFileInputTag");
          // for (let i = 0; i < inputFileFields.length; i++) {
          //   inputFileFields[i].value = "";
          // }
          dispatch(makeStateBlank());
          dispatch(fetchDataByRefNum([dispatch, infomate360ReferenceNumber]));
          setIsLoading(false);
          //setIsSaved(true);
        })
        .catch((e) => {
          setIsLoading(false);
          dispatch(setError(["Error in form submission!", e, false]));
        });

      //dispatch(makeStateBlank());
    }
  };

  useEffect(() => {
    if (!isSaved) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isSaved]);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to reload? All provided data will be lost and you will be logged out.";
    e.returnValue = message;
    return message;
  };
  const resetForm = () => {
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    setIsLoading(false);
    dispatch(makeStateBlank());
    dispatch(setInfmRefNumNotFilled(true));
    document.getElementById("InfmId").value = "";
  };
  return (
    <>
      <NavigationBar />
      <div className="main">
        <div className="container-fluid">
          <Header heading={"Report Writing Page"} />

          <form className="m-3">
            {/* <Header /> */}
            <PersonalInformation
              colorCodeArray={colorCodeArray}
              setFlag={setIsSaved}
              infomate360ReferenceNumber={infmNumber}
            />
            {/* <Footer /> */}
          </form>
          <NotificationAlert notify={notify} setNotify={setNotify} />
          <ConfirmationDialog
            confirmation={confirmation}
            setConfirmation={setConfirmation}
          />
          <div className="applicant-footer">
            <div className="apl-foot-ctn">
              <button
                disabled={InfmRefNumNotFilled}
                type="button"
                title="Preview"
                className={
                  InfmRefNumNotFilled
                    ? "button-close-disable"
                    : "button-close button-close-hover"
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropPreviewModal"
                onClick={(e) => {
                  e.preventDefault();
                  previewPdf(e);
                }}
              >
                Preview
              </button>

              <button
                disabled={InfmRefNumNotFilled}
                className={
                  InfmRefNumNotFilled
                    ? "button-close-disable"
                    : "button-close button-close-hover"
                }
                title="Reset The Form Data"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmation({
                    isOpen: true,
                    color: "warning",
                    title:
                      "Do you want to reset the form? Any unsubmitted changes would be lost! Press yes to continue and no to cancel.",
                    subTitle: "You can't undo this operation!",
                    onConfirm: () => {
                      resetForm();
                    },
                  });
                }}
              >
                Reset
              </button>
              <div>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <button
                disabled={InfmRefNumNotFilled}
                className={
                  InfmRefNumNotFilled
                    ? "button-close-disable"
                    : "button-close button-close-hover"
                }
                title="Submit"
                type="button"
                onClick={saveData}
              >
                Submit
              </button>

              <button
                disabled={InfmRefNumNotFilled}
                className={
                  InfmRefNumNotFilled
                    ? "button-close-disable"
                    : "button-close button-close-hover"
                }
                title="Generate PDF"
                type="button"
                onClick={(e) => createAndDownloadPdf(e)}
              >
                Generate Pdf
              </button>
            </div>
          </div>
          <PreviewPdfModal
            previewLoader={previewLoader}
            previewModalFlag={previewModalFlag}
            setPreviewModalFlag={setPreviewModalFlag}
            noPreviewModal={noPreviewModal}
            pdfFileUrl={pdfFileUrl}
          />
        </div>
      </div>
    </>
  );
}

export default AppApplicant;
