import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { BASE_URL } from "../constants";

function ROCDetailsModal(props) {
  const {
    companyNameList,
    inputFiles,
    companyForm,
    setcompanyForm,
    isDragActive,
    getRootProps,
    getInputProps,
    handleSearch,
    handleFieldChange,
    handleCompanySubmit,
    companyInitial,
    setcurrentForm,
    setinputFiles,
  } = props;

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      window.location.reload();
    });
  }, []);


  return (
    <div
      className="modal fade"
      id="company"
      tabindex="-1"
      role="dialog"
      aria-labelledby="companyLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog dialog-of-modal" role="document">
        <div className="modal-content content-modal">
          <div className="modal-header">
            <h5 className="modal-title title-modal" id="companyLabel">
              Company Detail Form
            </h5>
          </div>
          <hr />

          <div className="modal-body body-modal">
            <div className="mb-2">
              <label for="browser" className="form-label float-start">
                Select Company
              </label>
              <input
                className="form-control-sm border border-secondary"
                list="companies"
                name="company"
                id="companySearch"
                onBlur={(e) => {
                  handleSearch(e);
                }}
              />
              <datalist id="companies">
                {companyNameList.map((item) => {
                  return (
                    <option value={item} selected>
                      {item}
                    </option>
                  );
                })}
              </datalist>
            </div>
            <div className="mb-2">
              <label htmlFor="" className="form-label float-start">
                Company Name
              </label>
              <input
                id="companyForm"
                className="form-control-sm border border-secondary"
                type="text"
                name="CompanyName"
                required
                value={companyForm.CompanyName}
                onChange={(e) => handleFieldChange(e)}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="" className="form-label float-start">
                CIN
              </label>
              <input
                id="companyForm"
                className="form-control-sm border border-secondary"
                type="text"
                name="CIN"
                value={companyForm.CIN}
                onChange={(e) => handleFieldChange(e)}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="" className="form-label float-start">
                Date of Incorporation
              </label>
              <DatePicker
                id="companyForm"
                className="form-control-sm border border-secondary"
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  companyForm.DateOfIncorporation !== "" &&
                  companyForm.DateOfIncorporation !== "NA"
                    ? moment(
                        companyForm.DateOfIncorporation,
                        "DD/MM/YYYY"
                      ).toDate()
                    : ""
                }
                onChange={(date) => {
                  console.log(moment.isDate(date));
                  if (moment.isDate(date)) {
                    setcompanyForm({
                      ...companyForm,
                      DateOfIncorporation: moment(date)
                        .format("DD/MM/YYYY")
                        .toString(),
                    });
                  } else {
                    setcompanyForm({
                      ...companyForm,
                      DateOfIncorporation: "",
                    });
                  }
                  // }
                }}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="" className="form-label float-start">
                Registration Number
              </label>
              <input
                id="companyForm"
                className="form-control-sm border border-secondary"
                type="text"
                name="RegistrationNumber"
                value={companyForm.RegistrationNumber}
                onChange={(e) => handleFieldChange(e)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {companyForm.Attachments.map((img, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        marginTop: "2rem",
                        maxWidth: "50%",
                        border: "1px solid grey",
                        display: "block;",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        window.open(e.target.src);
                      }}
                      src={`${BASE_URL}form/image/${img}`}
                      alt={img}
                    />

                    <button
                      type="button"
                      className="button-cross button-cross-hover"
                      style={{
                        marginLeft: "4%",
                      }}
                      onClick={(e) => {
                        let deleteArray = companyForm.attachmentKeysToDelete;
                        deleteArray.push(img);
                        let attachmentsArray = companyForm.Attachments;
                        attachmentsArray = attachmentsArray.filter(function (
                          value,
                          index,
                          arr
                        ) {
                          return value !== img;
                        });
                        setcompanyForm({
                          ...companyForm,
                          attachmentKeysToDelete: deleteArray,
                          Attachments: attachmentsArray,
                        });
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="mb-2">
              <label htmlFor="">
                Upload new Document or Replace existing one
              </label>
              <div
                {...getRootProps()}
                style={{
                  backgroundColor: "#ECEDEE",
                  borderStyle: "dotted",
                  borderColor: "#6C757D",
                  color: "#979EA2",
                }}
              >
                <input
                  {...getInputProps()}
                  id="companyDoc"
                  name="companyForm"
                />
                {isDragActive ? (
                  <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                    Drop the files here ...
                  </p>
                ) : (
                  <p style={{ marginLeft: "10%", marginTop: "2%" }}>
                    {" "}
                    Drag 'n' drop some files here, or click to select files
                  </p>
                )}
              </div>
              <aside>
                <h3
                  style={{
                    display: `${inputFiles.length === 0 ? "none" : "block"}`,
                  }}
                >
                  Files
                </h3>
                <ul>
                  {inputFiles.map((file, index) => {
                    return (
                      <li key={file.path} style={{ color: "#7087ba" }}>
                        {file.path}
                      </li>
                    );
                  })}
                </ul>
              </aside>
            </div>
          </div>
          <hr />

          <div className="modal-footer justify-content-around">
            <button
              type="button"
              title="Save New Entry"
              className="btn-modal button-close button-close-hover"
              onClick={(e) => {
                handleCompanySubmit(e);
              }}
            >
              Submit
            </button>
            <button
              type="button"
              title="Close This Modal"
              className="btn-modal button-close button-close-hover"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("companySearch").value = "";
                setcurrentForm("company");
                setcompanyForm(companyInitial);
                setinputFiles([]);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ROCDetailsModal;