import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillCreditForm,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import RecursiveModal from "./RecursiveModalNew";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";

const CreditCheckNew = ({ annexure }) => {
  const dispatch = useDispatch();

  const creditForms = useSelector(
    (state) => state.pdfdatanew.forms["Credit Check"]
  );
  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Credit Check"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillCreditForm([
        "Credit Check",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addDateVal = (table, form, component, name, value) => {
    dispatch(
      fillCreditForm(["Credit Check", table, form, component, name, value])
    );
  };

  useEffect(() => {
    Object.keys(creditForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Credit Check",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(creditForms).map((table, index) => {
      dispatch(
        fillCreditForm([
          "Credit Check",
          table,
          "form2",
          "Annexure Details",
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <>
      <h3 className="text-center mt-4">
        <u>Credit Check</u>
      </h3>
      {Object.keys(creditForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <h3>{table}</h3>
            </th>
          </tr>
          <tr>
            <th className="centered-text" colSpan={2}>
              Details as per Subject’s Application Form
            </th>
            <th className="centered-text">Verification Remarks</th>
          </tr>
          {Object.keys(creditForms[table]["form1"]).map((row) => (
            <>
              <tr>
                <td>{row}</td>
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={
                      creditForms[table]["form1"][row]?.infoProvided
                    }
                    name="infoProvided"
                    onBlur={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>

                <InfoVerifiedDropDown
                  fun={(e) => addVal(e, table, "form1", row)}
                  currentValue={creditForms[table]["form1"][row].infoVerified}
                />
              </tr>
            </>
          ))}
          {Object.keys(creditForms[table]["form2"]).map((row) => (
            <tr>
              <td>{row}</td>

              {row === "Status" ? (
                <ColorCode
                  namefield={row}
                  cls={creditForms[table]["form2"][row]}
                  fun={(e) => {
                    addVal(e, table, "form2", row);
                  }}
                  spanNum={2}
                />
              ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                <td colSpan={2}>
                  <DatePickerReact
                    inputTagName={row}
                    val={creditForms[table]["form2"][row]}
                    isSubtable={true}
                    fun={(name, value) => {
                      addDateVal(table, "form2", row, "infoProvided", value);
                    }}
                  />
                </td>
              ) : (
                <td colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    defaultValue={creditForms[table]["form2"][row]}
                    disabled={row === "Annexure Details" ? true : false}
                    name={row}
                    onBlur={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Credit Check"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </>
  );
};
export default CreditCheckNew;
