import "../styles/pageStyles.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillCriminalDbForm,
  editCellInCriminalVerificationDetailForm,
  deleteCellInSubForm,
  addCellInSubFormsType1,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import AddCellModel from "./AddCellModel";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFunNew";
import DatePickerReact from "./DatePickerReact";
import RecursiveModal from "./RecursiveModalNew";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";
import EditCellTitleModel from "./EditCellTitleModel";

const CriminalNew = ({ annexure }) => {
  const modelId = "criminalVerificationCellTileEdit";

  const dispatch = useDispatch();

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["Criminal Verification"]
  );
  const FatherName = useSelector(
    (state) => state.pdfdatanew["caseDetails"]["Father Name"]
  );
  const criminalForms = useSelector(
    (state) => state.pdfdatanew.forms["Criminal Verification"]
  );
  const applicantName = useSelector(
    (state) => state.pdfdatanew["Name of Applicant"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );
  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType1({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(
      fillCriminalDbForm([
        "Criminal Verification",
        table,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "Status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Criminal Verification";
    dispatch(
      editCellInCriminalVerificationDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
  };
  const onDeleteCell = (index, type) => {
    if (index === "" || type === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Criminal Verification";
    const subform = type;
    const cellPosition = index;

    dispatch(
      deleteCellInSubForm({
        subform,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  const addDateVal = (table, component, value) => {
    dispatch(
      fillCriminalDbForm(["Criminal Verification", table, component, value])
    );
  };

  useEffect(() => {
    Object.keys(criminalForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "Criminal Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(criminalForms).map((table, index) => {
      dispatch(
        fillCriminalDbForm([
          "Criminal Verification",
          table,
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  useEffect(() => {
    Object.keys(criminalForms).map((table, index) => {
      dispatch(
        fillCriminalDbForm([
          "Criminal Verification",
          table,
          "Name of the Applicant",
          applicantName?.value,
        ])
      );
      dispatch(
        fillCriminalDbForm([
          "Criminal Verification",
          table,
          "Father Name",
          FatherName?.value
            ? FatherName?.value
            : criminalForms[table]["Father Name"],
        ])
      );
    });
  }, [applicantName, FatherName]);

  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <h3 className="text-center mt-4">
        <u>Criminal Verification</u>
      </h3>

      <>
        {Object.keys(criminalForms).map((table, index) => (
          <table key={table}>
            <tr>
              <th className="heading" colSpan={3}>
                <div className="row row-cols-2">
                  <div className="col">
                    <h3>{table}</h3>
                  </div>
                </div>
              </th>
            </tr>

            {Object.keys(criminalForms[table]).map((row, index) => (
              <tr>
                {row === "Name of the Applicant" || row === "Status" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                      <div className="col-sm-1">
                        {" "}
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, table);
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}

                {row === "Name of the Applicant" ? (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CriminalVerification${table}NameOfApplicant`}
                      defaultValue={
                        criminalForms[table][row] !== ""
                          ? criminalForms[table][row]
                          : applicantName.value
                      }
                      name={row}
                      disabled={true}
                    ></textarea>
                  </td>
                ) : row === "Father Name" && FatherName ? (
                  <td>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      id={`CriminalVerification${table}FatherName`}
                      defaultValue={criminalForms[table][row]}
                      name={row}
                      onChange={(e) => addVal(e, table)}
                    ></textarea>
                  </td>
                ) : row === "Status" ? (
                  <ColorCode
                    cls={criminalForms[table][row]}
                    namefield={row}
                    fun={(e) => addVal(e, table)}
                    spanNum={1}
                  />
                ) : row.toLocaleLowerCase().search(/\bdate\b/) > -1 ? (
                  <td key={row + table + index} colSpan={2}>
                    <DatePickerReact
                      inputTagName={row}
                      val={criminalForms[table][row]}
                      isSubtable={true}
                      fun={(name, value) => {
                        addDateVal(table, row, value);
                      }}
                    />
                  </td>
                ) : (
                  <td key={row + table + index} colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      id={table + row + "ROCUpdate"}
                      defaultValue={criminalForms[table][row]}
                      disabled={row === "Annexure Details" ? true : false}
                      name={row}
                      onBlur={(e) => {
                        addVal(e, table);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}

            <tr>
              <td>Upload Document</td>
              <td colSpan={1}>
                <RecursiveModal
                  mainTable="Criminal Verification"
                  subTable={table}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            {/* <SummaryRemarks
              type="Criminal Verification"
              namefield={table}
              spanNum={1}
            /> */}
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>

            <AddCellModel
              inputFields={criminalForms[table]}
              onaddCell={(typecellref, fieldType, position) =>
                addCell(
                  typecellref,
                  fieldType,
                  position,
                  "Criminal Verification",
                  table
                )
              }
              uniqueName={`CriminalVerification${index}`}
            />
            <EditCellTitleModel
              modelId={modelId}
              onEditCell={onEditCell}
              currentTitle={selectedCellTitle}
              setCurrentTitle={setSelectedCellTitle}
            />
          </table>
        ))}
      </>
    </div>
  );
};
export default CriminalNew;
