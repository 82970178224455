import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillForm26Form } from "../store/pdfdata/pdfdataSlice";
import ColorCheckFun from "./ColorCheckFun";
import ColorCode from "./ColorCode";
import RecursiveModal from "./RecursiveModal";
import SummaryRemarks from "./SummaryRemarks";

const Form26 = ({ annexure }) => {
  const Form26ASForms = useSelector(
    (state) => state.pdfdata.forms["Form26-AS Verification"]
  );
  const dispatch = useDispatch();
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillForm26Form([table, e.target.name, e.target.value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  return (
    <div>
      <h3 className="text-center mt-4">
        <u>Form26-AS Verification</u>
      </h3>
      {Object.keys(Form26ASForms).map((table, index) => (
        <table key={table}>
          {Object.keys(Form26ASForms[table]).map((row) => (
            <tr>
              <td>{row}</td>
              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={Form26ASForms[table][row]}
                  fun={(e) => addVal(e, table)}
                  spanNum={2}
                />
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    defaultValue={Form26ASForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Form26-AS Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Form26-AS Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </div>
  );
};
export default Form26;
