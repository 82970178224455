import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillPoliceForm,
  editCellInSummayDetailForm,
  deleteCellInSummayDetailForm,
  addCellInSubFormsType2,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
import EditCellTitleModel from "./EditCellTitleModel";
import AddCellModel from "./AddCellModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";

const PoliceVerification = ({ annexure }) => {
  const modelId = "policeVerificationCellTileEdit";

  const policeVerificationForms = useSelector(
    (state) => state.pdfdata.forms["Police Verification"]
  );
  const [policeState, setpoliceState] = useState(policeVerificationForms);
  useEffect(() => {
    if (policeVerificationForms !== undefined)
      setpoliceState(policeVerificationForms);
  }, [policeVerificationForms]);

  const dob = useSelector((state) => state.pdfdata["Date of Birth"]);
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const FatherName = useSelector((state) => state.pdfdata["Father Name"]);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const dispatch = useDispatch();

  const addVal = (e, table, form, component) => {
    e.preventDefault();
    console.log("AddVal: ", table, form, component);
    console.log(e.target.name, e.target.value);
    let value = e.target.value;

    dispatch(
      fillPoliceForm([
        "Police Verification",
        table,
        form,
        component,
        e.target.name,
        value,
      ])
    );
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType2({
        type: typecellref,
        fieldType,
        position,
        mainTable,
        subTable,
      })
    );
  };

  useEffect(() => {
    Object.keys(policeVerificationForms).forEach((table) => {
      if (
        policeVerificationForms[table]["form1"]["Name of the Applicant"]
          .infoProvided === ""
      ) {
        document.getElementById(`PoliceVerification${table}Applicant`).value =
          applicantName.value;
      }
      if (
        policeVerificationForms[table]["form1"]["Father Name"].infoProvided ===
          "" &&
        FatherName
      ) {
        document.getElementById(`PoliceVerification${table}FatherName`).value =
          FatherName.value;
      }
    });
  }, [applicantName, FatherName, policeVerificationForms]);
  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");

  const onEditCell = (cellTitileRef) => {
    console.log(cellTitileRef, selectedCellPosition);
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Police Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Police Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };
  return (
    <>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />

      <h3 className="text-center mt-4">
        <u>Police Verification</u>
      </h3>
      {Object.keys(policeVerificationForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <div className="row row-cols-2">
                <div className="col">
                  <h3>{table}</h3>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-dark col-sm-2 float-end"
                    style={{
                      paddingRight: "4px",
                      paddingLeft: "4px",
                    }}
                    data-bs-toggle="modal"
                    title="Add One More Cell"
                    data-bs-target={`#addCellModelPoliceVerification${index}`}
                    data-bs-whatever="@mdo"
                  >
                    <b
                      style={{
                        color: "#32cd32",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Add Cell
                    </b>
                  </button>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Components</th>
            <th className="centered-text">Information Provided</th>
            <th className="centered-text">Information Verified</th>
          </tr>
          {Object.keys(policeVerificationForms[table]["form1"]).map(
            (row, index) => (
              <>
                <tr>
                  {row === "Color Code" ? (
                    <td>{row}</td>
                  ) : (
                    <td className="cellTitle col-sm-6">
                      <div className="row row-cols-2">
                        <div className="col-sm-10">{row}</div>
                        <div className="col-sm-1">
                          <button
                            className="editIcon fa fa-pencil"
                            type="button"
                            data-bs-toggle="modal"
                            title="Edit Cell Title"
                            data-bs-target={`#${modelId}`}
                            data-bs-whatever="@mdo"
                            onClick={() => {
                              console.log(row, index);
                              setSelectedCellPosition(index);
                              setSelectedSubForm(table);
                              setselectedFormNumber("form1");
                              setSelectedCellTitle(row);
                            }}
                          ></button>
                        </div>
                        <div className="col-sm-1">
                          {" "}
                          <button
                            className="editIcon fa fa-trash"
                            type="button"
                            title="Delete Cell Title"
                            onClick={(e) => {
                              setConfirmation({
                                isOpen: true,
                                title: "Are you sure to delete this cell?",
                                subTitle: "You can't undo this operation!",
                                onConfirm: () => {
                                  onDeleteCell(index, table, "form1");
                                },
                              });
                            }}
                          ></button>
                        </div>
                      </div>
                    </td>
                  )}
                  {row === "Date of Birth" && dob ? (
                    <td>{dob.value}</td>
                  ) : (
                    <td>
                      {row === "Name of the Applicant" ? (
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          id={`PoliceVerification${table}Applicant`}
                          type="text"
                          defaultValue={
                            policeVerificationForms[table]["form1"][row]
                              .infoProvided !== ""
                              ? policeVerificationForms[table]["form1"][row]
                                  .infoProvided
                              : applicantName.value
                          }
                          name="infoProvided"
                          onChange={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      ) : row === "Father Name" && FatherName ? (
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          id={`PoliceVerification${table}FatherName`}
                          type="text"
                          defaultValue={
                            policeVerificationForms[table]["form1"][row]
                              .infoProvided !== ""
                              ? policeVerificationForms[table]["form1"][row]
                                  .infoProvided
                              : FatherName.value
                          }
                          name="infoProvided"
                          onChange={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      ) : (
                        <textarea
                          style={{ width: "100%", height: 30 }}
                          type="text"
                          value={
                            policeVerificationForms[table]["form1"][row]
                              .infoProvided
                          }
                          name="infoProvided"
                          onChange={
                            (e) => {
                              addVal(e, table, "form1", row);
                            }
                            // onBlur(e, item.component)
                          }
                        />
                      )}
                    </td>
                  )}

                  <td key={row}>
                    <InfoVerifiedDropDown
                      fun={(e) => addVal(e, table, "form1", row)}
                      currentValue={
                        policeState[table] !== undefined &&
                        policeState[table]["form1"][row] !== undefined &&
                        policeState[table]["form1"][row].infoVerified !==
                          undefined
                          ? policeState[table]["form1"][row].infoVerified
                          : policeVerificationForms[table]["form1"][row]
                              .infoVerified
                      }
                    />
                  </td>
                </tr>
              </>
            )
          )}
          {Object.keys(policeVerificationForms[table]["form2"]).map(
            (row, index) => (
              <tr>
                {row === "Color Code" ? (
                  <td>{row}</td>
                ) : (
                  <td className="cellTitle col-sm-6">
                    <div className="row row-cols-2">
                      <div className="col-sm-10">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            console.log(row, index);
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setselectedFormNumber("form2");
                            setSelectedCellTitle(row);
                          }}
                        ></button>
                      </div>
                      <div className="col-sm-1">
                        {" "}
                        <button
                          className="editIcon fa fa-trash"
                          type="button"
                          title="Delete Cell Title"
                          onClick={(e) => {
                            setConfirmation({
                              isOpen: true,
                              title: "Are you sure to delete this cell?",
                              subTitle: "You can't undo this operation!",
                              onConfirm: () => {
                                onDeleteCell(index, table, "form2");
                              },
                            });
                          }}
                        ></button>
                      </div>
                    </div>
                  </td>
                )}
                {row === "Color Code" ? (
                  <ColorCode
                    namefield={row}
                    cls={policeVerificationForms[table]["form2"][row]}
                    fun={(e) => addVal(e, table, "form2", row)}
                    spanNum={2}
                  />
                ) : (
                  <td colSpan={2}>
                    <textarea
                      style={{ width: "100%", height: 30 }}
                      type="text"
                      value={policeVerificationForms[table]["form2"][row]}
                      name={row}
                      onChange={(e) => {
                        addVal(e, table, "form2", row);
                      }}
                    />
                  </td>
                )}
              </tr>
            )
          )}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Police Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Police Verification"
            namefield={table}
            spanNum={2}
          />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <AddCellModel
            inputFields={policeVerificationForms[table]["form1"]}
            onaddCell={(typecellref, position, fieldType) =>
              addCell(
                typecellref,
                position,
                fieldType,
                "Police Verification",
                table
              )
            }
            uniqueName={`PoliceVerification${index}`}
          />
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </>
  );
};
export default PoliceVerification;
