import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillAddressForm,
  editCellInSummayDetailForm,
  deleteCellInSummayDetailForm,
  addCellInSubFormsType2,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
// import UploadExtension from "./UploadExtension";
import ColorCheckFun from "./ColorCheckFun";
import InfoVerifiedDropDown from "./InfoVerifiedDropDown";
import EditCellTitleModel from "./EditCellTitleModel";
import AddCellModel from "./AddCellModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";
import ConfirmationDialog from "./ConfirmationDialog";

const AddressVerification = ({ annexure }) => {
  const modelId = "addressCellTileEdit";
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmation, setConfirmation] = useState({
    isOpen: false,
    color: "",
    title: "",
    subTitle: "",
  });
  const addForms = useSelector(
    (state) => state.pdfdata.forms["Address Verification"]
  );
  const [addressState, setaddressState] = useState(addForms);
  useEffect(() => {
    if (addForms !== undefined) setaddressState(addForms);
  }, [addForms]);

  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    dispatch(
      fillAddressForm([
        "Address Verification",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (component === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  const addCell = (typecellref, fieldType, position, mainTable, subTable) => {
    if (typecellref === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title",
        type: "warning",
      });
      return;
    }
    if (position === "") {
      setNotify({
        isOpen: true,
        message: "Please Select The Position Where You Want To Add New Cell",
        type: "warning",
      });
      return;
    }
    dispatch(
      addCellInSubFormsType2({
        type: typecellref,
        fieldType,

        position,
        mainTable,
        subTable,
      })
    );
  };

  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  const [selectedFormNumber, setselectedFormNumber] = useState("");

  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    const mainTable = "Address Verification";
    dispatch(
      editCellInSummayDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    setselectedFormNumber("");
  };
  const onDeleteCell = (index, table, formnumber) => {
    if (index === "" || table === "") {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "warning",
      });
    }
    setConfirmation({
      ...confirmation,
      isOpen: false,
    });
    const mainTable = "Address Verification";
    const subform = table;
    const form = formnumber;
    const cellPosition = index;

    dispatch(
      deleteCellInSummayDetailForm({
        subform,
        form,
        cellPosition,
        mainTable,
      })
    );
    setNotify({
      isOpen: true,
      message: "Cell has been deleted!",
      type: "error",
    });
  };

  return (
    <>
      <h3 className="text-center mt-4">
        <u>Address Verification</u>
      </h3>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <ConfirmationDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />

      {Object.keys(addForms).map((table, index) => (
        <table key={table}>
          <tr>
            <th className="heading" colSpan={3}>
              <div className="row row-cols-2">
                <div className="col">
                  <h3>{table}</h3>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-dark col-sm-2 float-end"
                    style={{
                      paddingRight: "4px",
                      paddingLeft: "4px",
                    }}
                    data-bs-toggle="modal"
                    title="Add One More Cell"
                    data-bs-target={`#addCellModelAddressVerification${index}`}
                    data-bs-whatever="@mdo"
                  >
                    <b
                      style={{
                        color: "#32cd32",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Add Cell
                    </b>
                  </button>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th className="centered-text">Components</th>
            <th className="centered-text">Information Provided</th>
            <th className="centered-text">Information Verified</th>
          </tr>
          {Object.keys(addForms[table]["form1"]).map((row, index) => (
            <>
              <tr>
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form1");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                    <div className="col-sm-1">
                      {" "}
                      <button
                        className="editIcon fa fa-trash"
                        type="button"
                        title="Delete Cell Title"
                        onClick={(e) => {
                          setConfirmation({
                            isOpen: true,
                            color: "error",
                            title: "Are you sure to delete this cell?",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              onDeleteCell(index, table, "form1");
                            },
                          });
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
                <td>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    value={addForms[table]["form1"][row].infoProvided}
                    name="infoProvided"
                    onChange={
                      (e) => {
                        addVal(e, table, "form1", row);
                      }
                      // onBlur(e, item.component)
                    }
                  />
                </td>
                <td key={row}>
                  <InfoVerifiedDropDown
                    fun={(e) => addVal(e, table, "form1", row)}
                    // currentValue={addForms[table]["form1"][row].infoVerified}
                    currentValue={
                      addressState[table] !== undefined &&
                      addressState[table]["form1"][row] !== undefined &&
                      addressState[table]["form1"][row].infoVerified !==
                        undefined
                        ? addressState[table]["form1"][row].infoVerified
                        : addForms[table]["form1"][row].infoVerified
                    }
                  />
                  {/* <input
                    type="text"
                    defaultValue={addForms[table]["form1"][row].infoVerified}
                    name="infoVerified"
                    onBlur={(e) => {
                      addVal(e, table, "form1", row);
                    }}
                  /> */}
                </td>
              </tr>
            </>
          ))}
          {Object.keys(addForms[table]["form2"]).map((row, index) => (
            <tr>
              {row === "Color Code" ? (
                <td>{row}</td>
              ) : (
                <td className="cellTitle col-sm-6">
                  <div className="row row-cols-2">
                    <div className="col-sm-10">{row}</div>
                    <div className="col-sm-1">
                      <button
                        className="editIcon fa fa-pencil"
                        type="button"
                        data-bs-toggle="modal"
                        title="Edit Cell Title"
                        data-bs-target={`#${modelId}`}
                        data-bs-whatever="@mdo"
                        onClick={() => {
                          setSelectedCellPosition(index);
                          setSelectedSubForm(table);
                          setselectedFormNumber("form2");
                          setSelectedCellTitle(row);
                        }}
                      ></button>
                    </div>
                    <div className="col-sm-1">
                      {" "}
                      <button
                        className="editIcon fa fa-trash"
                        type="button"
                        title="Delete Cell Title"
                        onClick={(e) => {
                          setConfirmation({
                            isOpen: true,
                            color: "error",
                            title: "Are you sure to delete this cell?",
                            subTitle: "You can't undo this operation!",
                            onConfirm: () => {
                              onDeleteCell(index, table, "form2");
                            },
                          });
                        }}
                      ></button>
                    </div>
                  </div>
                </td>
              )}

              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={addForms[table]["form2"][row]}
                  fun={(e) => addVal(e, table, "form2", row)}
                  spanNum={2}
                />
              ) : (
                <td colSpan={2}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    value={addForms[table]["form2"][row]}
                    name={row}
                    onChange={(e) => {
                      addVal(e, table, "form2", row);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={2}>
              <RecursiveModal
                mainTable="Address Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Address Verification"
            namefield={table.split(":")[0]}
            spanNum={2}
          />
          <tr>
            <td colSpan={3}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <AddCellModel
            inputFields={addForms[table]["form1"]}
            onaddCell={(typecellref, position, fieldType) =>
              addCell(
                typecellref,
                position,
                fieldType,
                "Address Verification",
                table
              )
            }
            uniqueName={`AddressVerification${index}`}
          />
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </>
  );
};
export default AddressVerification;
