import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fillUANForm,
  updateImgStrucAnnexure,
} from "../store/pdfdata/pdfdataSliceNew";

import ColorCode from "./ColorCode";
import ColorCheckFun from "./ColorCheckFunNew";
import RecursiveModal from "./RecursiveModal";
import SummaryRemarks from "./SummaryRemarks";
import SummaryRemarksNew from "./SummaryRemarksNew";

const UANNew = ({ annexure }) => {
  const dispatch = useDispatch();

  const imgStruc = useSelector(
    (state) => state.pdfdatanew.imgStruc["UAN Verification"]
  );
  const UANForms = useSelector(
    (state) => state.pdfdatanew.forms["UAN Verification"]
  );
  const bAddToAnnexureTile = useSelector(
    (state) => state.annexureFlag.bAddToAnnexureTile
  );

  const execSummary = useSelector((state) => state.pdfdatanew.executiveSummary);

  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillUANForm([table, e.target.name, e.target.value]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };

  useEffect(() => {
    Object.keys(UANForms).map((table, index) => {
      if (imgStruc && imgStruc[table]) {
        Object.keys(imgStruc[table]).map((img) => {
          if (imgStruc[table][img]["isChecked"] !== undefined) {
            dispatch(
              updateImgStrucAnnexure([
                "UAN Verification",
                table,
                img,
                annexure + (index + 1),
              ])
            );
          }
        });
      }
    });

    Object.keys(UANForms).map((table, index) => {
      dispatch(
        fillUANForm([
          table,
          "Annexure Details",
          `Please refer Annexure-${annexure + (index + 1)}${
            bAddToAnnexureTile[annexure + (index + 1)] ? "" : "-NA"
          }`,
        ])
      );
    });
  }, [bAddToAnnexureTile, annexure]);

  return (
    <div>
      <h3 className="text-center mt-4">
        <u>UAN Verification</u>
      </h3>
      {Object.keys(UANForms).map((table, index) => (
        <table key={table}>
          {Object.keys(UANForms[table]).map((row) => (
            <tr>
              <td>{row}</td>
              {row === "Color Code" ? (
                <ColorCode
                  namefield={row}
                  cls={UANForms[table][row]}
                  fun={(e) => addVal(e, table)}
                  spanNum={2}
                />
              ) : (
                <td style={{ backgroundColor: "white" }}>
                  <textarea
                    style={{ width: "100%", height: 30 }}
                    type="text"
                    name={row}
                    disabled={row === "Annexure Details" ? true : false}
                    defaultValue={UANForms[table][row]}
                    onBlur={(e) => {
                      addVal(e, table);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="UAN Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />

              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarksNew
            type="UAN Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
        </table>
      ))}
    </div>
  );
};
export default UANNew;
