import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BASE_URL } from "../constants";
import { setError } from "../store/error/errorSlice";

const ColorCode = ({ cls, namefield, fun, spanNum, disabled }) => {
  const [colorCodesObjects, setColorObjects] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function findColor() {
      axios
        .get(`${BASE_URL}form/resource/colors`, {
          responseType: "json",
          withCredentials: true,
        })
        .then((res) => {
          setColorObjects(res.data.data);
        })
        .catch((e) => {
          dispatch(setError(["Error in fetching Color Code List!", e, false]));
        });
    }
    findColor();
  }, []);
  return (
    <td colSpan={spanNum} className={cls}>
      <select
        name={namefield}
        className={cls}
        onBlur={(e) => {
          e.target.className = e.target.value;
          e.target.parentElement.className = e.target.value;
          fun(e);
        }}
        style={{ width: "100%" }}
        disabled={disabled}
      >
        <option></option>
        {colorCodesObjects.map((item, index) => {
          const { ActualColor, ColorCode } = item;
          return ColorCode === cls ? (
            <option value={ColorCode} selected>
              {ColorCode}
            </option>
          ) : (
            <option value={ColorCode}>{ColorCode}</option>
          );
        })}
      </select>
    </td>
  );
};
export default ColorCode;
