import React, { useEffect, useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const CustomDropDown = ({ fun, currentValue, arr }) => {

  const [inputVal, setinputVal] = useState(currentValue);

  return (
    <>
      <Combobox
        openOnFocus={true}
        aria-labelledby="combobox"
        onSelect={(item) => {
          setinputVal(item);
        }}
      >
        <ComboboxInput
          id="inputbox"
          name="infoVerified"
          type="text"
          value={inputVal}
          onChange={(e) => {
            setinputVal(e.target.value);
            fun(e);
          }}
          onFocus={(e) => fun(e)}
          list="d2"
        />
        <ComboboxPopover className="shadow-popup">
          <ComboboxList id="d2">
          {arr.map((item) => (
              <ComboboxOption value={item} key={item} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
};

export default CustomDropDown;