import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fillAustralianFederalPoliceVerificationForm } from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import RecursiveModal from "./RecursiveModal";

const NationalPoliceAndHundredCheck = ({ annexure }) => {
  const AustralianFederalPoliceVerificationForms = useSelector(
    (state) => state.pdfdata.forms["National Police Check & 100 Points Check"]
  );
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();
  //   const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const addVal = (e, table, form, component) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    dispatch(
      fillAustralianFederalPoliceVerificationForm([
        "National Police Check & 100 Points Check",
        table,
        form,
        component,
        e.target.name,
        e.target.value,
      ])
    );
    if (e.target.name === "status")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  return (
    <>
      <h3 className="text-center mt-4">
        <u>National Police Check & 100 Points Check</u>
      </h3>
      {Object.keys(AustralianFederalPoliceVerificationForms).map(
        (table, index) => (
          <table key={table}>
            <tr>
              <th className="heading" colSpan={3}>
                <h3>{table}</h3>
              </th>
            </tr>
            <tr>
              <th className="centered-text">Type of Check</th>
              <th className="centered-text">Brief details</th>
              <th className="centered-text">Status</th>
            </tr>
            {Object.keys(
              AustralianFederalPoliceVerificationForms[table]["form1"]
            ).map((row) => (
              <>
                <tr>
                  <td>{row}</td>
                  <td>
                    <textarea
                      style={{
                        width: "100%",
                        height: 30,
                      }}
                      type="text"
                      defaultValue={
                        AustralianFederalPoliceVerificationForms[table][
                          "form1"
                        ][row].briefDetails
                      }
                      name="briefDetails"
                      onBlur={
                        (e) => {
                          addVal(e, table, "form1", row);
                        }
                        // onBlur(e, item.component)
                      }
                    />
                  </td>

                  {console.log(row)}
                  <ColorCode
                    namefield="status"
                    cls={
                      AustralianFederalPoliceVerificationForms[table]["form1"][
                        row
                      ].status
                    }
                    fun={(e) => addVal(e, table, "form1", row)}
                    spanNum={1}
                  />
                </tr>
              </>
            ))}
            <tr>
              <td>Upload Document</td>
              <td colSpan={2}>
                <RecursiveModal
                  mainTable="National Police Check & 100 Points Check"
                  subTable={table}
                  annexure={annexure + (index + 1)}
                />

                <span className="alert">
                  Files of format .jpg, .jpeg, .png, .eml are accepted!
                </span>
              </td>
            </tr>
            <SummaryRemarks
              type="National Police Check & 100 Points Check"
              namefield={table}
              spanNum={2}
            />
            <tr>
              <td colSpan={3}>
                Please Refer{" "}
                <b>
                  Annexure-{annexure}
                  {index + 1}
                </b>
              </td>
            </tr>
          </table>
        )
      )}
    </>
  );
};
export default NationalPoliceAndHundredCheck;
