import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillBankStatementForm,
  editCellInBankStatementDetailForm,
} from "../store/pdfdata/pdfdataSlice";
import ColorCode from "./ColorCode";
import SummaryRemarks from "./SummaryRemarks";
import ColorCheckFun from "./ColorCheckFun";
import EditCellTitleModel from "./EditCellTitleModel";
import RecursiveModal from "./RecursiveModal";
import NotificationAlert from "./NotificationAlert";

const BankStatementVerification = ({ annexure }) => {
  const modelId = "BankStatementCellTileEdit";

  const bankStatementForms = useSelector(
    (state) => state.pdfdata.forms["Bank Statement Verification"]
  );
  const applicantName = useSelector((state) => state.pdfdata["Applicant Name"]);
  const execSummary = useSelector((state) => state.pdfdata.executiveSummary);
  const dispatch = useDispatch();

  const [selectedCellTitle, setSelectedCellTitle] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const addVal = (e, table) => {
    e.preventDefault();
    dispatch(fillBankStatementForm([e.target.name, e.target.value, table]));
    if (e.target.name === "Color Code")
      ColorCheckFun(dispatch, execSummary, table, e.target.value);
  };
  useEffect(() => {
    Object.keys(bankStatementForms).forEach((table) => {
      if (bankStatementForms[table]["Name of the Applicant"] === "")
        document.getElementById(`BankStatementVerification${table}`).value =
          applicantName.value;
    });
  }, [applicantName, bankStatementForms]);
  const [selectedCellPosition, setSelectedCellPosition] = useState();
  const [selectedSubForm, setSelectedSubForm] = useState("");
  //  const [selectedFormNumber, setselectedFormNumber] = useState("");
  const onEditCell = (cellTitileRef) => {
    if (cellTitileRef === "") {
      setNotify({
        isOpen: true,
        message: "Please Enter New Cell Title...",
        type: "warning",
      });
      return;
    }
    if (
      selectedSubForm === "" ||
      //  selectedFormNumber === "" ||
      selectedCellPosition === ""
    ) {
      setNotify({
        isOpen: true,
        message: "Something went wrong!!",
        type: "error",
      });
    }
    const mainTable = "Bank Statement Verification";
    dispatch(
      editCellInBankStatementDetailForm({
        type: cellTitileRef,
        selectedSubForm,
        //  selectedFormNumber,
        selectedCellPosition,
        mainTable,
      })
    );
    setSelectedCellPosition("");
    setSelectedSubForm("");
    //  setselectedFormNumber("");
  };
  return (
    <div>
      <NotificationAlert notify={notify} setNotify={setNotify} />

      <h3 className="text-center mt-4">
        <u>Bank Statement Verification</u>
      </h3>
      {Object.keys(bankStatementForms).map((table, index) => (
        <table key={table}>
          {Object.keys(bankStatementForms[table]).map((row, index) => (
            <>
              <tr>
                <td className="cellTitle col-sm-6">
                  {row === "Name of the Applicant" || row === "Color Code" ? (
                    <>{row}</>
                  ) : (
                    <div className="row row-cols-2">
                      <div className="col-sm-11">{row}</div>
                      <div className="col-sm-1">
                        <button
                          className="editIcon fa fa-pencil"
                          type="button"
                          data-bs-toggle="modal"
                          title="Edit Cell Title"
                          data-bs-target={`#${modelId}`}
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            setSelectedCellPosition(index);
                            setSelectedSubForm(table);
                            setSelectedCellTitle(row);
                            // setselectedFormNumber("form1");
                          }}
                        ></button>
                      </div>
                    </div>
                  )}
                </td>
                {row === "Color Code" ? (
                  <ColorCode
                    namefield={row}
                    cls={bankStatementForms[table][row]}
                    fun={(e) => {
                      addVal(e, table);
                    }}
                    spanNum={1}
                  />
                ) : (
                  <td style={{ backgroundColor: "white" }}>
                    {row === "Name of the Applicant" ? (
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        id={`BankStatementVerification${table}`}
                        type="text"
                        name={row}
                        defaultValue={
                          bankStatementForms[table][row] !== ""
                            ? bankStatementForms[table][row]
                            : { applicantName }
                        }
                        onChange={(e) => {
                          addVal(e, table);
                        }}
                      />
                    ) : (
                      <textarea
                        style={{ width: "100%", height: 30 }}
                        type="text"
                        name={row}
                        defaultValue={bankStatementForms[table][row]}
                        onBlur={(e) => {
                          addVal(e, table);
                        }}
                      />
                    )}
                  </td>
                )}
              </tr>
            </>
          ))}
          <tr>
            <td>Upload Document</td>
            <td colSpan={1}>
              <RecursiveModal
                mainTable="Bank Statement Verification"
                subTable={table}
                annexure={annexure + (index + 1)}
              />
              <span className="alert">
                Files of format .jpg, .jpeg, .png, .eml are accepted!
              </span>
            </td>
          </tr>
          <SummaryRemarks
            type="Bank Statement Verification"
            namefield={table}
            spanNum={1}
          />
          <tr>
            <td colSpan={2}>
              Please Refer{" "}
              <b>
                Annexure-{annexure}
                {index + 1}
              </b>
            </td>
          </tr>
          <EditCellTitleModel
            modelId={modelId}
            onEditCell={onEditCell}
            currentTitle={selectedCellTitle}
            setCurrentTitle={setSelectedCellTitle}
          />
        </table>
      ))}
    </div>
  );
};
export default BankStatementVerification;
