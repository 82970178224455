import axios from "axios";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { setError } from "../store/error/errorSlice";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
import { useDispatch } from "react-redux";
import DownloadTrackerModal from "./DownloadTrackerModal";

const Header = (props) => {
  const { heading } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const downloadTracker = async () => {
    axios
      .get(`${BASE_URL}tracker/generateTracker`, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res);
        const XlxBlob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(XlxBlob, `Tracker.xlsx`);
      })
      .catch(async (e) => {
        try {
          const msg = JSON.parse(await e.response.data.text()).message;
          //console.log(error.toString());
          if (msg !== undefined) {
            dispatch(setError(["Error in generating tracker!", msg, true]));
          }
        } catch {
          dispatch(
            setError(["Error in generating tracker!", e.toString(), false])
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="row mt-3 ms-1 me-0">
        <div className="col  d-flex justify-content-start">
          <h4 className="mt-2">{heading}</h4>
        </div>
        <div className="col  d-flex justify-content-end">
          <Link
            className="btn btn-sm button-close button-close-hover m-2"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            data-bs-whatever="@mdo"
          >
            <i className="fa fa-download"></i> Generate PDF
          </Link>
          <Link
            className="btn btn-sm button-close button-close-hover m-2"
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropTracker1"
            data-bs-whatever="@mdo"
            onClick={() => {
              setLoading(true);
              downloadTracker();
            }}
          >
            <i className="fa fa-download"></i> Download Tracker
          </Link>
        </div>
      </div>
      <hr className="header-divider mt-2" />
      <DownloadTrackerModal
        loading={loading}
        modalId="staticBackdropTracker1"
      />
    </>
  );
};
export default Header;

// import React from "react";
// const Header = () => {
//     return (<div className="header">
//       <div>
//         <img src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png" alt="logo"/>
//       </div>
//       <div className="header-address">
//         Infomate 360 Solutions India Private Limited, Banjara Hills,
//         Telangana-500034
//       </div>
//     </div>);
// };
// export default Header;